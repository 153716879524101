import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconType } from 'react-icons';
import Color from '../../utils/constants/styles/Color';

interface SidebarItemProps {
  Icon: IconType;
  enName: string;
  name: string;
  path: string;
  status: 'default' | 'active' | 'disabled';
}

const SidebarItem: React.VFC<SidebarItemProps> = ({
  Icon,
  enName,
  name,
  path,
  status = 'default',
}) => (
  <StyledSidebarItem>
    <Link to={path} className={status}>
      <StyledSidebarItemInner className={status}>
        <StyledIcon>
          <Icon />
        </StyledIcon>
        <StyledEnName>{enName}</StyledEnName>
        <StyledName>{name}</StyledName>
      </StyledSidebarItemInner>
    </Link>
  </StyledSidebarItem>
);

export default SidebarItem;

const StyledSidebarItem = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayF3};
  border-bottom: ${Color.borderColorE8} 1px solid;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;

  a {
    display: block;
    width: 100%;
  }

  .disabled {
    pointer-events: none;
  }
`;

const StyledSidebarItemInner = styled.div`
  align-items: center;
  color: ${Color.fontColor};
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;

  &.disabled {
    color: ${Color.backgroundGrayCE};
  }

  &.active {
    background-color: #fff;
    border-radius: 4px;
    color: ${Color.secondary};

    &::after {
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 15px 0 15px 20px;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }
  }
`;

const StyledIcon = styled.div`
  svg {
    height: 24px;
    width: 24px;
  }
`;

const StyledEnName = styled.div`
  font-size: 11px;
`;

const StyledName = styled.div`
  font-size: 10px;
`;
