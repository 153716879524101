import { Comment } from '../lib/api-client/@types/index';
import {
  allDocumentStatusOptions,
  allStatusOptions,
  allTaskGenreOptions,
} from '../utils/constants/data/options';

export type StatusType = 'すべて' | '未対応' | '完了';

export type Status = typeof allStatusOptions[number];
export type TaskGenre = typeof allTaskGenreOptions[number];
export type DocumentStatus = typeof allDocumentStatusOptions[number];

export const isInReadonlyArray = <T extends U, U>(
  union: ReadonlyArray<T>,
  item: U
): item is T => union.includes(item as T);

export type StatusPattern = {
  [key in DocumentStatus]: {
    genre: TaskGenre;
    status: Status[];
  }[];
};

export type TermPattern = {
  [key in DocumentStatus]: {
    genre: TaskGenre;
    status: Status;
  };
};

export type FormItemType = {
  name: string;
  folder_name: string;
  end_date: string;
  price: string;
  contract_date: string;
  adverse: string;
  status: string;
  auto_update: string;
};

export type DocumentFormItemType = {
  name: string;
  folder_name: string;
  end_date: string;
  price: string;
  contract_date: string;
  adverse: string;
  status: string;
  auto_update: string;
};

export interface TaskTableType {
  id: string;
  documentName: string;
  documentId: string;
  folder_name: string;
  taskGenre: string;
  summary: string;
  title: string;
  message: string;
  client: string;
  manager: string;
  limitDate: string;
  announceDate: string;
  status: string;
  comments?: Comment[];
}

export interface DocumentModalTableType {
  button: string;
  id: string;
  create_date: string;
  folder_name: string;
  name: string;
}

export interface DocumentTableType {
  id: string;
  name: string;
  folder_name: string;
  end_date: string;
  amount: string;
  contract_date: string;
  adverse: string;
  status: string;
  auto_update: string;
  create_date: string;
  update_date: string;
  relatedDocumentIds: string[];
  menu: string;
}

//

export interface UserRegisterRequest {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  department: string;
  company_name: string;
}
