import React from 'react';
import styled from 'styled-components';
import AccordionListItem from './AccordionListItem';

interface AccordionListProps {
  list: { category: string; templates: { name: string }[] }[];
  setTemplate: any;
}

const AccordionList: React.VFC<AccordionListProps> = ({
  list,
  setTemplate,
}) => (
  <StyledAccordionList>
    {list.map((item) => (
      <AccordionListItem
        category={item.category}
        templates={item.templates}
        setTemplate={setTemplate}
      />
    ))}
  </StyledAccordionList>
);

export default AccordionList;

const StyledAccordionList = styled.ul``;
