import React from 'react';
import { BsCheckSquare, BsFillCaretRightFill, BsSearch } from 'react-icons/bs';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/atoms/Input';
import Select from '../../../../components/atoms/Select';
import Color from '../../../../utils/constants/styles/Color';
import { SearchDocumentDto } from '../../../../lib/api-client/@types';
import { ERROR_MESSAGES } from '../../../../validations/const';
import { isIntegerGreaterThanOrEqualToZero } from '../../../../validations/number';
import { allDocumentStatusOptions } from '../../../../utils/constants/data/options';

interface SearchFormProps {
  selectTab: React.Dispatch<React.SetStateAction<'search' | 'folder'>>;
  keywordFromSearchBar: string;
  searchDocuments: (query: SearchDocumentDto) => Promise<void>;
}

export const SearchForm = ({
  selectTab,
  keywordFromSearchBar,
  searchDocuments,
}: SearchFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchDocumentDto>({
    mode: 'all',
    defaultValues: {
      name: keywordFromSearchBar,
    },
  });
  const onSubmit: SubmitHandler<SearchDocumentDto> = async (data) => {
    await searchDocuments({ ...data, is_stored: 'True' });
  };

  return (
    <StyledSideBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Item>
          <ItemContainer>
            <KeyWordForm>
              <BsSearch className="icon-keyword" />
              <Input
                label=""
                type="text"
                placeholder={t('契約書名、会社名で検索')}
                register={register('name')}
              />
            </KeyWordForm>
          </ItemContainer>
        </Item>

        <Item>
          <Label>{t('相手方')}</Label>
          <ItemContainer>
            <Input
              label=""
              type="text"
              register={register('adverse', {
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
                },
              })}
              error={errors.adverse}
            />
          </ItemContainer>
        </Item>
        <Item>
          <LabelWrapper>
            <Label>{t('ステータス')}</Label>
            <div className="status-reset-button">
              <Radio className="status-reset-button-radio">
                <label htmlFor="clear">{t('クリア')}</label>
                <input
                  type="radio"
                  {...register('status')}
                  id="clear"
                  value=""
                />
              </Radio>
            </div>
          </LabelWrapper>
          <ItemContainer>
            <RadioContainer>
              {allDocumentStatusOptions.map((status) => (
                <Radio key={status}>
                  <input
                    type="radio"
                    {...register('status')}
                    value={status}
                    id={status}
                  />
                  <label htmlFor={status}>{t(status)}</label>
                  <div className="radio-icon">
                    <BsCheckSquare />
                  </div>
                </Radio>
              ))}
            </RadioContainer>
          </ItemContainer>
        </Item>

        <Item>
          <Label>{t('終了日')}</Label>
          <ItemContainer>
            <InputWrap>
              <Input
                label=""
                type="date"
                register={register('end_date_start')}
              />
              <IconWrap>
                <BsFillCaretRightFill />
              </IconWrap>
              <Input label="" type="date" register={register('end_date_end')} />
            </InputWrap>
          </ItemContainer>
        </Item>
        <Item>
          <Label>{t('自動更新')}</Label>
          <ItemContainer>
            <Select
              defaultValue=""
              register={register}
              label=""
              validation={{}}
              name="auto_update"
              options={['', 'あり', 'なし']}
            />
          </ItemContainer>
        </Item>

        <Item>
          <Label>{t('金額')}</Label>
          <ItemContainer>
            <InputWrap>
              <Input
                label=""
                type="number"
                register={register('price_row', {
                  maxLength: {
                    value: 30,
                    message: ERROR_MESSAGES.MAX_LENGTH_NUMBER_30,
                  },
                  validate: {
                    message: (v) =>
                      isIntegerGreaterThanOrEqualToZero(v)
                        ? undefined
                        : ERROR_MESSAGES.INTEGER_GREATER_THAN_OR_EQUAL_TO_ZERO,
                  },
                })}
                error={errors.price_row}
              />
              <IconWrap>
                <BsFillCaretRightFill />
              </IconWrap>
              <Input
                label=""
                type="number"
                register={register('price_high', {
                  maxLength: {
                    value: 30,
                    message: ERROR_MESSAGES.MAX_LENGTH_NUMBER_30,
                  },
                  validate: {
                    message: (v) =>
                      isIntegerGreaterThanOrEqualToZero(v)
                        ? undefined
                        : ERROR_MESSAGES.INTEGER_GREATER_THAN_OR_EQUAL_TO_ZERO,
                  },
                })}
                error={errors.price_high}
              />
            </InputWrap>
          </ItemContainer>
        </Item>
        <ButtonWrap>
          <Button type="submit">{t('絞り込む')}</Button>
          <ButtonCancel onClick={() => selectTab('folder')}>
            {t('キャンセル')}
          </ButtonCancel>
        </ButtonWrap>
      </form>
    </StyledSideBar>
  );
};

export default SearchForm;

const StyledSideBar = styled.div`
  margin-top: 15px;
  min-height: calc(100vh - 80px);
`;

const IconWrap = styled.div`
  color: #9d9d9d;
`;

const Item = styled.div``;

const KeyWordForm = styled.div`
  margin-bottom: 10px;
  position: relative;

  .icon-keyword {
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  input {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    color: #212529;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px 10px 10px 40px;
    width: 100%;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Radio = styled.div`
  margin: 0 6px 10px 0;
  position: relative;
  width: 46%;

  .radio-icon {
    color: #e1e1e1;
    left: 10px;
    margin: auto 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    background-color: #fff;
    border: 0.5px solid #dfdfdf;
    border-radius: 3px;
    display: block;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 3px;
    padding: 12px 15px 12px 30px;
  }

  input[type='radio']:checked + label + .radio-icon {
    color: #fff;
  }

  input[type='radio']:checked + label::after {
    background-color: ${Color.secondary};
    border-radius: 2px;
    content: '';
    display: block;
    height: 15px;
    left: 10px;
    margin: auto 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -55%);
    width: 16px;
  }
`;

const InputWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  label {
    margin-bottom: 0;
    width: 45%;
  }

  span {
    display: block;
    flex-shrink: 0;
  }
`;

const ItemContainer = styled.div`
  padding: 10px 0;

  select {
    background-color: #fff;
  }
`;

const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  .status-reset-button {
    background-color: ${Color.backgroundGrayB9};
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    margin-right: 8px;
    padding: 3px 12px;

    .status-reset-button-radio {
      margin: 0;
      width: auto;
    }
  }
`;

const Label = styled.div`
  display: block;
  font-weight: 700;
  padding: 10px 15px 5px 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const ButtonCancel = styled.div`
  background-color: #fff;
  border: 0.5px solid #8f8f8f;
  border-radius: 5px;
  color: #8f8f8f;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 700;
  padding: 10px;
`;

const Button = styled.button`
  background-color: ${Color.secondary};
  border: 2px solid ${Color.secondary};
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  margin-right: 15px;
  padding: 10px 0;
  text-align: center;
  width: 100%;
`;
