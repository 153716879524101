import { StatusPattern, TermPattern } from '../../../types';

export const allStatusOptions = [
  '未着手',
  '進行中',
  '完了',
  '完了以外',
] as const;

export const allTaskGenreOptions = [
  '作成',
  '承認',
  '締結依頼',
  '格納',
  // TODO : β番では除外'リマインド',
] as const;

export const allDocumentStatusOptions = [
  '作成中',
  '承認待ち',
  '承認完了',
  '締結確認',
  '締結完了',
  '格納完了',
] as const;

export const statusPatterns: StatusPattern = {
  作成中: [
    {
      genre: '作成',
      status: ['未着手', '進行中'],
    },
  ],
  承認待ち: [
    {
      genre: '作成',
      status: ['完了'],
    },
    {
      genre: '承認',
      status: ['未着手', '進行中'],
    },
  ],
  承認完了: [
    {
      genre: '承認',
      status: ['完了'],
    },
    {
      genre: '締結依頼',
      status: ['未着手', '進行中'],
    },
  ],
  締結確認: [
    {
      genre: '締結依頼',
      status: ['完了'],
    },
  ],
  締結完了: [
    {
      genre: '格納',
      status: ['未着手', '進行中'],
    },
  ],
  格納完了: [
    {
      genre: '格納',
      status: ['完了'],
    },
  ],
};

export const termPatterns: TermPattern = {
  // 「承認」タスクが設定されたが「完了」以外のステータスのもの
  作成中: { genre: '作成', status: '完了以外' },
  // 「承認」タスクが設定されたが「完了」以外のステータスのもの
  承認待ち: { genre: '承認', status: '完了以外' },
  // 「承認」タスクが「完了」のステータスで、「締結依頼」が設定されていないもの(承認完了すると締結依頼のタスクが自動で生成される想定)
  承認完了: { genre: '締結依頼', status: '完了以外' },
  // 「締結依頼」タスクが設定されたが、「完了」以外のステータスのもの
  締結確認: { genre: '締結依頼', status: '完了' },
  // 「締結依頼」タスクが「完了」のステータスで、「格納」タスクが「完了」以外のステータスのもの (締結完了すると格納のタスクが自動で生成される想定)
  締結完了: { genre: '格納', status: '完了以外' },
  // 「格納」タスクが「完了」のステータスのもの
  格納完了: { genre: '格納', status: '完了' },
};
