import React from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import { AiOutlineUpload } from 'react-icons/ai';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Color from '../../../../utils/constants/styles/Color';

interface HeadingProps {
  open: () => void;
  displayFolder;
  tab;
}

const Heading = ({ open, displayFolder, tab }) => {
  const { t } = useTranslation();
  return (
    <StyledHeading>
      <StyledHeadingTitle>
        <RiFileCopy2Line className="icon" />
        {t('契約書管理')}
      </StyledHeadingTitle>
      {displayFolder && tab === 'folder' && (
        <StyledButton type="button">
          <span onClick={() => open()}>
            <AiOutlineUpload />
            {t('契約書をアップロードする')}
          </span>
        </StyledButton>
      )}
    </StyledHeading>
  );
};

export default Heading;

const StyledHeading = styled.h1`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 15px;
`;

const StyledHeadingTitle = styled.div`
  position: relative;

  &::after {
    bottom: -18px;
    content: 'Contract management';
    font-size: 10px;
    font-weight: normal;
    left: 40px;
    position: absolute;
    width: 100%;
  }

  .icon {
    margin-right: 20px;
  }
`;

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  font-size: 15px;
  margin-top: 15px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }

  a,
  span {
    align-items: center;
    background-color: ${Color.secondary};
    border-radius: 3px;
    color: white;
    color: ${Color.fontColorWhite};
    display: flex;
    padding: 5px 10px;

    svg {
      margin-right: 10px;
    }
  }

  a:link,
  a:visited {
    background-color: ${Color.secondary};
    color: ${Color.fontColorWhite};
  }
`;
