import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import taskReducer from '../features/task/taskSlice';
import documentReducer from '../features/document/documentSlice';
import folderReducer from '../features/folder/folderSlice';
import userReducer from '../features/user/userSlice';
import langReducer from '../features/language/languageSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const index = configureStore({
  reducer: {
    task: taskReducer,
    document: documentReducer,
    folder: folderReducer,
    user: userReducer,
    language: langReducer,
  },
});

export type AppDispatch = typeof index.dispatch;
export type RootState = ReturnType<typeof index.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
