import React, { createContext, useContext, useMemo, useState } from 'react';

type SelectedStep = {
  selectedStep: number | undefined;
  setSelectedStep: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const SelectedStepContext = createContext<SelectedStep>(
  {} as SelectedStep
);

export const useSelectedStepContext = () => useContext(SelectedStepContext);

type Props = {
  children: React.ReactChild;
};

export const SelectedStepContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [selectedStep, setSelectedStep] = useState<number | undefined>(
    undefined
  );
  const value = useMemo(
    () => ({ selectedStep, setSelectedStep }),
    [selectedStep]
  );

  return (
    <SelectedStepContext.Provider value={value}>
      {children}
    </SelectedStepContext.Provider>
  );
};
