import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { IoPersonAddOutline } from 'react-icons/io5';
import { Input } from '../../../../components/atoms/Input';

import Color from '../../../../utils/constants/styles/Color';
import { apiClient } from '../../../../utils/apiClient';
import { ERROR_MESSAGES } from '../../../../validations/const';
import { AddUserDto } from '../../../../lib/api-client/@types';

const SignUpModal = ({ setIsModalOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddUserDto>({ mode: 'all' });

  const onSubmit: SubmitHandler<AddUserDto> = async (data) => {
    const { email, password } = data;
    // TODO: usernameとdepartmentをbodyに込めてないけど大丈夫なのかこれ？
    await apiClient().v1.jwtcookie.create.$post({
      body: { email, password },
    });
  };

  return (
    <Wrapper>
      <GrayScale
        onClick={() => {
          setIsModalOpen(false);
        }}
      />
      <Container>
        <Title>
          <IoPersonAddOutline className="icon" />
          メンバーを追加
        </Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="ユーザー名"
            type="text"
            register={register('username', {
              required: ERROR_MESSAGES.REQUIRED,
              maxLength: {
                value: 30,
                message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
              },
            })}
            error={errors.username}
          />
          <Input
            label="パスワード"
            type="password"
            register={register('password', {
              required: ERROR_MESSAGES.REQUIRED,
              maxLength: {
                value: 30,
                message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
              },
            })}
            error={errors.password}
          />
          <Input
            label="メールアドレス"
            type="text"
            register={register('email', {
              required: ERROR_MESSAGES.REQUIRED,
            })}
            error={errors.email}
          />
          <Input
            label="部署名"
            type="text"
            register={register('department', {
              required: ERROR_MESSAGES.REQUIRED,
              maxLength: {
                value: 30,
                message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
              },
            })}
            error={errors.department}
          />
          <ButtonWrapper>
            {/* TODO: disabled時のスタイル追加 */}
            <button type="submit" disabled={isValid}>
              登録
            </button>
          </ButtonWrapper>
        </form>
      </Container>
    </Wrapper>
  );
};

export default SignUpModal;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const GrayScale = styled.div`
  background-color: ${Color.backgroundGrayA6};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Container = styled.div`
  background-color: ${Color.fontColorWhite};
  left: 50%;
  padding: 35px 45px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
  z-index: 1;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  button {
    background-color: ${Color.secondary};
    border: none;
    border-radius: 3px;
    color: ${Color.fontColorWhite};
    font-weight: 700;
    padding: 10px;
    width: 180px;
  }
`;
