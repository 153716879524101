import React from 'react';
import styled from 'styled-components';
import { UseFormRegister } from 'react-hook-form';
import Color from '../../utils/constants/styles/Color';

interface TextAreaProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  /* eslint react/require-default-props: 0 */
  placeholder?: string;
  validation: { required?: string; maxLength?: number };
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  register,
  name,
  placeholder = '',
  validation,
}) => (
  <StyledLabel htmlFor={name}>
    <span>{label}</span>
    <StyledTextArea
      id={name}
      placeholder={placeholder}
      {...register(name, validation)}
    />
  </StyledLabel>
);

export default TextArea;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 15px;

  span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

const StyledTextArea = styled.textarea`
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  height: 100px;
  padding: 10px 15px;
  width: 100%;
`;
