import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import Color from '../../utils/constants/styles/Color';

const NoticeList = [
  {
    name: '山本元隆歳',
    date: '2022/05/10',
    text: '契約書をアップロードしました。',
    url: '/documents/aaa',
  },
  {
    name: '田中秀吉',
    date: '2022/05/09',
    text: '契約書をアップロードしました。',
    url: '/documents/aaa',
  },
  {
    name: '田中秀吉',
    date: '2022/05/09',
    text: '機密保持契約を更新しました。',
    url: '/documents/aaa',
  },
  {
    name: '斎藤悠太',
    date: '2022/05/03',
    text: '契約書をアップロードしました。',
    url: '/documents/aaa',
  },
  {
    name: '鈴木花子',
    date: '2022/05/03',
    text: '契約書をアップロードしました。',
    url: '/documents/aaa',
  },
  {
    name: '鈴木花子',
    date: '2022/05/03',
    text: 'ステータスを更新しました。',
    url: '/documents/aaa',
  },
];

interface NoticePopProps {
  showNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoticePop = ({ showNotification }: NoticePopProps) => (
  <StyleNoticePop>
    <Head>
      <Container>
        <FaRegEdit />
        <Title>編集履歴</Title>
      </Container>
      <CloseButton onClick={() => showNotification(false)}>
        <AiOutlineClose />
      </CloseButton>
    </Head>
    <CardList>
      {NoticeList.map(({ name, url, text, date }) => (
        <Link to={url}>
          <Card>
            <NameContainer>
              <Container>
                <BiUserCircle />
                <Name>{name}</Name>
              </Container>
              <Date>{date}</Date>
            </NameContainer>
            <Text>{text}</Text>
          </Card>
        </Link>
      ))}
    </CardList>
  </StyleNoticePop>
);

export default NoticePop;

const StyleNoticePop = styled.div`
  background-color: #fff;
  border: 1px solid ${Color.borderColorE8};
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px ${Color.backgroundGrayF3};
  padding: 10px 15px 80px;
  position: fixed;
  right: 80px;
  top: 70px;
  width: 330px;
  z-index: 1;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 15px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-left: 4px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const CardList = styled.div``;

const CloseButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 5px 1px;

  :hover {
    background-color: ${Color.backgroundGrayF3};
  }
`;

const Card = styled.div`
  border-top: 1px solid ${Color.borderColorE8};
  padding: 10px 0;

  :hover {
    opacity: 0.7;
  }
`;

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Name = styled.div`
  font-size: 14px;
  margin-left: 2px;
`;

const Date = styled.div`
  font-size: 12px;
`;

const Text = styled.div`
  color: ${Color.secondary};
  font-size: 14px;
`;
