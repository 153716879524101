import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

export type ToastProps = {
  text: string;
  isToast: boolean;
};

const Toast: React.VFC<ToastProps> = ({ text = '', isToast }) => (
  <TransitionStyle>
    <CSSTransition
      unmountOnExit
      classNames="toast"
      timeout={3000}
      in={isToast}
      // onExited={() => setText('')}
    >
      <StyledToast>{text}</StyledToast>
    </CSSTransition>
  </TransitionStyle>
);
export default Toast;

const StyledToast = styled.div`
  background-color: #555;
  bottom: 40px;
  color: #fff;
  font-family: sans-serif;
  font-size: 13px;
  left: 40px;
  padding: 12px 15px;
  position: absolute;
  z-index: 100000;
`;

const TransitionStyle = styled.div`
  .toast-enter {
    opacity: 0;
  }

  .toast-enter-active {
    opacity: 1;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.3s;
  }

  .toast-exit {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s;
  }

  .toast-exit-active {
    opacity: 0;
    transition: opacity 0.5s, transform 0.3s;
  }
`;
