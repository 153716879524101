import React, { useEffect, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { useModal } from 'react-hooks-use-modal';
import Header from '../../components/modules/Header';
import Color from '../../utils/constants/styles/Color';
import AccordionList from './components/AccordionList';
import pdfImg from '../../assets/images/pdf.svg';
import UploadModalWithoutSelectFile from '../../components/organisms/UploadModalWithoutSelectFile';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { fetchTasks } from '../../features/task/taskSlice';
import { fetchAccountInfo } from '../../features/user/userSlice';

const list = [
  {
    category: 'LEGAL0雛形',
    templates: [
      {
        name: '機密保持契約書',
        path: 'https://api.legal0.com/static/NDA-Template.pdf',
        url: 'https://api.legal0.com/static/NDA-Template.pdf',
      },
      {
        name: '業務委託契約書',
        path: 'https://api.legal0.com/static/ServiceAgreement-Template.pdf',
        url: 'https://api.legal0.com/static/ServiceAgreement-Template.pdf',
      },
      {
        name: '取引基本契約書',
        path: 'https://api.legal0.com/static/BasicContractDocument-Template.pdf',
        url: 'https://api.legal0.com/static/BasicContractDocument-Template.pdf',
      },
    ],
  },
];

const DocumentTemplate = () => {
  const dispatch = useAppDispatch();
  const [Modal, open, close, isOpen] = useModal('root', {});
  const [template, setTemplate] = useState({
    name: '',
    path: '',
  });
  const folders = useAppSelector(selectFolders);
  // 初期レンダリング
  useEffect(() => {
    void (async () => {
      // ユーザー情報も取得する
      await dispatch(fetchFolders());
    })();
  }, [dispatch]);

  return (
    <>
      <Header />
      <StyledContainer>
        <StyledSidebar>
          <StyledSidebarTitle>
            <RiFileCopy2Line className="icon" />
            契約書雛形一覧
          </StyledSidebarTitle>
          <AccordionList list={list} setTemplate={setTemplate} />
        </StyledSidebar>
        {template.name && (
          <StyledMain>
            <StyledHeading>
              <StyledHeadingName>
                <img className="icon" src={pdfImg} alt="" />
                {template.name}
              </StyledHeadingName>
              <StyledButton onClick={open}>この契約書で作成する</StyledButton>
            </StyledHeading>
            <iframe
              width="100%"
              height="100%"
              title="aa"
              src={template.path}
              frameBorder="0"
            />
          </StyledMain>
        )}
        <UploadModalWithoutSelectFile
          Modal={Modal}
          open={open}
          close={close}
          isOpen={isOpen}
          defaultTabName="summary"
          folders={folders}
          selectedFile={template}
        />
      </StyledContainer>
    </>
  );
};

export default DocumentTemplate;

const StyledContainer = styled.div`
  display: flex;
  height: calc(100vh - 97px);
  margin-top: 97px;
`;

const StyledSidebar = styled.div`
  background-color: ${Color.backgroundGrayF3};
  height: calc(100vh - 97px);
  padding: 20px 30px;
  position: fixed;
  width: 300px;
`;

const StyledSidebarTitle = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-bottom: 30px;

  .icon {
    height: 20px;
    margin-right: 15px;
    width: 20px;
  }
`;

const StyledMain = styled.div`
  margin-left: 300px;
  padding-left: 20px;
  width: 100%;
`;

const StyledHeading = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayF3};
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  color: white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 15px 20px;

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;
const StyledHeadingName = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;

  .icon {
    margin-right: 10px;
  }
`;
