import * as React from 'react';
import { useTable, Column, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';
import { HiSelector } from 'react-icons/hi';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store';
import { DocumentTableType, TaskTableType } from '../../../../types';
import Color from '../../../../utils/constants/styles/Color';
import { Document, Folder } from '../../../../lib/api-client/@types/index';
import { deleteDocument } from '../../../../features/document/documentSlice';
import Toast from '../../../../components/atoms/Toast';
import Menu from '../../../../components/modules/Menu';

export interface TableProps {
  data: Document[];
  columns: Column<Document>[];
  folders: Folder[];
  displayFolder: Folder | undefined;
}

const Table: React.VFC<TableProps> = ({
  data,
  columns,
  folders,
  displayFolder,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    rows,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
                <StyledTableHead
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/* @ts-ignore */}
                  {column.isSorted && <HiSelector className="icon" />}
                </StyledTableHead>
              ))}
            </StyledTableRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  switch (cell.column.Header) {
                    case '契約書名':
                    case 'Contract name':
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          <StyledTableLink to={`/detail/${row.original.id}`}>
                            <div>{cell.render('Cell')}</div>
                            <StyledDocumentId>
                              {t('契約書')}No.{row.original.id}
                            </StyledDocumentId>
                          </StyledTableLink>
                        </StyledTableDesc>
                      );
                    case '契約開始日':
                    case '契約終了日':
                    case 'Contract start date':
                    case 'Contract end date':
                      return (
                        <StyledTableDesc
                          {...cell.getCellProps()}
                          className="limitDate"
                        >
                          {cell.value
                            ? dayjs(String(cell.value)).format('YYYY年M月D日')
                            : ''}
                        </StyledTableDesc>
                      );
                    case '':
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          <Menu
                            document={row.original}
                            folders={folders}
                            displayFolder={displayFolder}
                            is_stored={false}
                          />
                        </StyledTableDesc>
                      );
                    default:
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableDesc>
                      );
                  }
                })}
              </StyledTableRow>
            );
          })}
        </tbody>
      </StyledTable>
    </StyledWrapper>
  );
};

export default Table;

const StyledTable = styled.table`
  font-size: 13px;
  text-align: left;
  width: 100%;

  tbody {
    border-top: 1px solid ${Color.borderColorTable};
  }
`;

const StyledTableHead = styled.th`
  background-color: ${Color.backgroundGrayF7};
  font-size: 10px;
  padding: 0.5rem;
  position: relative;

  .icon {
    position: absolute;
    right: 5px;
  }

  &:hover {
    background-color: ${Color.backgroundGrayED};
    transition: 0.2s;
  }
`;

const StyledTableRow = styled.tr`
  background-color: #fff;

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.borderColorTable};
  }
`;

const StyledTableDesc = styled.td`
  padding: 12px;
  position: relative;
  vertical-align: middle;

  &.limitDate {
    font-size: 10px;
  }

  &:first-child {
    font-weight: bold;
  }
`;

const StyledTableLink = styled(Link)`
  :hover {
    color: ${Color.font7A};
    text-decoration: underline;
  }
`;

const StyledDocumentId = styled.span`
  color: ${Color.secondary};
  display: block;
  font-size: 10px;
  padding-top: 5px;
`;

const StyledSelectWrapper = styled.div`
  border-radius: 4px;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  right: 0;
  top: -85px;

  select.is-gray {
    background-color: ${Color.backgroundGrayED};
  }

  &::before {
    content: '表示数';
    display: block;
    font-size: 10px;
    left: 15px;
    position: absolute;
    top: 6px;
    z-index: -1;
  }

  &::after {
    border-color: #343434 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 11px;
  }

  select {
    /* 初期化 */
    appearance: none;
    background: none;
    border: 1px solid ${Color.borderColorE7};
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    height: 100%;
    min-width: 110px;
    padding: 3px 28px 5px 70px;
    text-align: right;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledPagination = styled.div`
  bottom: -40px;
  position: absolute;
  right: 0;
`;

const StyledPaginationCounter = styled.span`
  font-size: 12px;
  margin: 0 15px;
`;

const StyledPaginationArrow = styled.span`
  cursor: pointer;
  padding: 10px;

  &::before {
    border: 0;
    border-bottom: solid 2px ${Color.borderColor4F};
    border-left: solid 2px ${Color.borderColor4F};
    content: '';
    display: inline-block;
    height: 10px;
    transform: rotate(45deg);
    transition: 0.2s;
    width: 10px;
  }

  :hover {
    &::before {
      transform: rotate(45deg) skew(-15deg, -15deg);
    }
  }

  &.next {
    &::before {
      transform: rotate(-135deg);
    }

    :hover {
      &::before {
        transform: rotate(-135deg) skew(-15deg, -15deg);
      }
    }
  }

  &.is-disable {
    cursor: auto;
    filter: none;

    &::before {
      border-color: ${Color.borderColorE7};
      border-left: 0;
      border-right: 0;
      transform: rotate(0) translateY(-3px);
    }

    :hover {
      &::before {
        border-color: ${Color.borderColorE7};
        border-left: 0;
        border-right: 0;
        transform: rotate(0) translateY(-3px);
      }
    }
  }
`;

const StyledIconWrapper = styled.div`
  align-items: center;
  border-radius: 50px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 3px;
  width: 30px;

  &:hover {
    background-color: ${Color.backgroundGrayED};
    cursor: pointer;
  }
`;
const StyledMenuWrapper = styled.div`
  &.is-hidden {
    display: none;
  }
`;

const StyledLayer = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const StyledMenu = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px #eee;
  position: absolute;
  right: 0;
  top: 33px;
  width: 200px;
  z-index: 1;
`;

const StyledMenuItem = styled.li`
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: lightgray;
  }
`;
