import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RiFileCopy2Line } from 'react-icons/ri';
import {
  AiOutlineFolder,
  AiOutlineFolderOpen,
  AiOutlineUpload,
} from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { IoDocumentTextOutline, IoSettingsOutline } from 'react-icons/io5';
import { BiPen } from 'react-icons/bi';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store';
import Sidebar from './components/molecules/Sidebar';
import DocumentTable from './components/molecules/DocumentTable';
import { DocumentTableType } from '../../types';
import {
  fetchDocumentsInFolder,
  initializeDocuments,
  selectDocuments,
} from '../../features/document/documentSlice';
import {
  DOCUMENTS_COLUMN,
  DOCUMENTS_COLUMN_EN,
} from '../../utils/constants/data/tableData';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { selectLng } from '../../features/language/languageSlice';
import { UploadButton } from './components/molecules/uploadButton';
import Layout from '../../components/organisms/Layout';
import Color from '../../utils/constants/styles/Color';
import Title from '../../components/atoms/Title';
import TableHeader from '../documents/components/molecules/TableHeader';
import UploadModal from '../../components/organisms/UploadModal';
import RegisterModal from './components/molecules/RegisterModal';
import { Document, Folder } from '../../lib/api-client/@types/index';
import { useSearchDocuments } from '../documents/hooks/useSearchDocuments';
import ToastContextProvider from '../../context/useToastContext';

const Register = () => {
  const { t } = useTranslation();
  const { documents: searchedDocs, searchDocuments } = useSearchDocuments();
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const documents = useAppSelector(selectDocuments);
  const folders = useAppSelector(selectFolders);
  const currentLng = useAppSelector(selectLng);
  const orderedFolders = folders
    .filter((folder) => folder.is_stored)
    .sort((a, b) => {
      if (a.name === b.name) return 0;
      return a.name > b.name ? 1 : -1;
    });
  const archiveFolder = orderedFolders.filter(({ name }) => name === 'ARCHIVE');
  const nonArchiveFolders = orderedFolders.filter(
    ({ name }) => name !== 'ARCHIVE'
  );
  const [tab, selectTab] = useState<'folder' | 'search'>('folder');
  const displayFolders = [...nonArchiveFolders, ...archiveFolder];
  const nonStoredFolders = folders.filter((folder) => !folder.is_stored);
  const [Modal, open, close, isOpen] = useModal('root', {});
  const [ModalRegister, openModalRegister, closeModalRegister, modalIsOpen] =
    useModal('root', {});
  // urlからフォルダを取得
  const folderIdFromPath = params.id as string;
  const [firstId, secondId] = folderIdFromPath.split('_');
  const childId = secondId || firstId;
  const parentIdFromPath = firstId === childId ? '' : firstId;
  const parentFolder = folders.find(
    ({ id, parent_id }) => String(id) === parentIdFromPath && !parent_id
  );
  const displayFolder = folders.find(({ id, parent_id }) => {
    if (!parentFolder) return String(id) === childId;
    return String(id) === childId && parentFolder.id === parent_id;
  });
  // headerのキーワード検索からの場合
  // @ts-ignore
  const keywordFromSearchBar = location?.state?.keyword;

  // 初期レンダリング時に全てのフォルダを取得
  useEffect(() => {
    void (async () => {
      dispatch(initializeDocuments());
      await dispatch(fetchFolders());
    })();
  }, [dispatch]);

  // フォルダの中のドキュメントを取得
  useEffect(() => {
    if (!folders.length || !displayFolder) return;
    void (async () => {
      await dispatch<any>(
        fetchDocumentsInFolder({ folderId: String(displayFolder.id) })
      );
    })();
  }, [dispatch, folderIdFromPath, folders, displayFolder]);
  const [selectedDocument, selectDocument] = useState<Document>();
  const [isDisable, setDisable] = useState(true);
  return (
    <ToastContextProvider>
      <StyledContainer>
        <Sidebar
          keywordFromSearchBar={keywordFromSearchBar}
          folders={displayFolders}
          displayFolder={displayFolder}
          searchDocuments={searchDocuments}
          tab={tab}
          selectTab={selectTab}
        />
        <StyledContents>
          <Title
            Icon={<BiPen />}
            title={t('台帳登録する')}
            subTitle="Ledgers management"
          />
          <>
            <StyledButtonWrapper>
              <StyledMediumButton>
                <button
                  disabled={isDisable}
                  type="button"
                  onClick={() => openModalRegister()}
                >
                  <BiPen />
                  {t('選択した契約書に上書きして登録')}
                </button>
              </StyledMediumButton>
            </StyledButtonWrapper>
            {tab === 'folder' && displayFolder && (
              <StyledHeading>
                <StyledButton type="button">
                  <span onClick={() => open()}>
                    <AiOutlineUpload />
                    {t('締結済み契約書アップロード')}
                  </span>
                </StyledButton>
              </StyledHeading>
            )}
            <StyledTableWrapper>
              {tab === 'folder' && (
                <TableHeader
                  displayFolder={displayFolder}
                  parentFolder={parentFolder}
                  folders={displayFolders}
                  documents={documents}
                />
              )}
              <DocumentTable
                columns={
                  currentLng === 'jp' ? DOCUMENTS_COLUMN : DOCUMENTS_COLUMN_EN
                }
                data={documents}
                selectDocument={selectDocument}
                setDisable={setDisable}
                folders={displayFolders}
                displayFolder={displayFolder}
              />
            </StyledTableWrapper>
          </>
        </StyledContents>
      </StyledContainer>
      <UploadModal
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
        defaultTabName="summary"
        folders={displayFolders}
        defaultSelectedFolder={displayFolder}
        status="格納完了"
      />
      <RegisterModal
        Modal={ModalRegister}
        folders={displayFolders}
        close={closeModalRegister}
        nonStoredFolders={nonStoredFolders}
        selectedDocument={selectedDocument}
      />
    </ToastContextProvider>
  );
};

export default Register;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledContents = styled.div`
  padding: 32px;
  width: 100%;
`;

const StyledContentWrapper = styled.div``;

const StyledTableWrapper = styled.div`
  background-color: ${Color.backgroundGrayF7};
  border: 1px solid ${Color.borderColorE7};
`;

const StyledButtonWrapper = styled.div``;

const StyledHeading = styled.h1`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  position: relative;
  top: -15px;

  &:hover {
    opacity: 0.7;
  }

  a,
  span {
    align-items: center;
    background-color: ${Color.backgroundGrayED};
    border-radius: 3px;
    display: flex;
    font-weight: bold;
    padding: 10px 15px;

    svg {
      margin-right: 10px;
    }
  }

  a:link,
  a:visited {
    background-color: ${Color.secondary};
    color: ${Color.fontColorWhite};
  }
`;

const StyledMediumButton = styled.div`
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-right: 20px;
  padding: 0;

  button {
    align-items: center;
    background-color: ${Color.secondary};
    border: none;
    border-radius: 3px;
    color: white;
    color: ${Color.fontColorWhite};
    cursor: pointer;
    display: flex;
    font-weight: bold;
    padding: 16px 22px;

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      background-color: ${Color.backgroundGrayF7};
      color: ${Color.font7A};
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }

    svg {
      margin-right: 10px;
    }
  }
`;
