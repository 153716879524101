import React, { useEffect, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineFolder } from 'react-icons/ai';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FolderList from './FolderList';
import Color from '../../../../utils/constants/styles/Color';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { Document, Folder } from '../../../../lib/api-client/@types/index';
import { apiClient } from '../../../../utils/apiClient';
import RegisterModalHeadingStep from './RegisterModalHeadingStep';
import RegisterModalStepSelect from './RegisterModalStepSelect';
import RegisterModalStepInput from './RegisterModalStepInput';
import RegisterModalStepTask from './RegisterModalStepTask';

interface RegisterModalType {
  Modal: React.FC<{ children: React.ReactNode }>;
  close: () => void;
  nonStoredFolders: Folder[];
  selectedDocument?: Document;
  folders: Folder[];
}

const RegisterModal: React.VFC<RegisterModalType> = ({
  Modal,
  close,
  nonStoredFolders,
  selectedDocument,
  folders,
}) => {
  const [step, moveStep] = useState('select');
  const [targetItem, selectItem] = useState<Document | Folder | null>(null);
  const closeModal = () => {
    close();
    moveStep('select');
    selectItem(null);
  };
  const [newDocumentId, setNewDocumentId] = useState<number | null>(null);
  const { t } = useTranslation();

  return (
    <Modal>
      <StyledModalInner>
        <StyledModalHeading>
          <StyledModalHeadingTitle>
            <RiFileCopy2Line className="icon" />
            {t('台帳登録する')}
          </StyledModalHeadingTitle>
          <RegisterModalHeadingStep step={step} />
        </StyledModalHeading>
        {step === 'select' && (
          <RegisterModalStepSelect
            targetItem={targetItem}
            selectItem={selectItem}
            nonStoredFolders={nonStoredFolders}
            moveStep={moveStep}
            closeModal={closeModal}
          />
        )}
        {step === 'register' && (
          <RegisterModalStepInput
            selectedDocument={selectedDocument}
            targetItem={targetItem}
            moveStep={moveStep}
            closeModal={closeModal}
            setNewDocumentId={setNewDocumentId}
            nonStoredFolders={nonStoredFolders}
          />
        )}
        {/* {step === 'task' && ( */}
        {/*  <RegisterModalStepTask */}
        {/*    closeModal={closeModal} */}
        {/*    newDocumentId={newDocumentId} */}
        {/*  /> */}
        {/* )} */}
        {step === 'complete' && (
          <>
            <StyledCompleteSentence>
              台帳登録が完了しました
            </StyledCompleteSentence>
            <StyledButton onClick={closeModal}>完了</StyledButton>
          </>
        )}
      </StyledModalInner>
    </Modal>
  );
};

export default RegisterModal;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  max-height: 650px;
  min-width: 550px;
  overflow: scroll;
  padding: 30px;
`;

const StyledModalHeading = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const StyledModalHeadingTitle = styled.h2`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledCompleteSentence = styled.div`
  align-items: center;
  background-color: ${Color.backgroundBluePrimary};
  color: ${Color.secondary};
  display: flex;
  font-weight: bold;
  height: 140px;
  justify-content: center;
  margin: 20px 0;
  padding: 20px;
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-left: auto;
  padding: 15px 20px;
  width: 150px;
`;
