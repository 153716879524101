import React, { useEffect, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Color from '../../../../utils/constants/styles/Color';
import { useAppDispatch } from '../../../../store';
import {
  addDocumentTask,
  addTaskComment,
} from '../../../../features/document/documentSlice';
import { TaskInput } from '../../../../components/modules/TaskInput';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';
import { AddTaskDto } from '../../../../lib/api-client/@types';
import { useToastContext } from '../../../../context/useToastContext';

const taskMap = {
  作成中: '作成',
  承認待ち: '承認',
  承認完了: '締結依頼',
  締結確認: '格納',
  締結完了: '格納',
  // TODO: 格納完了: 'リマインド', β番ではリマインドタスクを除外
  // TODO: ステータスの平文はバグの元なので削除したい
};

interface AddTaskModal {
  Modal: React.FC<{ children: React.ReactNode }>;
  close: () => void;
  documentId: number;
  documentStatus: string;
}

const AddTaskModal: React.VFC<AddTaskModal> = ({
  Modal,
  close,
  documentId,
  documentStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setToastText } = useToastContext();
  const [isDisableButton, setIsDisableButton] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddTaskDto>({ mode: 'all' });

  useEffect(() => {
    setValue('task_genre', taskMap[documentStatus]);
  }, [documentStatus, setValue]);

  const { setHasError } = useNetworkErrorModalContext();

  const onSubmit: SubmitHandler<AddTaskDto> = (data) => {
    setIsDisableButton(true);
    const params = {
      ...data,
      document_id: documentId,
    };
    void (async () => {
      const {
        // @ts-ignore
        // TODO: ハンドリングできていない
        payload: { id },
      } = await dispatch(addDocumentTask({ ...params })).catch(() => {
        setIsDisableButton(false);
      });
      if (data.message) {
        const taskResult = await dispatch(
          addTaskComment({ id, message: data.message })
        );
        if (addTaskComment.rejected.match(taskResult)) {
          setHasError(true);
          setIsDisableButton(false);
          return;
        }
      }
      setToastText(`${t('タスクを追加しました')}`);
      reset();
      setIsDisableButton(false);
    })();
  };

  return (
    <Modal>
      <StyledModalInner>
        <StyledModalHeading>
          <StyledModalHeadingTitle>
            <RiFileCopy2Line className="icon" />
            {t('タスクを設定する')}
          </StyledModalHeadingTitle>
        </StyledModalHeading>
        <form onSubmit={handleSubmit(onSubmit)} action="">
          <TaskInput register={register} errors={errors} />
          <StyledButtonBox>
            <StyledButton disabled={!isValid || isDisableButton} type="submit">
              {t('追加')}
            </StyledButton>
          </StyledButtonBox>
        </form>
      </StyledModalInner>
    </Modal>
  );
};

export default AddTaskModal;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 550px;
  padding: 30px;
`;

const StyledModalHeading = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledModalHeadingTitle = styled.h2`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &:disabled {
    background-color: ${Color.backgroundGrayF7};
    color: ${Color.font7A};
    cursor: default;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }

  &.complete {
    background-color: ${Color.borderColor4F};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
