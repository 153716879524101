import React, { useState } from 'react';
import styled from 'styled-components';
import { AiFillMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { Folder } from '../../../../lib/api-client/@types/index';
import FolderList from './FolderList';

interface FolderListProps {
  folders: Folder[];
  displayFolder?: Folder;
  id: number;
}

const FolderListSub: React.VFC<FolderListProps> = ({
  displayFolder,
  folders,
  id,
}) => {
  const [isOpen, openAccordion] = useState(false);
  const filterChildFolder = (parentId: number) =>
    folders.filter((folder) => folder.parent_id === parentId);
  if (!filterChildFolder(id).length) return null;
  return (
    <StyledSubFolder>
      <StyledAccordion className={isOpen ? 'is-open' : ''}>
        <FolderList
          folders={filterChildFolder(id)}
          displayFolder={displayFolder}
        />
      </StyledAccordion>
    </StyledSubFolder>
  );
};

export default FolderListSub;

const StyledSubFolder = styled.div`
  padding-left: 20px;
`;

const StyledAccordion = styled.div`
  &.is-open {
    display: block;
  }
`;
