import React, { useState } from 'react';
import { AiOutlineFolder } from 'react-icons/ai';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import FolderList from '../modules/FolderList';
import Color from '../../utils/constants/styles/Color';
import { useAppDispatch } from '../../store';
import { createFolder } from '../../features/folder/folderSlice';
import { Folder } from '../../lib/api-client/@types/index';
import { CreateFolderFormType } from '../../types/form';
import { useToastContext } from '../../context/useToastContext';

interface CreateFolderModalType {
  Modal: React.FC<{ children: React.ReactNode }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
  defaultTabName: string;
  folders: Folder[];
  isStored: boolean;
}

const CreateFolderModal: React.VFC<CreateFolderModalType> = ({
  Modal,
  close,
  folders,
  isStored = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setToastText } = useToastContext();
  const [selectedFolder, selectFolder] = useState<Folder | null>(null);
  const [isParent, setParent] = useState(false);
  const parentFolders = folders.filter((folder) => !folder.parent_id);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateFolderFormType>({ mode: 'all' });
  const checkSameName = (name) => {
    if (!selectedFolder) {
      return !parentFolders.map((folder) => folder.name).includes(name);
    }
    return !folders
      .map(
        (folder) =>
          String(folder.parent_id) === getValues('folderId') && folder.name
      )
      .includes(name);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    await dispatch(
      createFolder({
        name: data.folder_name,
        parent_id: selectedFolder ? selectedFolder.id : null,
        is_stored: isStored,
      })
    )
      .then(() => {
        setToastText(`${data.folder_name}${t('フォルダを作成しました')}`);
        reset();
        close();
      })
      .catch(() => {
        setToastText(`${t('フォルダ作成に失敗しました')}`);
      });
  };
  return (
    <Modal>
      <StyledModalInner>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledModalTitle>
            <AiOutlineFolder className="icon" />
            {t('フォルダ名')}
            {!isValid &&
              errors.folder_name &&
              errors.folder_name.type === 'validate' && (
                <StyledError>
                  {selectedFolder?.name &&
                    `${selectedFolder?.name}${t('には、')}`}
                  {getValues('folder_name')}
                  {t('という名前のフォルダが既に存在します')}
                </StyledError>
              )}
          </StyledModalTitle>
          <StyledModalInput
            type="text"
            {...register('folder_name', {
              validate: checkSameName,
              required: true,
            })}
          />
          <StyledModalTitle>
            <AiOutlineFolder className="icon" />
            {t('親フォルダの中に作成する')}
            <input
              type="checkbox"
              checked={isParent}
              onChange={(event) => setParent(event.target.checked)}
            />
          </StyledModalTitle>
          {isParent && (
            <StyledModalContent>
              {parentFolders.map(
                (folder) =>
                  !folder.parent_id && (
                    <FolderList
                      key={folder.id}
                      folder={folder}
                      folders={parentFolders}
                      selectFolder={selectFolder}
                      selectedFolder={selectedFolder}
                      register={register}
                    />
                  )
              )}
            </StyledModalContent>
          )}
          <StyledButtonBox>
            <StyledButton type="submit" disabled={!isValid}>
              {t('作成')}
            </StyledButton>
            <StyledButton className="cancel" onClick={() => close()}>
              {t('キャンセル')}
            </StyledButton>
          </StyledButtonBox>
        </StyledForm>
      </StyledModalInner>
    </Modal>
  );
};

export default CreateFolderModal;

const StyledModalUploadArea = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayE7};
  border: 1px splid ${Color.borderColorCB};
  display: flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  text-align: center;

  input {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;
const StyledModalContent = styled.div`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px;
  padding: 15px 25px;
  width: 100%;
  z-index: 1;
`;

const StyledModalInput = styled.input`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
`;

const StyledModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledForm = styled.form``;
const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }

  &:disabled {
    background-color: ${Color.backgroundGrayED};
    color: ${Color.backgroundGrayCE};
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 500px;
  padding: 30px;
`;

const StyledError = styled.span`
  color: ${Color.fontRed};
  font-size: 10px;
  font-weight: normal;
  margin-left: 10px;
`;
