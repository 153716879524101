import React, { useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import pdfImg from '../../../../assets/images/pdf.svg';
import Color from '../../../../utils/constants/styles/Color';
import { apiClient } from '../../../../utils/apiClient';

// TODO: レスポンスのURLを表示

const steps = [
  '作成中',
  '承認待ち',
  '承認完了',
  '締結確認',
  '締結完了',
  '格納完了',
];

const DocumentPreview = ({ document }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  useEffect(() => {
    void (async () => {
      const { object_token } = await apiClient()
        .v1.document._documentId(document.id)
        .object.$post();
      setToken(object_token);
    })();
  }, [document.id]);

  return (
    <>
      <StyledHeading>
        <StyledTitle>
          <StyledTitleMain>
            <img className="icon" src={pdfImg} alt="" />
            {document.name}
          </StyledTitleMain>
          <StyledTitleSub>
            {t('更新日')}: {dayjs(document.update_date).format('YYYY年M月D日')}
          </StyledTitleSub>
        </StyledTitle>

        <StyledAside>
          {/* TODO: βリリースでは非表示 */}
          {/* <StyledStep> */}
          {/*  {steps.map((step, index) => ( */}
          {/*    // TODO: 判別ロジック */}
          {/*    <StyledStepItem */}
          {/*      key={step} */}
          {/*      className={step === document.status ? 'is-active' : ''} */}
          {/*    > */}
          {/*      <div className="stepCircle" /> */}
          {/*      <div className="stepText">{step}</div> */}
          {/*    </StyledStepItem> */}
          {/*  ))} */}
          {/* </StyledStep> */}
          <StyledDownloadButton
            onClick={() =>
              saveAs(
                `${process.env.REACT_APP_API_URL}/v1/document/object/${token}`,
                document.name
              )
            }
          >
            <FiDownload />
            <span>{t('契約書をダウンロード')}</span>
          </StyledDownloadButton>
        </StyledAside>
      </StyledHeading>
      <iframe
        width="100%"
        height="100%"
        title={document.name}
        src={`${process.env.REACT_APP_API_URL}/v1/document/object/${token}`}
        frameBorder="0"
      />
    </>
  );
};

export default DocumentPreview;

const StyledHeading = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  iframe {
    height: 80vh;
    width: 100%;
  }
`;

const StyledAside = styled.div`
  align-items: center;
  display: flex;
`;

const StyledTitle = styled.div``;

const StyledStep = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  margin-right: 20px;
  position: relative;

  &::before {
    background-color: ${Color.borderColorE7};
    content: '';
    display: inline-block;
    height: 2px;
    left: 10px;
    position: absolute;
    top: 9px;
    width: 300px;
    z-index: 0;
  }
`;

const StyledStepItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 40px;

  .stepCircle {
    background-color: #fff;
    border: 2px solid ${Color.borderColorE7};
    border-radius: 50%;
    height: 20px;
    margin-bottom: 5px;
    width: 20px;
    z-index: 1;
  }

  .stepText {
    color: ${Color.font7A};
    font-size: 10px;
    font-weight: 700;
  }

  &.is-active .stepCircle {
    background-color: ${Color.primary};
    border-color: ${Color.fontColor};
  }

  &.is-active .stepText {
    color: ${Color.fontColor};
  }
`;

const StyledTitleMain = styled.h1`
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;

  .icon {
    margin-right: 5px;
  }
`;

const StyledTitleSub = styled.h1`
  font-size: 12px;
  margin-bottom: 15px;
`;

const StyledDownloadButton = styled.a`
  align-items: center;
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorE7};
  border-radius: 4px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;

  span {
    margin-left: 3px;
  }
`;
