import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '../../store';

interface LanguageState {
  lng: string;
}

const initialState = {
  lng: Cookies.get('i18next') || 'jp',
} as LanguageState;

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    updateLng: (state, action: PayloadAction<string>) => {
      state.lng = action.payload;
    },
  },
});

export const selectLng = (state: RootState) => state.language.lng;

export const { updateLng } = languageSlice.actions;

export default languageSlice.reducer;
