import React, { VFC, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface AuthenticatedGuardProps {
  children?: any;
}

const AuthenticatedGuard: VFC<AuthenticatedGuardProps> = ({ children }) =>
  // const navigate = useNavigate();
  // const location = useLocation();
  // const token = Cookies.get('access_token');
  // const lastLocation = location?.pathname;
  // useEffect(() => {
  //   if (!token && location.pathname !== '/signup') {
  //     navigate(`/signup`, { state: { lastLocation } });
  //   }
  // }, [lastLocation, location.pathname, navigate, token]);

  children || <Outlet />;
export default AuthenticatedGuard;
