import React, { useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Color from '../../utils/constants/styles/Color';
import { useAppDispatch } from '../../store';
import {
  deleteDocument,
  moveDocument,
  updateDocument,
} from '../../features/document/documentSlice';
import FolderList from './FolderList';
import { Folder } from '../../lib/api-client/@types/index';
import { createFolder } from '../../features/folder/folderSlice';
import { apiClient } from '../../utils/apiClient';
import { useNetworkErrorModalContext } from '../../context/useNetworkErrorModalContext';
import { useToastContext } from '../../context/useToastContext';

interface MenuProps {
  folders: Folder[];
  document;
  displayFolder: Folder | undefined;
  is_stored: boolean;
}

const Menu: React.VFC<MenuProps> = ({
  document,
  folders,
  displayFolder,
  is_stored,
}) => {
  const { t } = useTranslation();
  // TODO react-hook-formに置き換える
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({ mode: 'all' });

  const { setToastText } = useToastContext();

  const navigate = useNavigate();
  const { id, name, file_data: fileData } = document;
  const [isShowMenu, toggleMenu] = useState(false);
  const [type, setType] = useState('');
  const dispatch = useAppDispatch();
  const [selectedFolder, selectFolder] = useState<Folder | null>(null);
  const { setHasError } = useNetworkErrorModalContext();

  const handleDelete = async () => {
    await dispatch(deleteDocument(document.id)).then((res) => {
      if (deleteDocument.rejected.match(res)) {
        setHasError(true);
        return;
      }
      setToastText(`${name}${'を削除しました'}`);
      toggleMenu(false);
    });
  };

  const handleMove = async () => {
    if (displayFolder && displayFolder.id === selectedFolder!.id) return;
    // ユーザー情報も取得する
    const result = await dispatch<any>(
      moveDocument({
        folder_id: selectedFolder!.id,
        folder_name: selectedFolder!.name,
        documentId: document.id,
      })
    );
    if (moveDocument.rejected.match(result)) {
      setHasError(true);
    }
  };

  const handleCancel = () => {
    setType('');
    toggleMenu(false);
  };

  const [uploadedFile, uploadFile] = useState<File | null>(null);

  const handleUpdate = () => {
    // TODO アーカイブにファイルを残す
    const archiveFolder = folders.find(
      ({ name: folderName }) => folderName === 'ARCHIVE'
    );

    // アーカイブフォルダに新規作成
    void (async () => {
      const { object_token } = await apiClient()
        .v1.document._documentId(document.id)
        .object.$post();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/document/object/${object_token}`
      );
      const blob = await res.blob();
      const file = new File([blob], document.file_data, {
        type: 'application/pdf',
      });
      if (!archiveFolder) {
        const {
          // @ts-ignore //TODO: エラーハンドリングできていない
          payload: { id: FolderId },
        } = await dispatch(
          createFolder({
            name: 'ARCHIVE',
            is_stored,
          })
        );
        // アーカイブに前のファイルで新規作成
        const { id: documentId } = await apiClient().v1.upload_document.$post({
          body: {
            // @ts-ignore
            file_data: file,
            name: document.name,
            folder_id: FolderId,
          },
        });
        // 前の内容をアーカイブにも適用
        const {
          related_documents,
          file_data,
          folder_id,
          name: documentName,
          ...rest
        } = document;
        const result = await dispatch<any>(
          updateDocument({
            document: {
              ...rest,
              related_documents: related_documents.map(
                ({ document_id }) => document_id
              ),
              id: documentId,
            },
          })
        );
        if (updateDocument.rejected.match(result)) {
          setHasError(true);
        }
      } else {
        // アーカイブに前のファイルで新規作成
        const { id: documentId } = await apiClient().v1.upload_document.$post({
          body: {
            // @ts-ignore
            file_data: file,
            name: document.name,
            folder_id: archiveFolder.id,
          },
        });
        // 前の内容をアーカイブにも適用
        const {
          related_documents,
          file_data,
          folder_id,
          name: documentName,
          ...rest
        } = document;
        const result = await dispatch<any>(
          updateDocument({
            document: {
              ...rest,
              related_documents: related_documents.map(
                ({ document_id }) => document_id
              ),
              id: documentId,
            },
          })
        );
        if (updateDocument.rejected.match(result)) {
          setHasError(true);
        }
      }
      // 既存のファイルを上書き
      const form_data = new FormData();
      // @ts-ignore
      form_data.append('file_data', uploadedFile);
      const token = Cookies.get('access_token');
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v1/documents/${document.id}`,
        form_data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `JWT ${token}`,
          },
        }
      );
      navigate(`/detail/${document.id}`);
    })();
  };

  const obj = {
    delete: {
      text: `${name}${t('を削除します')}`,
      buttonText: `${t('削除')}`,
      onClick: handleDelete,
    },
    move: {
      text: `${t('移動先のフォルダを選択')}`,
      buttonText: `${t('移動')}`,
      onClick: handleMove,
    },
    update: {
      text: `${t('契約書をアップロードして更新')}`,
      buttonText: `${t('更新')}`,
      onClick: handleUpdate,
    },
  };

  return (
    <>
      <StyledIconWrapper onClick={() => toggleMenu(true)}>
        <FiMoreHorizontal />
      </StyledIconWrapper>
      {isShowMenu && (
        <StyledMenuWrapper>
          <StyledLayer onClick={() => toggleMenu(false)} />
          <StyledMenu>
            <li>
              <StyledMenuItemLink href={fileData} download={name}>
                {t('ダウンロード')}
              </StyledMenuItemLink>
            </li>
            <StyledMenuItem onClick={() => setType('update')}>
              {t('更新')}
            </StyledMenuItem>
            <StyledMenuItem onClick={() => setType('move')}>
              {t('移動')}
            </StyledMenuItem>
            <StyledMenuItem
              className="is-danger"
              onClick={() => setType('delete')}
            >
              {t('削除')}
            </StyledMenuItem>
            {type && (
              <StyledSubMenu className={type}>
                <StyledMenuTitle>{obj[type].text}</StyledMenuTitle>
                {type === 'move' && (
                  <StyledModalContent>
                    {folders.map(
                      (folder) =>
                        !folder.parent_id && (
                          <FolderList
                            key={folder.id}
                            folder={folder}
                            folders={folders}
                            selectFolder={selectFolder}
                            selectedFolder={selectedFolder}
                            register={register}
                          />
                        )
                    )}
                  </StyledModalContent>
                )}
                {type === 'update' && (
                  <StyledModalUploadArea
                    className={uploadedFile ? 'is-file' : ''}
                  >
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={(event) =>
                        uploadFile(
                          !event.target.files || event.target.files.length === 0
                            ? null
                            : event.target.files[0]
                        )
                      }
                    />
                    {uploadedFile ? (
                      <StyledUploadFileText>
                        {uploadedFile.name}
                      </StyledUploadFileText>
                    ) : (
                      <>
                        {t('ファイルをドラッグ&ドロップ')}
                        <br />
                        {t('またはファイルを選択')}
                      </>
                    )}
                  </StyledModalUploadArea>
                )}
                <StyledButtonBox>
                  <StyledButton type="button" onClick={obj[type].onClick}>
                    {obj[type].buttonText}
                  </StyledButton>
                  <StyledButton className="cancel" onClick={handleCancel}>
                    {t('キャンセル')}
                  </StyledButton>
                </StyledButtonBox>
              </StyledSubMenu>
            )}
          </StyledMenu>
        </StyledMenuWrapper>
      )}
    </>
  );
};

export default Menu;

const StyledIconWrapper = styled.div`
  align-items: center;
  border-radius: 50px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 3px;
  width: 30px;

  &:hover {
    background-color: ${Color.backgroundGrayED};
    cursor: pointer;
  }
`;
const StyledMenuWrapper = styled.div`
  &.is-hidden {
    display: none;
  }
`;

const StyledLayer = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const StyledMenu = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px #eee;
  position: absolute;
  right: 0;
  top: 33px;
  width: 200px;
  z-index: 1;
`;

const StyledMenuItem = styled.li`
  border-bottom: 1px solid ${Color.borderColorED};
  cursor: pointer;
  font-size: 14px;
  padding: 14px 20px;

  &.is-danger {
    color: ${Color.fontRed};
  }

  &:hover {
    background-color: ${Color.backgroundGrayED};
  }
`;

const StyledMenuItemLink = styled.a`
  border-bottom: 1px solid ${Color.borderColorED};
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 14px 20px;

  &:hover {
    background-color: ${Color.backgroundGrayED};
    text-decoration: none;
  }
`;

const StyledSubMenu = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px #eee;
  left: -320px;
  padding: 20px 30px;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 1;
`;

const StyledInput = styled.input`
  border: 1px solid ${Color.borderColorCB};
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
`;

const StyledMenuTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 5px 10px;
  width: 100px;

  .delete & {
    background-color: ${Color.important};
    color: #fff;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledModalContent = styled.div`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  max-height: 320px;
  overflow-y: scroll;
  padding: 10px 25px;
  width: 100%;
  z-index: 1;
`;

const StyledModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledModalUploadArea = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayE7};
  border: 1px splid ${Color.borderColorCB};
  display: flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  text-align: center;

  &.is-file {
    background-color: ${Color.backgroundBlueSecondary};
  }

  input {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;

const StyledUploadFileText = styled.p`
  color: ${Color.secondary};
`;
