import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RiFileCopy2Line } from 'react-icons/ri';
import { VscSettings } from 'react-icons/vsc';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store';
import {
  Folder,
  SearchDocumentDto,
} from '../../../../lib/api-client/@types/index';
import FolderList from './FolderList';
import Color from '../../../../utils/constants/styles/Color';
import SearchForm from './SearchForm';
import CreateFolderModal from '../../../../components/organisms/CreateFolderModal';

interface SidebarPropsType {
  keywordFromSearchBar: string;
  folders: Folder[];
  displayFolder?: Folder;
  searchDocuments: (query: SearchDocumentDto) => Promise<void>;
  tab;
  selectTab;
}

const Sidebar: React.VFC<SidebarPropsType> = ({
  keywordFromSearchBar,
  folders,
  displayFolder,
  searchDocuments,
  tab,
  selectTab,
}) => {
  const { t } = useTranslation();
  const [Modal, open, close, isOpen] = useModal('root', {});

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    keywordFromSearchBar && selectTab('search');
  }, [keywordFromSearchBar, selectTab]);

  return (
    <StyledSidebar>
      <StyledSidebarTab>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <StyledSidebarTabItem
          onClick={() => selectTab('folder')}
          className={tab === 'folder' ? 'is-active' : ''}
        >
          <RiFileCopy2Line className="icon" />
          {t('契約書一覧')}
        </StyledSidebarTabItem>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <StyledSidebarTabItem
          onClick={() => selectTab('search')}
          className={tab === 'search' ? 'is-active' : ''}
        >
          <VscSettings className="icon" />
          {t('契約書を検索')}
        </StyledSidebarTabItem>
      </StyledSidebarTab>
      {tab === 'folder' && (
        <>
          <StyledFolderListWrapper>
            <FolderList folders={folders} displayFolder={displayFolder} />
          </StyledFolderListWrapper>
          <StyledSidebarButton type="button" onClick={() => open()}>
            <AiOutlinePlusSquare className="icon" />
            {t('フォルダ追加')}
          </StyledSidebarButton>
        </>
      )}
      {tab === 'search' && (
        <SearchForm
          selectTab={selectTab}
          keywordFromSearchBar={keywordFromSearchBar}
          searchDocuments={searchDocuments}
        />
      )}
      <CreateFolderModal
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
        defaultTabName="summary"
        folders={folders}
        isStored
      />
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  background: #f8f8f8;
  padding: 50px 12px;
  width: 300px;
`;

const StyledSidebarButton = styled.button`
  align-items: center;
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 15px 20px;
  width: 100%;

  .icon {
    height: 18px;
    margin-right: 4px;
    width: 18px;
  }
`;

const StyledSidebarTab = styled.ul``;

const StyledSidebarTabItem = styled.li`
  align-items: center;
  border-bottom: 1px solid ${Color.borderColorE8};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 12px;

  &:hover {
    background-color: ${Color.backgroundGrayED};
  }

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }

  &.is-active {
    background-color: ${Color.secondary};
    border-radius: 10px;
    color: #fff;
  }
`;

const StyledFolderListWrapper = styled.div`
  border-bottom: 1px solid ${Color.borderColorE8};
  margin-bottom: 50px;
  padding-bottom: 40px;
  padding-top: 10px;
`;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 500px;
  padding: 30px;
`;
