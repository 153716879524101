import { Task } from '../../../lib/api-client/@types';
import { isInReadonlyArray } from '../../../types';
import {
  allStatusOptions,
  statusPatterns,
} from '../../../utils/constants/data/options';
import { TaskWithProgress } from '../../../utils/constants/data/tableData';

export const useGetTaskWithProgress = (tasks: Task[]) => {
  const taskWithProgress: TaskWithProgress[] = tasks.map((task) => {
    const index = Object.values(statusPatterns)
      .map((statusPattern) =>
        statusPattern.findIndex(
          (item) =>
            item.genre === task.task_genre &&
            isInReadonlyArray(allStatusOptions, task.status) &&
            item.status.includes(task.status)
        )
      )
      .findIndex((item) => item !== -1);
    return { ...task, progress_index: index };
  });
  return { taskWithProgress };
};
