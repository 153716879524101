import React from 'react';
// import { Link } from 'react-router-dom';
// import { BiPen } from 'react-icons/bi';
import { AiOutlineUpload } from 'react-icons/ai';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Color from '../../../utils/constants/styles/Color';

const DocumentButtonArea = ({ open }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* TODO: βリリースでは非表示 */}
      {/* <StyledButton type="button"> */}
      {/*  <Link to="/templates"> */}
      {/*    <BiPen /> */}
      {/*    雛形を使用して契約書を作成する */}
      {/*  </Link> */}
      {/* </StyledButton> */}
      <StyledButton type="button">
        <span onClick={() => open()}>
          <AiOutlineUpload />
          {t('契約書をアップロードする')}
        </span>
      </StyledButton>
    </>
  );
};

export default DocumentButtonArea;

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 0;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    opacity: 0.7;
  }

  a,
  span {
    align-items: center;
    background-color: ${Color.secondary};
    border-radius: 3px;
    color: white;
    color: ${Color.fontColorWhite};
    display: flex;
    padding: 15px 20px;

    svg {
      margin-right: 10px;
    }
  }

  a:link,
  a:visited {
    background-color: ${Color.secondary};
    color: ${Color.fontColorWhite};
  }
`;
