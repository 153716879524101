import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { NetworkErrorModal } from '../components/modules/NetworkErrorModal';

type Props = {
  children: ReactNode;
};

type HasError = {
  setHasError: Dispatch<SetStateAction<boolean>>;
};

const NetworkErrorModalContext = createContext({} as HasError);

export const useNetworkErrorModalContext = (): HasError =>
  useContext(NetworkErrorModalContext);

export const NetworkErrorModalProvider = ({ children }: Props): JSX.Element => {
  const [hasError, setHasError] = useState<boolean>(false);
  const value = useMemo(() => ({ setHasError }), []);
  return (
    <NetworkErrorModalContext.Provider value={value}>
      {children}
      <NetworkErrorModal hasError={hasError} />
    </NetworkErrorModalContext.Provider>
  );
};
