import React from 'react';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import Color from '../../../../utils/constants/styles/Color';
import FolderMenu from '../../../../components/modules/FolderMenu';
import { Folder, Document } from '../../../../lib/api-client/@types/index';

interface TableHeaderProps {
  displayFolder;
  parentFolder;
  folders: Folder[];
  documents: Document[];
}

const TableHeader: React.VFC<TableHeaderProps> = ({
  displayFolder,
  parentFolder,
  folders,
  documents,
}) => (
  <StyledTableHeader>
    <StyledTableHeaderLeft>
      <AiOutlineFolderOpen className="icon" />
      {parentFolder && (
        <div>
          <span>{parentFolder.name}</span>
          <IoIosArrowForward />
        </div>
      )}
      <span>{displayFolder?.name}</span>
    </StyledTableHeaderLeft>
    <StyledTableHeaderRight>
      <FolderMenu
        displayFolder={displayFolder}
        folders={folders}
        documents={documents}
      />
    </StyledTableHeaderRight>
  </StyledTableHeader>
);

export default TableHeader;

const StyledTableHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Color.borderColorE7};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding: 15px 35px 10px 15px;
`;

const StyledTableHeaderLeft = styled.div`
  align-items: center;
  display: flex;

  .icon {
    height: 25px;
    margin-right: 10px;
    width: 25px;
  }
`;

const StyledTableHeaderRight = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  .icon {
    height: 16px;
    margin-right: 3px;
    width: 16px;
  }
`;
