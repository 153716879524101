import React from 'react';
import styled from 'styled-components';
import Color from '../../../../utils/constants/styles/Color';

const ProfileContainer = ({ user }) => {
  const { company_name, department, email, username } = user;

  // userNameの二文字目までを表示
  const handleFirstLetter = (name: string) => name?.slice(0, 2);
  const firstLetterName = handleFirstLetter(username);

  return (
    <Container>
      <ProfileIcon>
        <ProfileName>{firstLetterName}</ProfileName>
      </ProfileIcon>
      <ProfileText>
        <Text>
          <span>会社名:</span> {company_name}
        </Text>
        {/* β版は対象外 */}
        {/* <Text> */}
        {/*  <span>部署名:</span> {department} */}
        {/* </Text> */}
        <Text>
          <span>名前:</span> {username}
        </Text>
        <Text>
          <span>メール:</span> {email}
        </Text>
      </ProfileText>
    </Container>
  );
};

export default ProfileContainer;

const Text = styled.div`
  margin-bottom: 10px;

  span {
    font-weight: 700;
  }
`;

const Container = styled.div`
  align-items: flex-start;
  display: flex;
`;

const ProfileIcon = styled.div`
  margin-right: 30px;
`;

const ProfileText = styled.div``;

const ProfileName = styled.div`
  align-items: center;
  background-color: ${Color.primary};
  border-radius: 50%;
  color: ${Color.fontColorWhite};
  display: flex;
  font-size: 35px;
  font-weight: 700;
  height: 100px;
  justify-content: center;
  width: 100px;
`;
