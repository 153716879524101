import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import Toast from '../components/atoms/Toast';

type Props = {
  children: ReactNode;
};

type ToastContextType = {
  setToastText: (text: string) => void;
};

const ToastContext = createContext({} as ToastContextType);

const ToastContextProvider = ({ children }: Props) => {
  const [isToast, toggleToast] = useState(false);
  const [text, setToastText] = useState('');
  useEffect(() => {
    if (!text) return;
    toggleToast(true);
    setTimeout(() => {
      toggleToast(false);
    }, 3000);
    setTimeout(() => {
      setToastText('');
    }, 4000);
  }, [setToastText, text]);

  const addToastText = useMemo(() => ({ setToastText }), []);

  return (
    <ToastContext.Provider value={addToastText}>
      {children}
      <Toast text={text} isToast={isToast} />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);

export default ToastContextProvider;
