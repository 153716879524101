import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import SignUp from '../pages/signup';
import AuthenticatedGuard from './AuthenticatedGuard';
import Login from '../pages/login';
import Layout from '../components/organisms/Layout';
import Home from '../pages/home';
import Document from '../pages/documents';
import Register from '../pages/register';
import AccountPage from '../pages/account';
import Detail from '../pages/detail';
import Privacy from '../pages/privacy';
import DocumentTemplate from '../pages/templates';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route element={<AuthenticatedGuard />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="documents/:id" element={<Document />} />
          <Route path="register/:id" element={<Register />} />
          <Route path="account" element={<AccountPage />} />
        </Route>
      </Route>
      <Route path="signup" element={<SignUp />} />
      <Route path="login" element={<Login />} />
      <Route path="detail/:id" element={<Detail />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="templates" element={<DocumentTemplate />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
