import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { useAppSelector, useAppDispatch } from '../../store';
import Sidebar from './components/molecules/Sidebar';
import DocumentTable from './components/molecules/DocumentTable';
import {
  fetchDocumentsInFolder,
  initializeDocuments,
  selectDocuments,
} from '../../features/document/documentSlice';
import {
  DOCUMENTS_COLUMN,
  DOCUMENTS_COLUMN_EN,
} from '../../utils/constants/data/tableData';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { selectLng } from '../../features/language/languageSlice';
import Layout from '../../components/organisms/Layout';
import Color from '../../utils/constants/styles/Color';
import UploadModal from '../../components/organisms/UploadModal';
import Heading from './components/molecules/Heading';
import TableHeader from './components/molecules/TableHeader';
import { useSearchDocuments } from './hooks/useSearchDocuments';
import ToastContextProvider from '../../context/useToastContext';

const Document = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const documents = useAppSelector(selectDocuments);
  const folders = useAppSelector(selectFolders);
  const currentLng = useAppSelector(selectLng);
  const { documents: searchedDocs, searchDocuments } = useSearchDocuments();

  const orderedFolders = folders
    .filter((folder) => !folder.is_stored)
    .sort((a, b) => {
      if (a.name === b.name) return 0;
      return a.name > b.name ? 1 : -1;
    });
  const archiveFolder = orderedFolders.filter(({ name }) => name === 'ARCHIVE');
  const nonArchiveFolders = orderedFolders.filter(
    ({ name }) => name !== 'ARCHIVE'
  );
  const displayFolders = [...nonArchiveFolders, ...archiveFolder];
  const [tab, selectTab] = useState<'folder' | 'search'>('folder');
  const [Modal, open, close, isOpen] = useModal('root', {});
  // urlからフォルダを取得
  const folderIdFromPath = params.id as string;
  const [firstId, secondId] = folderIdFromPath.split('_');
  const childId = secondId || firstId;
  const parentIdFromPath = firstId === childId ? '' : firstId;
  const parentFolder = folders.find(
    ({ id, parent_id }) => String(id) === parentIdFromPath && !parent_id
  );
  const displayFolder = folders.find(({ id, parent_id }) => {
    if (!parentFolder) return String(id) === childId;
    return String(id) === childId && parentFolder.id === parent_id;
  });

  // headerのキーワード検索からの場合
  // @ts-ignore
  const keywordFromSearchBar = location?.state?.keyword;

  // 初期レンダリング時に全てのフォルダを取得
  useEffect(() => {
    void (async () => {
      dispatch(initializeDocuments());
      await dispatch(fetchFolders());
    })();
  }, [dispatch]);

  // フォルダの中のドキュメントを取得
  useEffect(() => {
    if (!folders.length || !displayFolder) return;
    void (async () => {
      await dispatch(
        fetchDocumentsInFolder({ folderId: String(displayFolder.id) })
      );
    })();
  }, [dispatch, folderIdFromPath, folders, displayFolder]);

  return (
    <ToastContextProvider>
      <StyledContainer>
        <Sidebar
          keywordFromSearchBar={keywordFromSearchBar}
          displayFolder={displayFolder}
          folders={displayFolders}
          searchDocuments={searchDocuments}
          selectTab={selectTab}
          tab={tab}
        />
        <StyledContents>
          <Heading open={open} displayFolder={displayFolder} tab={tab} />

          <StyledTableWrapper>
            {tab === 'folder' && (
              <TableHeader
                displayFolder={displayFolder}
                parentFolder={parentFolder}
                folders={displayFolders}
                documents={documents}
              />
            )}
            <DocumentTable
              columns={
                currentLng === 'jp' ? DOCUMENTS_COLUMN : DOCUMENTS_COLUMN_EN
              }
              data={documents}
              folders={displayFolders}
              displayFolder={displayFolder}
            />
          </StyledTableWrapper>
        </StyledContents>
      </StyledContainer>
      <UploadModal
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
        defaultTabName="summary"
        folders={displayFolders}
        defaultSelectedFolder={displayFolder}
        status="作成中"
      />
    </ToastContextProvider>
  );
};

export default Document;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledContents = styled.div`
  padding: 32px;
  width: 100%;
`;

const StyledTableWrapper = styled.div`
  background-color: ${Color.backgroundGrayF7};
  border: 1px solid ${Color.borderColorE7};
`;
