import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from './components/modules/Sidebar';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  fetchDocument,
  fetchDocuments,
  fetchDocumentTasks,
  selectDocument,
  selectDocuments,
  selectDocumentTasks,
} from '../../features/document/documentSlice';
import Header from '../../components/modules/Header';
import DocumentPreview from './components/modules/DocumentPreview';
import Toast from '../../components/atoms/Toast';
import { fetchAccountInfo, selectUser } from '../../features/user/userSlice';
import ToastContextProvider from '../../context/useToastContext';

const Detail = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const defaultTabName: string = location.state
    ? // @ts-ignore
      location?.state?.defaultTabName
    : 'summary';
  const tasks = useAppSelector(selectDocumentTasks);
  const documents = useAppSelector(selectDocuments);
  const documentTasks = useSelector(selectDocumentTasks);
  const user = useAppSelector(selectUser);
  const [tabName, setTabName] = useState(defaultTabName);
  const params = useParams();
  const documentId = params.id as string;
  const document = useSelector(selectDocument);
  useEffect(() => {
    void (async () => {
      await dispatch(fetchDocument(documentId));
      await dispatch(fetchDocuments({}));
      await dispatch(fetchAccountInfo());
      await dispatch<any>(fetchDocumentTasks(documentId));
    })();
  }, [dispatch, documentId]);
  if (!document) return null;

  return (
    <ToastContextProvider>
      <Header />
      <StyledWrapper>
        <Sidebar
          tabName={tabName}
          setTabName={setTabName}
          document={document}
          documentTasks={documentTasks}
        />
        <StyledMain>
          <DocumentPreview document={document} />
        </StyledMain>
      </StyledWrapper>
    </ToastContextProvider>
  );
};

export default Detail;
const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 97px;
`;

const StyledMain = styled.div`
  padding-left: 15px;
  padding-right: 30px;
  width: 100%;
`;
