import axiosClient from '@aspida/axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import api from '../lib/api-client/$api';

const axiosInstance = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 3000,
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

export const apiClient = () => {
  const token = Cookies.get('access_token');
  const instance = axiosInstance(token);
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // 何らかの処理が可能
      if (error.response.status === 401) {
        //
        location.href = '/login';
      } else if (error.response.status === 403) {
        //
      }
      return Promise.reject(error);
    }
  );
  return api(axiosClient(instance));
};
