import React, { useState } from 'react';
import styled from 'styled-components';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { IoMdArrowDropup } from 'react-icons/io';
import pdfImg from '../../../assets/images/pdf.svg';
import Color from '../../../utils/constants/styles/Color';

interface AccordionListItemProps {
  category: string;
  templates: { name: string }[];
  setTemplate: any;
}

const AccordionListItem: React.VFC<AccordionListItemProps> = ({
  category,
  templates,
  setTemplate,
}) => {
  const [isOpen, open] = useState(false);
  return (
    <StyledAccordionListItem>
      <StyledCategory onClick={() => open((prevState) => !prevState)}>
        <IoMdArrowDropup className={isOpen ? 'icon is-open' : 'icon'} />
        <IoDocumentTextOutline />
        {category}
      </StyledCategory>
      {isOpen && (
        <StyledDocumentList>
          {templates.map((template) => (
            <li onClick={() => setTemplate(template)}>
              <img className="icon" src={pdfImg} alt="" />
              {template.name}
            </li>
          ))}
        </StyledDocumentList>
      )}
    </StyledAccordionListItem>
  );
};

export default AccordionListItem;

const StyledAccordionListItem = styled.li`
  margin-bottom: 10px;
`;

const StyledCategory = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;

  .icon {
    color: ${Color.backgroundGrayA6};

    &.is-open {
      transform: rotate(180deg);
    }
  }
`;

const StyledDocumentList = styled.ul`
  padding: 10px;

  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    padding: 5px;

    &:hover {
      background-color: #fff;
    }

    .icon {
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
  }
`;
