import React, { useState } from 'react';
import styled from 'styled-components';
import { AiOutlineFolder, AiOutlineFolderOpen } from 'react-icons/ai';
import { Document, Folder } from '../../lib/api-client/@types/index';
import { apiClient } from '../../utils/apiClient';
import pdfImg from '../../assets/images/pdf.svg';
import Color from '../../utils/constants/styles/Color';

interface DocumentFolderSelectItemProps {
  folder: Folder;
  folders: Folder[];
  isChild: boolean;
  targetItem: Document;
  selectItem: () => void;
  setIsDisable: () => void;
}

const DocumentSelectItem = ({
  folder,
  folders,
  isChild = false,
  targetItem,
  selectItem,
  setIsDisable,
}) => {
  const [displayDocuments, setDocuments] = useState<Document[]>([]);
  const [isOpen, openFolder] = useState(false);
  // フォルダの中のドキュメントを取得
  const onClick = async () => {
    const documents = await apiClient()
      .v1.folders._folderId(folder.id)
      .documents.$get();
    setDocuments(documents);
    selectItem(folder);
    openFolder(true);
    setIsDisable(false);
  };

  const childFolders = folders.filter(
    ({ parent_id }) => folder.id === parent_id
  );

  return (
    <StyledItem className={isChild ? 'is-child' : ''}>
      <StyledFolder
        onClick={() => {
          void onClick();
        }}
        className={targetItem === folder ? 'is-active' : ''}
      >
        {isOpen ? (
          <AiOutlineFolderOpen className="icon" />
        ) : (
          <AiOutlineFolder className="icon" />
        )}
        {folder.name}
      </StyledFolder>
      {displayDocuments.map((document) => (
        <StyledDocument
          onClick={() => selectItem(document)}
          className={targetItem === document ? 'is-active' : ''}
        >
          <img src={pdfImg} alt="" />
          {document.name}
        </StyledDocument>
      ))}
      {!isChild && (
        <StyledChildFolderWrapper>
          {childFolders.map((childFolder) => (
            <DocumentSelectItem
              key={childFolder.id}
              folder={childFolder}
              folders={folders}
              selectItem={selectItem}
              targetItem={targetItem}
              isChild
              setIsDisable={setIsDisable}
            />
          ))}
        </StyledChildFolderWrapper>
      )}
    </StyledItem>
  );
};

export default DocumentSelectItem;

const StyledItem = styled.div`
  font-size: 16px;
  font-weight: bold;

  &.is-child {
    font-size: 15px;
    margin-left: 15px;
  }

  .icon {
    margin-right: 5px;
  }
`;

const StyledFolder = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px;

  &.is-active {
    background-color: ${Color.backgroundBlueSecondary};
    color: ${Color.secondary};
  }
`;

const StyledDocument = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 5px;
  padding: 8px;

  &.is-active {
    background-color: ${Color.backgroundBlueSecondary};
    color: ${Color.secondary};
  }

  img {
    height: 15px;
    margin-right: 5px;
    width: 15px;
  }
`;

const StyledChildFolderWrapper = styled.div`
  margin-left: 5px;
  padding: 5px;
`;
