import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineFolderOpen, AiOutlineFolder } from 'react-icons/ai';

import styled from 'styled-components';
import { editFolderName } from '../../../../features/folder/folderSlice';
import { useAppDispatch } from '../../../../store';
import { Folder } from '../../../../lib/api-client/@types/index';
import FolderListSub from './FolderListSub';
import Color from '../../../../utils/constants/styles/Color';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';

interface FolderListProps {
  folders: Folder[];
  displayFolder?: Folder;
}

const FolderList: React.VFC<FolderListProps> = ({ folders, displayFolder }) => {
  const navigate = useNavigate();
  const [editingId, editText] = useState('');
  const dispatch = useAppDispatch();
  const { setHasError } = useNetworkErrorModalContext();
  const endEdit = async (
    event: React.FocusEvent<HTMLInputElement>,
    id: string,
    parentId: number
  ) => {
    await dispatch(
      editFolderName({
        name: event.target.value,
        id: Number(id),
        parent_id: displayFolder?.parent_id || null,
        is_stored: displayFolder?.is_stored || false,
      })
    ).then((result) => {
      if (editFolderName.rejected.match(result)) {
        setHasError(true);
        return;
      }
      editText('');
      navigate(`/documents/${event.target.value}`);
    });
  };

  const originalFolder = folders.filter((folder) => !folder.parent_id);
  const folderList = folders.find(({ parent_id }) => !parent_id)
    ? originalFolder
    : folders;
  return (
    <ul>
      {folderList.map(({ name, id, parent_id }) => (
        <StyledListItem key={id}>
          <StyledListItemFolder>
            <Link
              className={id === displayFolder?.id ? 'link is-active' : 'link'}
              to={`/documents/${parent_id ? `${parent_id}_${id}` : id}`}
            >
              {id === displayFolder?.id ? (
                <AiOutlineFolderOpen className="folderIcon" />
              ) : (
                <AiOutlineFolder className="folderIcon" />
              )}
              <StyledFolderName>{name}</StyledFolderName>
            </Link>
          </StyledListItemFolder>
          <FolderListSub
            folders={folders}
            displayFolder={displayFolder}
            id={id}
            key={id}
          />
        </StyledListItem>
      ))}
    </ul>
  );
};

export default FolderList;

const StyledListItemFolder = styled.div`
  align-items: center;
  color: #333;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  text-decoration: none;

  .link {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: ${Color.backgroundBlueSecondary};
    }

    &.is-active {
      background-color: #fff;
      border-radius: 4px;
      color: ${Color.secondary};
    }
  }

  .folderIcon {
    height: 25px;
    margin-right: 5px;
    width: 25px;
  }

  .editIcon {
    opacity: 0.3;

    :hover {
      opacity: 1;
    }
  }
`;

const StyledListItem = styled.li``;

const StyledFolderName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
