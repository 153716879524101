import React, { useEffect } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import styled from 'styled-components';
import { BiTask } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Color from '../../../../utils/constants/styles/Color';
import {
  fetchDocumentTasks,
  selectDocumentTasks,
} from '../../../../features/document/documentSlice';
import { useAppDispatch } from '../../../../store';
import TaskCard from './TaskCard';
import SummaryTab from './SummaryTab';
import RemarksTab from './RemarksTab';
import RelatedDocumentTab from './RelatedDocumentTab';
import TaskTab from './TaskTab';

const Sidebar = ({
  document,
  tabName = 'summary',
  setTabName,
  documentTasks,
}) => {
  const { t } = useTranslation();
  const isTaskTab = tabName === 'task';
  const isSummaryTab = tabName === 'summary';
  const isRelatedTab = tabName === 'related';
  const isRemarksTab = tabName === 'remarks';

  return (
    <StyledSidebar>
      <StyledSidebarContainer>
        {isSummaryTab && (
          <SummaryTab document={document} setTabName={setTabName} />
        )}
        {isTaskTab && (
          <TaskTab
            documentTasks={documentTasks}
            documentId={document.id}
            documentStatus={document.status}
          />
        )}
        {isRelatedTab && <RelatedDocumentTab document={document} />}
        {isRemarksTab && <RemarksTab document={document} />}
      </StyledSidebarContainer>

      <StyledSidebarHeading>
        <StyledSidebarHeadingItem
          className={isSummaryTab ? 'is-active' : ''}
          onClick={() => setTabName('summary')}
        >
          <RiFileCopy2Line className="icon" />
          <StyledSidebarHeadingItemLabel>
            {t('契約概要')}
          </StyledSidebarHeadingItemLabel>
        </StyledSidebarHeadingItem>
        <StyledSidebarHeadingItem
          className={isTaskTab ? 'is-active' : ''}
          onClick={() => setTabName('task')}
        >
          <BiTask className="icon" />
          {t('タスク')}
        </StyledSidebarHeadingItem>
        <StyledSidebarHeadingItem
          className={isRelatedTab ? 'is-active' : ''}
          onClick={() => setTabName('related')}
        >
          <IoDocumentTextOutline className="icon" />
          {t('関連契約書')}
        </StyledSidebarHeadingItem>
        <StyledSidebarHeadingItem
          className={isRemarksTab ? 'is-active' : ''}
          onClick={() => setTabName('remarks')}
        >
          <AiOutlineExclamationCircle className="icon" />
          {t('備考欄')}
        </StyledSidebarHeadingItem>
      </StyledSidebarHeading>
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 400px;
`;

const StyledSidebarContainer = styled.div`
  background-color: ${Color.backgroundGrayF3};
  min-height: 100vh;
  padding: 20px;
  width: calc(400px - 70px);
`;

const StyledSidebarHeading = styled.div`
  background-color: ${Color.backgroundWhite};
  border-left: 1px solid ${Color.borderColorE7};
  border-right: 1px solid ${Color.borderColorE7};
  font-size: 18px;
  font-weight: bold;
  height: 100vh;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledSidebarHeadingItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Color.borderColorE7};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding: 12px;
  width: 75px;

  .icon {
    display: block;
    margin-bottom: 5px;
  }

  &.is-active {
    background-color: ${Color.backgroundGrayF3};
  }
`;

const StyledSidebarHeadingItemLabel = styled.div`
  text-align: center;
`;
