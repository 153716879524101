import { BsFillPersonFill } from 'react-icons/bs';
import { BiLinkExternal, BiTask, BiTimeFive } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Color from '../../../../utils/constants/styles/Color';
import {
  addTaskComment,
  changeStatus,
  updateDocument,
} from '../../../../features/document/documentSlice';
import { useAppDispatch } from '../../../../store';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';
import { useToastContext } from '../../../../context/useToastContext';

const today = dayjs();

const TaskCard = ({ task }) => {
  const { t } = useTranslation();
  const { task_genre, status, manager, client, limit_date, id, document_id } =
    task;
  const { setToastText } = useToastContext();
  const [selectedStatus, selectStatus] = useState(status);
  const { setHasError } = useNetworkErrorModalContext();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const { register, handleSubmit, reset } = useForm<any>({ mode: 'all' });

  const isDone = status === '完了';
  const isConclusion = task_genre === '締結依頼';

  const conclusionUrl =
    'https://www.docusign.net/Member/authenticate.aspx?dscj_ft=https%253A%252F%252Fwww.google.com%252F%2C1652228559%2Chttps%253A%252F%252Fwww.docusign.jp%252F&dscj_lt=https%253A%252F%252Fwww.google.com%252F%2C1652228566%2Chttps%253A%252F%252Fwww.docusign.jp%252F&dscj_m=referringUrl%2Cts%2Ccu&_gl=1*n71609*_ga*MTA3OTc2OTcwNC4xNjUyMjI4NTYw*_ga_1TZ7S9D6BQ*MTY1MjIyODU1OS4xLjEuMTY1MjIyODU3NC4w';

  useEffect(() => {
    if (isDone) {
      setIsOpen(false);
    }
  }, [isDone]);

  const commentList = task?.comments?.map(
    ({ id: comment_id, email, create_date, message }) => (
      <StyledCommentListItem key={comment_id}>
        <StyledCommentInfo>
          <span>
            <BsFillPersonFill />
            {email}
          </span>
          <span>
            <BiTimeFive />
            {dayjs(create_date).format('YYYY年M月D日')}
          </span>
        </StyledCommentInfo>
        <StyledCommentMessage>{message}</StyledCommentMessage>
      </StyledCommentListItem>
    )
  );

  const className = () => {
    if (selectedStatus === '未着手') return 'is-yet';
    if (selectedStatus === '進行中') return 'is-progress';
    if (selectedStatus === '完了') return 'is-done';
    return '';
  };

  const handleOnChange = async (event) => {
    selectStatus(event.target.value);
    setIsOpen(event.target.value !== '完了');

    const result = await dispatch(
      changeStatus({ id, status: event.target.value }) // TODO task_genreとdocument_idいらない？
    ).then(
      // @ts-ignore //TODO: エラーハンドリングできていない
      ({ payload: { status: res_status, task_genre: res_task_genre } }) => {
        setToastText(`${res_task_genre}のタスクを${res_status}にしました`);
        // 「作成」タスクを完了
        // 「承認」タスクを完了
        // 「締結依頼」タスクを完了
        // 締結済みの契約書が直接アップロードor自動連携される (ここはバックエンド側で行う必要あり)
        // 「格納」タスクの完了
        if (res_status === '完了' && task_genre !== 'リマインド') {
          const obj = {
            作成: '承認待ち',
            承認: '承認完了',
            締結依頼: '締結確認',
            格納: '格納完了',
          };
          return dispatch(
            updateDocument({
              document: { id: document_id, status: obj[res_task_genre] },
            })
          );
        }
        return null;
      }
    );
    if (result === null) return;
    if (updateDocument.rejected.match(result)) {
      setHasError(true);
    }
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const result = await dispatch(
      addTaskComment({ id, message: data.message })
    );
    if (addTaskComment.rejected.match(result)) {
      setHasError(true);
      return;
    }
    setToastText('メッセージを追加しました');
    reset();
  };

  return (
    <StyledTaskItem>
      <StyledTaskHeader>
        {/* TODO: βリリースでは非表示 */}
        {/* className={isConclusion ? 'is-conclusion' : ''}> */}
        <StyledTaskGenre className="genre">
          <BiTask className="icon" />
          {t(task_genre)}
        </StyledTaskGenre>
        <StyledHeaderAside>
          <StyledStatus className={className()}>
            <select
              name=""
              id=""
              value={selectedStatus}
              onChange={handleOnChange}
            >
              <option value="未着手">{t('未着手')}</option>
              <option value="進行中">{t('進行中')}</option>
              <option value="完了">{t('完了')}</option>
            </select>
          </StyledStatus>
          <MdKeyboardArrowUp
            onClick={() => setIsOpen(!isOpen)}
            className={isOpen ? 'header-arrow is-open' : 'header-arrow'}
          />
        </StyledHeaderAside>
      </StyledTaskHeader>
      {isOpen && (
        <StyledTaskContainer>
          <StyledTaskManager>
            <BsFillPersonFill />
            {t('担当者')}: {manager}
          </StyledTaskManager>
          <StyledTaskClient>
            <BsFillPersonFill />
            {t('依頼者')}: {client}
          </StyledTaskClient>
          <StyledTaskLimitDate
            className={dayjs(limit_date) <= today ? 'is-expired' : ''}
          >
            <BiTimeFive />
            {t('期限')}: {dayjs(limit_date).format('YYYY年M月D日')}
          </StyledTaskLimitDate>
          <StyledCommentList>
            {commentList}
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <StyledCommentInputWrapper onSubmit={handleSubmit(onSubmit)}>
              <StyledCommentInput
                placeholder={t('コメントを記入する')}
                {...register('message', { required: true })}
              />
              <StyledCommentButton>{t('送信')}</StyledCommentButton>
            </StyledCommentInputWrapper>
          </StyledCommentList>
          {/* TODO: βリリースでは非表示 */}
          {/* {isConclusion ? ( */}
          {/*  <StyledConclude */}
          {/*    className="is-emphasis" */}
          {/*    target="_blank" */}
          {/*    href={conclusionUrl} */}
          {/*  > */}
          {/*    締結を依頼する <BiLinkExternal /> */}
          {/*  </StyledConclude> */}
          {/* ) : null} */}
        </StyledTaskContainer>
      )}
    </StyledTaskItem>
  );
};

export default TaskCard;

const StyledTaskItem = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 1px 1px 4px;
  margin-bottom: 15px;
  position: relative;

  &.is-done {
    background-color: ${Color.borderColorE7};

    ul {
      background-color: ${Color.borderColorE7};
    }

    .status {
      background-color: ${Color.borderColorE7};
      color: #333;
    }
  }
`;

const StyledTaskContainer = styled.div`
  padding: 15px;
`;

const StyledTaskHeader = styled.div`
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px 15px;

  .icon {
    margin-right: 5px;
  }

  .is-close {
    border-radius: 4px;
  }

  &.is-conclusion {
    background-color: ${Color.secondary};

    .header-arrow {
      color: ${Color.fontColorWhite};
    }

    .genre {
      color: ${Color.fontColorWhite};
    }
  }
`;

const StyledHeaderAside = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  .header-arrow {
    font-size: 20px;

    &.is-open {
      transform: rotate(180deg);
    }
  }
`;

const StyledTaskGenre = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: bold;
`;

const StyledTaskManager = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 8px;
`;
const StyledTaskClient = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 8px;
`;
const StyledTaskLimitDate = styled.div`
  align-items: center;
  color: ${Color.secondary};
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 8px;

  &.is-expired {
    color: ${Color.fontRed};
    font-weight: bold;
  }
`;

const StyledStatus = styled.div`
  background-color: #fff;
  border-radius: 4px;
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  width: 50px;

  select {
    align-items: center;

    /* 初期化 */
    appearance: none;
    background: none;
    background-color: ${Color.backgroundGrayE7};
    border: none;
    border-radius: 3px;
    color: #333;
    color: ${Color.fontColorWhite};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
    margin-right: 4px;
    padding: 10px;
    padding: 5px;
    text-align: center;
    width: 100%;
  }

  &.is-yet select {
    background-color: ${Color.important};
  }

  &.is-progress select {
    background-color: #fbb917;
  }

  &.is-done select {
    background-color: #91d7c3;
  }
`;

const StyledCommentList = styled.ul`
  font-size: 12px;
`;

const StyledCommentListItem = styled.li`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorE7};
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 10px;
`;

const StyledCommentInfo = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledCommentMessage = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const StyledCommentButton = styled.button`
  appearance: none;
  background-color: ${Color.secondary};
  border: none;
  color: ${Color.fontColorWhite};
  cursor: pointer;
  margin-left: 5px;
  padding: 6px;
  width: 50px;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledCommentInput = styled.textarea`
  appearance: none;
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorE7};
  border-radius: 4px;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 10px;
  width: 100%;
`;

const StyledCommentInputWrapper = styled.form`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const StyledConclude = styled.a`
  align-items: center;
  background-color: ${Color.secondary};
  border-radius: 3px;
  color: ${Color.fontColorWhite};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 15px auto 0;
  padding: 12px 0;
  width: 80%;

  &:link,
  &:visited {
    color: ${Color.fontColorWhite};
  }
`;
