import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { apiClient } from '../../utils/apiClient';
import { Company, User } from '../../lib/api-client/@types/index';
import { UserRegisterRequest } from '../../types';

export interface UserState {
  user: User;
  company: Company;
}

const initialState: UserState = {
  // @ts-ignore TODO: backend改修後にstoplight書き換え？
  user: {
    id: 1,
    email: '',
    password: '',
    is_bell: false,
    company_id: 1,
  },
  company: {
    company: '',
    member: [
      {
        id: 1,
        username: 'sample',
        email: 'init@state',
        department: 'test部署',
      },
      {
        id: 2,
        username: 'sample2',
        email: 'init@state2',
        department: 'test部署',
      },
    ],
  },
};

export const registerUser = createAsyncThunk<
  { token: string },
  { user: UserRegisterRequest },
  {
    rejectValue: { errorMessage: string };
  }
>('user/register', async ({ user }, { rejectWithValue }) => {
  try {
    return await apiClient().v1.register.$post({ body: user });
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const fetchAccountInfo = createAsyncThunk<User>('user/fetch', () =>
  apiClient().v1.user.myself.$get()
);

export const fetchCompanyInfo = createAsyncThunk<Company>(
  'user/fetchCompany',
  () => apiClient().v1.company.$get()
);

export const userlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.fulfilled, (state, action) => {
      localStorage.setItem('localJWT', action.payload.token);
    });
    builder.addCase(fetchAccountInfo.fulfilled, (state, action) => ({
      ...state,
      user: action.payload,
    }));
    builder.addCase(fetchCompanyInfo.fulfilled, (state, action) => ({
      ...state,
      company: action.payload,
    }));
  },
});

export const selectUser = (state: RootState) => state.user.user;
export const selectCompanyMember = (state: RootState) =>
  state.user.company.member;

export default userlice.reducer;
