import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '../../components/atoms/Input';
import { SignUpDto } from '../../lib/api-client/@types';
import { ERROR_MESSAGES } from '../../validations/const';

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpDto>({ mode: 'all' });

  const onSubmit: SubmitHandler<SignUpDto> = (data) => {
    //
  };

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledTitle>会員登録</StyledTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="email"
            type="email"
            register={register('email', {
              required: ERROR_MESSAGES.REQUIRED,
            })}
            error={errors.email}
          />
          <Input
            label="password"
            type="password"
            register={register('password', {
              required: ERROR_MESSAGES.REQUIRED,
              maxLength: {
                value: 30,
                message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
              },
            })}
            error={errors.password}
          />
          <button type="submit">登録</button>
        </form>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default SignUp;

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const StyledContainer = styled.div``;
