import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { apiClient } from '../../utils/apiClient';
import { Folder } from '../../lib/api-client/@types/index';

export interface folderState {
  folders: Folder[];
}

const initialState: folderState = {
  folders: [],
};

// 全てのフォルダを取得
export const fetchFolders = createAsyncThunk<Folder[]>('folder/fetch', () =>
  apiClient().v1.folders.$get()
);

export const createFolder = createAsyncThunk<
  Folder,
  { name: string; parent_id?: number | null; is_stored?: boolean },
  {
    rejectValue: { errorMessage: string };
  }
>(
  'folder/create',
  async (
    { name, parent_id = null, is_stored = false },
    { rejectWithValue }
  ) => {
    try {
      return await apiClient().v1.folders.$post({
        body: { name, parent_id, is_stored },
      });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const deleteFolder = createAsyncThunk(
  'folder/delete',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return await apiClient().v1.folders._folderId(String(id)).$delete();
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const editFolderName = createAsyncThunk<
  Folder,
  Folder,
  {
    rejectValue: { errorMessage: string };
  }
>(
  'folder/edit',
  async ({ name, id, parent_id = null, is_stored }, { rejectWithValue }) => {
    try {
      return await apiClient().v1.folders._folderId(String(id)).$put({
        body: { name, parent_id, is_stored },
      });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const folderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFolder.fulfilled, (state, action) => ({
      ...state,
      folders: [...state.folders, action.payload],
    }));
    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      const newFolders = state.folders.filter(
        (folder) => folder.id !== action.payload.id
      );
      return {
        ...state,
        folders: newFolders,
      };
    });
    builder.addCase(fetchFolders.fulfilled, (state, action) => ({
      ...state,
      folders: action.payload,
    }));
    builder.addCase(editFolderName.fulfilled, (state, action) => {
      const newFolders = state.folders.map((folder) =>
        folder.id === action.payload.id ? action.payload : folder
      );
      return {
        ...state,
        folders: newFolders,
      };
    });
  },
});

export const selectFolders = (state: RootState) => state.folder.folders;

export default folderSlice.reducer;
