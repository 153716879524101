import styled from 'styled-components';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import Color from '../../utils/constants/styles/Color';
import { ErrorText } from './ErrorText';

type InputProps = {
  label: string;
  type: string;
  placeholder?: string;
  register: UseFormRegisterReturn;
  error?: FieldError;
};

export const Input = ({
  label,
  register,
  type,
  placeholder = '',
  error,
}: InputProps) => (
  <StyledLabel htmlFor={register.name}>
    {label && <span>{label}</span>}
    <StyledInput
      {...register}
      id={register.name}
      type={type}
      placeholder={placeholder}
    />
    {error && <ErrorText>{error.message}</ErrorText>}
  </StyledLabel>
);

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 15px;

  span {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 6px;
    padding-left: 2px;
  }
`;

const StyledInput = styled.input`
  border: 1px solid ${Color.borderColorCB};
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  color: #212529;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  width: 100%;
`;
