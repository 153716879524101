import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RiFileCopy2Line } from 'react-icons/ri';
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import { IoPersonAddOutline } from 'react-icons/io5';
import Color from '../../utils/constants/styles/Color';
import Layout from '../../components/organisms/Layout';
import AlignmentCard from './components/molecules/AlignmentCard';
import MemberTable from './components/molecules/MemberTable';
import ProfileContainer from './components/molecules/ProfileContainer';
import SignUpModal from './components/molecules/SignUpModal';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  fetchAccountInfo,
  fetchCompanyInfo,
  selectCompanyMember,
  selectUser,
} from '../../features/user/userSlice';
import ToastContextProvider from '../../context/useToastContext';

const AccountPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const companyMemberList = useAppSelector(selectCompanyMember);

  useEffect(() => {
    void dispatch(fetchAccountInfo());
    void dispatch(fetchCompanyInfo());
  }, [dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <ToastContextProvider>
      <Wrapper>
        <Head>
          <Container>
            <Title>
              <RiFileCopy2Line className="icon" />
              プロフィール
            </Title>
            <ProfileContainer user={user} />
          </Container>
          {/* β版は対象外 */}
          {/* <Container> */}
          {/*  <Title> */}
          {/*    <HiOutlineDesktopComputer className="icon" /> */}
          {/*    電子契約 */}
          {/*  </Title> */}
          {/*  <AlignmentCard */}
          {/*    title="DocuSign" */}
          {/*    text="連携を希望される場合はこちらから申請してください" */}
          {/*    label="申請する" */}
          {/*    isDisabled={false} */}
          {/*  /> */}
          {/*  <AlignmentCard */}
          {/*    title="GoogleDrive" */}
          {/*    text="" */}
          {/*    label="連携済み" */}
          {/*    isDisabled */}
          {/*  /> */}
          {/* </Container> */}
        </Head>
        <Container className="memberList">
          <Title>
            <HiOutlineDesktopComputer className="icon" />
            メンバーリスト
          </Title>
          <MemberTable companyMemberList={companyMemberList} />
          {/* β版は対象外 */}
          {/* <AddButton */}
          {/*  onClick={() => { */}
          {/*    setIsModalOpen(true); */}
          {/*  }} */}
          {/* > */}
          {/*  <IoPersonAddOutline className="icon" /> */}
          {/*  メンバーを追加 */}
          {/* </AddButton> */}
          {isModalOpen && <SignUpModal setIsModalOpen={setIsModalOpen} />}
        </Container>
      </Wrapper>
    </ToastContextProvider>
  );
};

export default AccountPage;

const Wrapper = styled.div`
  padding: 0 20px 20px;
`;

const Head = styled.div`
  display: flex;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;

  .icon {
    margin-right: 4px;
  }
`;

const Container = styled.div`
  border: 1px solid ${Color.borderColorE7};
  margin: 0 15px 15px 0;
  padding: 30px;
  position: relative;
  width: 50%;

  &:last-child {
    margin-right: 0;
  }

  &.memberList {
    width: 100%;
  }
`;

const AddButton = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayF3};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 30px;
  position: absolute;
  right: 30px;
  top: 30px;

  .icon {
    font-size: 18px;
    margin-right: 5px;
  }
`;
