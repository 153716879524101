import React, { useEffect } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import Color from '../../utils/constants/styles/Color';

type Inputs = {
  status: string;
  auto_update: string;
  task_genre: string;
};

interface SelectProps<T extends FieldValues> {
  label: string;
  name: keyof Inputs;
  options: string[];
  validation: { required?: string | boolean; maxLength?: number };
  register: UseFormRegister<T>;
  defaultValue: string;
}

const Select = <T extends FieldValues>({
  label,
  register,
  name,
  validation,
  options,
  defaultValue = '',
}: SelectProps<T>) => {
  const { t } = useTranslation();

  return (
    <StyledLabel htmlFor={label}>
      <span>{label}</span>
      <StyledSelectWrapper>
        {/* statusの型がかぶっているため変更する */}
        {/* @ts-ignore */}
        <select id={label} {...register(name, validation)}>
          {options.map((option) => (
            <option key={option} value={option}>
              {t(option)}
            </option>
          ))}
        </select>
      </StyledSelectWrapper>
    </StyledLabel>
  );
};

export default Select;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 15px;

  span {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

const StyledSelectWrapper = styled.div`
  background-color: #fff;
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  width: 100%;

  &::after {
    border-color: ${Color.backgroundGray9D} transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
    opacity: 0.5;
    position: absolute;
    right: 13px;
    top: 17px;
  }

  select {
    /* 初期化 */
    appearance: none;
    background: none;
    border: none;
    color: #333;
    font-size: 16px;
    height: 100%;
    padding: 10px;
    width: 100%;
  }
`;
