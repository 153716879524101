import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BiCalendarStar, BiPen } from 'react-icons/bi';
import { GrInProgress } from 'react-icons/gr';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store';
import Table from './components/Table';
import { fetchTasks, selectTasks } from '../../features/task/taskSlice';
import {
  HOME_TASK_COLUMN,
  HOME_TASK_COLUMN_EN,
} from '../../utils/constants/data/tableData';
import Layout from '../../components/organisms/Layout';
import News from '../../components/modules/News';
import Title from '../../components/atoms/Title';
import Color from '../../utils/constants/styles/Color';
import ProgressBoxes from '../../components/modules/ProgressBoxes';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { testProperty } from './modules';
import { news } from './data';
import DocumentButtonArea from './components/DocumentButtonArea';
import TableSelectList from './components/TableSelectList';
import UploadModal from '../../components/organisms/UploadModal';
import { fetchAccountInfo, selectUser } from '../../features/user/userSlice';
import { selectLng } from '../../features/language/languageSlice';
import { allDocumentStatusOptions } from '../../utils/constants/data/options';
import { Status, TaskGenre } from '../../types';
import {
  SelectedStepContextProvider,
  useSelectedStepContext,
} from '../../context/pages/home/useSelectedStepContext';
import { useGetTaskWithProgress } from './hooks/useGetTaskWithProgress';
import ToastContextProvider from '../../context/useToastContext';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const user = useAppSelector(selectUser);
  const folders = useAppSelector(selectFolders);
  const currentLng = useAppSelector(selectLng);
  const [Modal, open, close, isOpen] = useModal('root', {});
  const { selectedStep } = useSelectedStepContext();
  const { taskWithProgress } = useGetTaskWithProgress(tasks);
  // 選択肢
  const [selectedClient, setClient] = useState('');
  const [selectedManager, setManager] = useState('');
  const [selectedStatus, setStatus] = useState<Status>('完了以外');
  const [selectedGenre, setGenre] = useState<TaskGenre>();
  // 表示タスク
  const displayTasks = taskWithProgress.filter((task) => {
    const clientTerm = testProperty(task.client, selectedClient);
    const managerTerm = testProperty(task.manager, selectedManager);
    const genreTerm = testProperty(task.task_genre, selectedGenre);
    const statusTerm = testProperty(task.status, selectedStatus);
    return clientTerm && managerTerm && statusTerm && genreTerm;
  });
  // 進捗数字
  const displayTasksForCount = tasks.filter((task) => {
    const clientTerm = testProperty(task.client, selectedClient);
    const managerTerm = testProperty(task.manager, selectedManager);
    return clientTerm && managerTerm;
  });
  // テーブルフィルター
  const selectList = [
    {
      id: 'client',
      name: '依頼者',
      setHandler: setClient,
      value: selectedClient,
    },
    {
      id: 'manager',
      name: '担当者',
      setHandler: setManager,
      value: selectedManager,
    },
    {
      id: 'task_genre',
      name: 'タスク種別',
      setHandler: setGenre,
      value: selectedGenre,
    },
    {
      id: 'status',
      name: 'ステータス',
      setHandler: setStatus,
      value: selectedStatus,
    },
  ];

  // 初期レンダリング
  useEffect(() => {
    void (async () => {
      // ユーザー情報も取得する
      await dispatch(fetchTasks());
      await dispatch(fetchAccountInfo());
      await dispatch(fetchFolders());
    })();
  }, [dispatch]);

  useEffect(() => {
    const isTask = tasks.find(({ manager }) => manager === user.email);
    if (!isTask) return;
    setManager(user.email);
  }, [tasks, user]);

  return (
    <ToastContextProvider>
      <StyledContainer>
        {/* TODO: βリリースでは非表示 */}
        {/* <StyledNewsArea> */}
        {/*  <News news={news} /> */}
        {/* </StyledNewsArea> */}
        <StyledDocumentButtonArea>
          <Title
            Icon={<BiPen />}
            title={t('契約書を作成')}
            subTitle="Create a contract"
          />
          <DocumentButtonArea open={open} />
        </StyledDocumentButtonArea>
        {/* TODO: βリリースでは非表示 */}
        {/* <StyledTitleWrapper> */}
        {/*  <Title */}
        {/*    Icon={<GrInProgress />} */}
        {/*    title="契約書の進捗状況" */}
        {/*    subTitle="Contract progress" */}
        {/*  /> */}

        {/*   <StyledStep> */}
        {/*    {allDocumentStatusOptions.map((step, index) => ( */}
        {/*      <StyledStepItem */}
        {/*        key={step} */}
        {/*        className={index === selectedStep ? 'is-active' : ''} */}
        {/*      > */}
        {/*        <div className="stepCircle" /> */}
        {/*      </StyledStepItem> */}
        {/*    ))} */}
        {/*   </StyledStep> */}
        {/* </StyledTitleWrapper> */}
        {/* <StyledProgressArea> */}
        {/*  <ProgressBoxes */}
        {/*    tasks={displayTasksForCount} */}
        {/*    steps={allDocumentStatusOptions} */}
        {/*    setStatus={setStatus} */}
        {/*    selectedStatus={selectedStatus} */}
        {/*    selectedManager={selectedManager} */}
        {/*    selectedClient={selectedClient} */}
        {/*    setGenre={setGenre} */}
        {/*    selectedGenre={selectedGenre ?? ''} */}
        {/*  /> */}
        {/* </StyledProgressArea> */}
        <StyledTableArea>
          <Title
            Icon={<BiCalendarStar />}
            title={t('タスク')}
            subTitle="Task"
          />
          <StyledTableWrapper>
            <StyledSelectWrapper>
              <TableSelectList selectList={selectList} tasks={tasks} />
            </StyledSelectWrapper>
            <Table
              data={displayTasks}
              columns={
                currentLng === 'jp' ? HOME_TASK_COLUMN : HOME_TASK_COLUMN_EN
              }
            />
          </StyledTableWrapper>
        </StyledTableArea>
      </StyledContainer>
      <UploadModal
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
        defaultTabName="summary"
        folders={folders}
        status="作成中"
      />
    </ToastContextProvider>
  );
};

const HomeWithProvider = () => (
  <SelectedStepContextProvider>
    <Home />
  </SelectedStepContextProvider>
);

export default HomeWithProvider;

const StyledContainer = styled.div`
  padding-left: 20px;
`;

const StyledNewsArea = styled.div`
  margin-bottom: 30px;
`;

const StyledTableWrapper = styled.div`
  border: 1px solid ${Color.borderColorE7};
  border-radius: 6px;
  margin-bottom: 100px;
  width: 90%;
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 50px 8px;
`;

const StyledTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`;

const StyledStep = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  margin-left: 20px;
  position: relative;

  &::before {
    background-color: ${Color.backgroundBlack};
    content: '';
    display: inline-block;
    height: 2px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 150px;
    z-index: 0;
  }
`;

const StyledStepItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  width: 20px;

  .stepCircle {
    background-color: #fff;
    border: 2px solid ${Color.backgroundBlack};
    border-radius: 50%;
    height: 18px;
    width: 18px;
    z-index: 1;
  }

  &.is-active .stepCircle {
    background-color: ${Color.primary};
  }
`;

const StyledProgressArea = styled.div`
  margin-bottom: 40px;
`;

const StyledDocumentButtonArea = styled.div`
  margin-bottom: 50px;
`;

const StyledTableArea = styled.div``;
