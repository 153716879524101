import React, { useEffect, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineFolder } from 'react-icons/ai';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import FolderList from '../modules/FolderList';
import Color from '../../utils/constants/styles/Color';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { FormItemType } from '../../types';
import { Folder } from '../../lib/api-client/@types/index';
import { apiClient } from '../../utils/apiClient';

interface UploadModalType {
  Modal: React.FC<{ children: React.ReactNode }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
  defaultTabName: string;
  folders: Folder[];
  defaultSelectedFolder?: Folder | null;
  selectedFile;
}

const UploadModal: React.VFC<UploadModalType> = ({
  Modal,
  open,
  close,
  isOpen,
  defaultTabName,
  folders,
  defaultSelectedFolder = null,
  selectedFile,
}) => {
  const dispatch = useAppDispatch();
  const [selectedFolder, selectFolder] = useState<Folder | null>(null);
  const documentPageFolders = folders.filter((folder) => !folder.is_stored);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onChange',
  });
  const navigate = useNavigate();

  useEffect(() => {
    selectFolder(defaultSelectedFolder);
  }, [defaultSelectedFolder]);

  useEffect(() => {
    setValue('name', selectedFile.name);
  }, [selectedFile, setValue]);

  const onSubmit: SubmitHandler<any> = (data) => {
    void (async () => {
      const res = await fetch(selectedFile.url);
      const blob = await res.blob();
      const file = new File([blob], selectedFile.url, {
        type: 'application/pdf',
      });
      const { id } = await apiClient().v1.upload_document.$post({
        body: {
          // @ts-ignore
          file_data: file,
          name: data.name,
          // @ts-ignore
          folder_id: selectedFolder?.id,
        },
      });

      navigate(`/detail/${id}`, { state: { defaultTabName: 'task' } });
    })();
  };

  return (
    <Modal>
      <StyledModalInner>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledModalTitle>
            <RiFileCopy2Line className="icon" />
            契約書をアップロード
          </StyledModalTitle>
          <StyledModalTitle>
            <IoDocumentTextOutline className="icon" />
            契約書名
          </StyledModalTitle>
          <StyledModalInput type="text" {...register('name')} />
          {!defaultSelectedFolder && (
            <>
              <StyledModalTitle>
                <AiOutlineFolder className="icon" />
                保存先フォルダの選択
              </StyledModalTitle>
              <StyledModalContent>
                {documentPageFolders.map(
                  (folder) =>
                    !folder.parent_id && (
                      <FolderList
                        register={register}
                        key={folder.id}
                        folder={folder}
                        folders={documentPageFolders}
                        selectFolder={selectFolder}
                        selectedFolder={selectedFolder}
                      />
                    )
                )}
              </StyledModalContent>
            </>
          )}

          <StyledButtonBox>
            <StyledButton type="submit" disabled={!isValid || !selectedFolder}>
              アップロード
            </StyledButton>
            <StyledButton className="cancel" onClick={() => close()}>
              キャンセル
            </StyledButton>
          </StyledButtonBox>
        </StyledForm>
      </StyledModalInner>
    </Modal>
  );
};

export default UploadModal;

const StyledModalUploadArea = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayE7};
  border: 1px splid ${Color.borderColorCB};
  display: flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  text-align: center;

  &.is-file {
    background-color: ${Color.backgroundBlueSecondary};
  }

  input {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;
const StyledModalContent = styled.div`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  max-height: 320px;
  overflow-y: scroll;
  padding: 10px 25px;
  width: 100%;
  z-index: 1;
`;

const StyledModalInput = styled.input`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
`;

const StyledModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledForm = styled.form``;
const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 500px;
  padding: 30px;
`;

const StyledUploadFileText = styled.p`
  color: ${Color.secondary};
`;
