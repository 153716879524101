import * as React from 'react';

import { useTable, Column, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';
import { HiSelector } from 'react-icons/hi';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Color from '../../../utils/constants/styles/Color';
import { TaskWithProgress } from '../../../utils/constants/data/tableData';
import { allDocumentStatusOptions } from '../../../utils/constants/data/options';

export interface TableProps {
  data: TaskWithProgress[];
  columns: Column<TaskWithProgress>[];
}

const today = dayjs();

const Table: React.VFC<TableProps> = ({ data, columns }) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setPageSize,
    state,
  } = useTable<TaskWithProgress>(
    {
      disableGroupBy: false,
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [
          {
            id: 'limit_date',
            // @ts-ignore
            asc: true,
          },
          {
            id: 'status',
            // @ts-ignore
            asc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  return (
    <StyledWrapper>
      <StyledSelectWrapper>
        <select
          value={pageSize}
          onChange={(event) => setPageSize(Number(event.target.value))}
          name=""
          id=""
        >
          {[5, 10, 15, 20].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </StyledSelectWrapper>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTableHead
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/* @ts-ignore */}
                  {column.isSorted && <HiSelector className="icon" />}
                </StyledTableHead>
              ))}
            </StyledTableRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  switch (cell.column.Header) {
                    case '契約書名':
                    case 'Contract name':
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          <Link
                            to={`/detail/${row.original.document_id}`}
                            state={{ defaultTabName: 'task' }}
                          >
                            <div>{cell.render('Cell')}</div>
                            <StyledDocumentId>
                              {t('契約書')}No.{row.original.document_id}
                            </StyledDocumentId>
                          </Link>
                        </StyledTableDesc>
                      );
                    case '進捗状況':
                    case 'Progress':
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          <StyledStep>
                            {allDocumentStatusOptions.map((step, index) => (
                              <StyledStepItem
                                key={step}
                                className={
                                  index === row.original.progress_index
                                    ? 'is-active'
                                    : ''
                                }
                              >
                                <div className="stepCircle" />
                              </StyledStepItem>
                            ))}
                          </StyledStep>
                          <StyledStepStatus>
                            {
                              allDocumentStatusOptions[
                                row.original.progress_index
                              ]
                            }
                          </StyledStepStatus>
                        </StyledTableDesc>
                      );
                    case '期限':
                    case 'Deadline':
                      return (
                        <StyledTableDesc
                          {...cell.getCellProps()}
                          className={
                            dayjs(cell.value) <= today
                              ? 'limitDate limitDate--expired'
                              : 'limitDate'
                          }
                        >
                          {dayjs(cell.value).format('YYYY年M月D日')}
                        </StyledTableDesc>
                      );
                    default:
                      return (
                        <StyledTableDesc {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableDesc>
                      );
                  }
                })}
              </StyledTableRow>
            );
          })}
        </tbody>
      </StyledTable>
      <StyledPagination>
        <StyledPaginationArrow
          className={canPreviousPage ? 'prev' : 'prev is-disable'}
          onClick={() => previousPage()}
        />
        <StyledPaginationCounter>
          {pageIndex + 1} / {pageOptions.length}
        </StyledPaginationCounter>

        <StyledPaginationArrow
          className={canNextPage ? 'next' : 'next is-disable'}
          onClick={() => nextPage()}
        />
      </StyledPagination>
    </StyledWrapper>
  );
};

export default Table;

const StyledTable = styled.table`
  font-size: 13px;
  text-align: left;
  width: 100%;
`;

const StyledTableHead = styled.th`
  background-color: ${Color.backgroundGrayF7};
  font-size: 10px;
  padding: 0.5rem;
  position: relative;

  .icon {
    position: absolute;
    right: 5px;
  }

  &:hover {
    background-color: ${Color.backgroundGrayED};
    transition: 0.2s;
  }
`;

const StyledTableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
  }
`;

const StyledTableDesc = styled.td`
  padding: 12px;
  vertical-align: middle;

  a:hover {
    color: ${Color.font7A};
    text-decoration: underline;
  }

  &.limitDate {
    font-size: 10px;

    &--expired {
      color: ${Color.fontRed};
      font-weight: bold;
    }
  }

  &:first-child {
    font-weight: bold;
  }
`;

const StyledDocumentId = styled.span`
  color: ${Color.secondary};
  display: block;
  font-size: 10px;
  padding-top: 5px;
`;

const StyledStep = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  position: relative;

  &::before {
    background-color: ${Color.backgroundBlack};
    content: '';
    display: inline-block;
    height: 2px;
    position: absolute;
    top: 4px;
    width: 80%;
    z-index: 0;
  }
`;

const StyledStepItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 4px;

  .stepCircle {
    background-color: #fff;
    border: 1.5px solid ${Color.backgroundBlack};
    border-radius: 50%;
    height: 10px;
    width: 10px;
    z-index: 1;
  }

  &.is-active .stepCircle {
    background-color: ${Color.primary};
  }
`;

const StyledStepStatus = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  padding-top: 5px;
`;

const StyledSelectWrapper = styled.div`
  border-radius: 4px;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  right: 0;
  top: -85px;

  select.is-gray {
    background-color: ${Color.backgroundGrayED};
  }

  &::before {
    content: '表示数';
    display: block;
    font-size: 10px;
    left: 15px;
    position: absolute;
    top: 6px;
    z-index: -1;
  }

  &::after {
    border-color: #343434 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 11px;
  }

  select {
    /* 初期化 */
    appearance: none;
    background: none;
    border: 1px solid ${Color.borderColorE7};
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    height: 100%;
    min-width: 110px;
    padding: 3px 28px 5px 70px;
    text-align: right;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledPagination = styled.div`
  bottom: -40px;
  position: absolute;
  right: 0;
`;

const StyledPaginationCounter = styled.span`
  font-size: 12px;
  margin: 0 15px;
`;

const StyledPaginationArrow = styled.span`
  cursor: pointer;
  padding: 10px;

  &::before {
    border: 0;
    border-bottom: solid 2px ${Color.borderColor4F};
    border-left: solid 2px ${Color.borderColor4F};
    content: '';
    display: inline-block;
    height: 10px;
    transform: rotate(45deg);
    transition: 0.2s;
    width: 10px;
  }

  :hover {
    &::before {
      transform: rotate(45deg) skew(-15deg, -15deg);
    }
  }

  &.next {
    &::before {
      transform: rotate(-135deg);
    }

    :hover {
      &::before {
        transform: rotate(-135deg) skew(-15deg, -15deg);
      }
    }
  }

  &.is-disable {
    cursor: auto;
    filter: none;

    &::before {
      border-color: ${Color.borderColorE7};
      border-left: 0;
      border-right: 0;
      transform: rotate(0) translateY(-3px);
    }

    :hover {
      &::before {
        border-color: ${Color.borderColorE7};
        border-left: 0;
        border-right: 0;
        transform: rotate(0) translateY(-3px);
      }
    }
  }
`;
