import React from 'react';
import { Link } from 'react-router-dom';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import pdfImg from '../../../../assets/images/pdf.svg';
import wordImg from '../../../../assets/images/word.svg';
import Color from '../../../../utils/constants/styles/Color';
import RelatedDocumentModal from './RelatedDocumentModal';

const RelatedDocumentTab = ({ document }) => {
  const { t } = useTranslation();
  const [Modal, open, close, isOpen] = useModal('root', {});
  const setIcon = (type) => {
    if (type === 'pdf') return <img src={pdfImg} alt="" />;
    if (type === 'word') return <img src={wordImg} alt="" />;
    return null;
  };

  return (
    <Container>
      <Title>
        <IoDocumentTextOutline className="icon" />
        <TitleLabel>{t('関連契約書')}</TitleLabel>
      </Title>

      <AddButton onClick={open}>
        <AiOutlinePlusCircle className="icon" />
        <AddButtonText>{t('関連契約書を追加')}</AddButtonText>
      </AddButton>

      <CardList>
        {document.related_documents.map((item) => (
          <Card key={item.document_id}>
            <Link to={`/detail/${item.document_id}`}>
              <CardName>
                {setIcon(item.document_type)}
                <span>{item.name}</span>
              </CardName>
              <CardNumber>
                {t('契約書')}No.{item.management_id}
              </CardNumber>
            </Link>
          </Card>
        ))}
      </CardList>
      <RelatedDocumentModal Modal={Modal} close={close} document={document} />
    </Container>
  );
};

export default RelatedDocumentTab;

const Container = styled.div``;

const AddButton = styled.div`
  align-items: center;
  background-color: ${Color.backgroundWhite};
  border: 1px solid ${Color.borderColorE7};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  padding: 16px;
  width: 100%;
`;

const AddButtonText = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
`;

const CardList = styled.div``;

const CardName = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  span {
    font-size: 14px;
    font-weight: 700;
    margin-left: 4px;
  }

  img {
    width: 20px;
  }
`;

const CardNumber = styled.div`
  color: ${Color.secondary};
  font-size: 11px;
  font-weight: 700;
`;

const Card = styled.div`
  background-color: ${Color.backgroundWhite};
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 1px 1px 4px;
  margin-bottom: 15px;
  padding: 15px;

  :hover {
    opacity: 0.8;
  }
`;

const TitleLabel = styled.div`
  margin-left: 5px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  margin-bottom: 15px;
`;
