import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Color from '../../../../utils/constants/styles/Color';

const RegisterModalHeadingStep = ({ step: text }) => {
  const steps = [
    '上書き\n契約書を\n選択',
    '契約書の\n格納先を\n選択',
    '契約書の\n情報を\n入力',
    // 'タスクを\n設定',
    '完了',
  ];
  const array = ['', 'select', 'register', 'complete'];
  const { t } = useTranslation();
  return (
    <StyledStep>
      {steps.map((step, index) => (
        <StyledStepItem
          key={step}
          className={array[index] === text ? 'is-active' : ''}
        >
          <StyledStepNumber>STEP{index + 1}</StyledStepNumber>
          <StyledStepCircle />
          <StyledStepText>{t(step)}</StyledStepText>
        </StyledStepItem>
      ))}
    </StyledStep>
  );
};

export default RegisterModalHeadingStep;

const StyledStep = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  position: relative;

  &::before {
    background-color: ${Color.borderColorB9};
    content: '';
    display: inline-block;
    height: 1px;
    left: 12px;
    position: absolute;
    top: 25px;
    width: 150px;
    z-index: 0;
  }
`;

const StyledStepItem = styled.div`
  align-items: center;
  color: ${Color.fontA9};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2px;
  width: 45px;

  &.is-active {
    color: ${Color.primary};
  }
`;

const StyledStepNumber = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
`;
const StyledStepCircle = styled.div`
  background-color: #fff;
  border: 2px solid ${Color.borderColorA9};
  border-radius: 50%;
  height: 20px;
  margin-bottom: 5px;
  width: 20px;
  z-index: 1;

  .is-active & {
    border-color: ${Color.primary};
  }
`;
const StyledStepText = styled.div`
  font-size: 10px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
`;
