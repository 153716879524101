import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Document } from '../../../../lib/api-client/@types';
import DocumentFolderSelect from '../../../../components/modules/DocumentFolderSelect';
import Color from '../../../../utils/constants/styles/Color';
import {
  fetchFolders,
  selectFolders,
} from '../../../../features/folder/folderSlice';
import { useAppDispatch } from '../../../../store';
import { updateRelatedDocuments } from '../../../../features/document/documentSlice';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';

interface RelatedDocumentModalProps {
  document: Document;
  Modal: any;
  close: any;
}

const RelatedDocumentModal: React.VFC<RelatedDocumentModalProps> = ({
  document,
  Modal,
  close,
}) => {
  const { t } = useTranslation();
  const [targetItem, selectItem] = useState<Document | null>(null);
  const dispatch = useAppDispatch();
  const folders = useSelector(selectFolders);
  const [isDisable, setIsDisable] = useState(false);
  const { setHasError } = useNetworkErrorModalContext();

  const cancel = () => {
    close();
    selectItem(null);
  };
  // 初期レンダリング
  useEffect(() => {
    void (async () => {
      // ユーザー情報も取得する
      await dispatch<any>(fetchFolders());
    })();
  }, [dispatch]);

  const onClick = async () => {
    if (!targetItem) return;
    const { document_type = '', name, id, management_id } = targetItem;
    const relatedDocumentIds = document.related_documents!.map(
      ({ document_id }) => document_id
    );
    // ユーザー情報も取得する
    const result = await dispatch<any>(
      updateRelatedDocuments({
        related_documents: [...relatedDocumentIds, id],
        document_id: document.id,
      })
    );
    if (updateRelatedDocuments.rejected.match(result)) {
      setHasError(true);
    }
  };

  return (
    <Modal>
      <StyledModalInner>
        <div>
          <StyledModalHeadingTitle>
            <IoDocumentTextOutline className="icon" />
            {t('契約書を選択')}
          </StyledModalHeadingTitle>
          <DocumentFolderSelect
            targetItem={targetItem}
            selectItem={selectItem}
            folders={folders}
            setIsDisable={setIsDisable}
            isFolderSelect={false}
          />
          <StyledButtonBox>
            <StyledButton disabled={isDisable} type="button" onClick={onClick}>
              {t('選択')}
            </StyledButton>
            <StyledButton className="cancel" onClick={cancel}>
              {t('キャンセル')}
            </StyledButton>
          </StyledButtonBox>
        </div>
      </StyledModalInner>
    </Modal>
  );
};

export default RelatedDocumentModal;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 550px;
  padding: 30px;
`;

const StyledModalHeadingTitle = styled.h2`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-top: 15px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &:disabled {
    background-color: ${Color.backgroundGrayF7};
    color: ${Color.font7A};
    cursor: default;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
