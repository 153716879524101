import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { BsSearch } from 'react-icons/bs';
import { BiUserCircle } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { FiLogOut } from 'react-icons/fi';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import logoImage from '../../assets/images/logo_legal0_blue.svg';
import NoticePop from './NoticePop';
import { Input } from '../atoms/Input';
import Color from '../../utils/constants/styles/Color';
import { useSearchDocuments } from '../../pages/documents/hooks/useSearchDocuments';
import { useAppDispatch, useAppSelector } from '../../store';
import { updateLng } from '../../features/language/languageSlice';
import { SearchDocumentDto } from '../../lib/api-client/@types';

export type HeaderProps = {
  // isHideButton?: boolean;
  // isHideSearchBar?: boolean;
};

const lngs = {
  jp: { nativeName: '日本語' },
  en: { nativeName: 'English' },
};

const Header: React.VFC<HeaderProps> = () => {
  const { t, i18n } = useTranslation();
  const localeState = useAppSelector((state) => state.language.lng);
  const dispatch = useAppDispatch();
  const [isNotification, checkNotification] = useState(true);
  const [isShowNotification, showNotification] = useState(false);
  const [isShowSearchForm, setIsShowSearchForm] = useState(false);
  const { ref, inView } = useInView({
    rootMargin: '0px',
  });
  const { documents: searchedDocs, searchDocuments } = useSearchDocuments();
  const navigate = useNavigate();

  const handleNotification = () => {
    checkNotification(false);
    showNotification(true);
  };

  const handleLogout = () => {
    Cookies.remove('access_token');
    navigate('/login');
  };

  const handleChangeLocale = (e) => {
    const lng = e.target.value;
    dispatch(updateLng(lng));
    void i18n.changeLanguage(lng);
  };

  const { register, handleSubmit } = useForm<SearchDocumentDto>({
    mode: 'all',
  });
  const onSubmit: SubmitHandler<SearchDocumentDto> = async (data) => {
    await searchDocuments(data);
    navigate('/documents/0');
  };

  return (
    <>
      <StyledHeader className={inView ? '' : 'is-scroll'}>
        <StyledLogoArea>
          <Link to="/">
            <img src={logoImage} alt="Legal0" />
          </Link>
        </StyledLogoArea>
        <StyledIconArea>
          <HeaderForm>
            {isShowSearchForm && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  label=""
                  type="text"
                  register={register('name')}
                  placeholder="Enter to search"
                />
              </form>
            )}
            <BsSearch
              className="icon icon--search"
              onClick={() => setIsShowSearchForm(true)}
            />
          </HeaderForm>
          <StyledSelect value={localeState} onChange={handleChangeLocale}>
            {Object.keys(lngs).map((lng) => (
              <option key={lng} value={lng}>
                {lngs[lng].nativeName}
              </option>
            ))}
          </StyledSelect>
          {/* TODO: βリリースでは非表示 */}
          {/* <StyledBell onClick={() => handleNotification()}> */}
          {/*  <VscBell className="icon icon--bell" /> */}
          {/*  {isNotification && <StyledBellDot />} */}
          {/* </StyledBell> */}
          <StyledUserIcon>
            <Link to="/account">
              <BiUserCircle className="icon icon--user" />
            </Link>
          </StyledUserIcon>
          <StyledLogout>
            <FiLogOut onClick={handleLogout} />
          </StyledLogout>
        </StyledIconArea>
      </StyledHeader>
      <span ref={ref} />
      {isShowNotification && <NoticePop showNotification={showNotification} />}
    </>
  );
};

export default Header;

const StyledHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 30px 43px;
  position: fixed;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: 1;

  &.is-scroll {
    opacity: 0.5;
    padding: 30px 50px 5px 43px;

    &:hover {
      opacity: 1;
    }
  }
`;

const StyledLogoArea = styled.div`
  img {
    height: 35px;
  }
`;

const StyledIconArea = styled.div`
  align-items: center;
  display: flex;

  .icon {
    height: 20px;
    width: 20px;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const StyledBell = styled.div`
  cursor: pointer;
  display: block;
  height: 23px;
  margin-left: 10px;
  position: relative;
  top: 1px;
  width: 23px;
`;

const StyledBellDot = styled.div`
  background-color: ${Color.important};
  border-radius: 50%;
  content: '';
  display: block;
  height: 12px;
  position: absolute;
  right: 0;
  top: -3px;
  width: 12px;
`;

const StyledUserIcon = styled.div`
  margin-left: 15px;

  a {
    display: block;
  }
`;

const HeaderForm = styled.div`
  position: relative;

  label {
    margin-bottom: 0;
  }

  input {
    background-color: ${Color.backgroundGrayF7};
    border: none;
    font-weight: 500;
    padding: 10px 40px 10px 15px;
    width: 290px;
  }

  .icon--search {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -55%);
  }
`;

const StyledLogout = styled.div`
  cursor: pointer;
  margin-left: 15px;
`;

const StyledSelect = styled.select`
  background: white;
  border: none;
  color: gray;
  font-size: 14px;
  height: 35px;
  margin-left: 10px;
  padding-left: 5px;

  option {
    background: white;
    color: black;
    display: flex;
    min-height: 20px;
    padding: 0 2px 1px;
    white-space: pre;
  }
`;
