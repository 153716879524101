import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../atoms/Input';
import TextArea from '../atoms/TextArea';
import Select from '../atoms/Select';
import { AddTaskDto } from '../../lib/api-client/@types';
import { ERROR_MESSAGES } from '../../validations/const';
import { allTaskGenreOptions } from '../../utils/constants/data/options';

type Props = {
  register: UseFormRegister<AddTaskDto>;
  errors: FieldErrors<AddTaskDto>;
};

export const TaskInput = ({ register, errors }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Select
        register={register}
        label={t('タスク種別')}
        name="task_genre"
        options={allTaskGenreOptions.filter((item) => typeof item === 'string')}
        validation={{ required: true }}
        defaultValue=""
      />
      <Input
        label={t('送信先メールアドレス')}
        type="email"
        placeholder="keiyaku@sample.com"
        register={register('manager', { required: ERROR_MESSAGES.REQUIRED })}
        error={errors.manager}
      />
      <Input
        label={t('期日')}
        type="date"
        register={register('limit_date', { required: ERROR_MESSAGES.REQUIRED })}
        error={errors.limit_date}
      />
      <TextArea
        label={t('コメント')}
        name="message"
        validation={{}}
        placeholder={t(
          'タスクを行う上で留意すべき事柄などを入力してください。'
        )}
        register={register}
      />
    </>
  );
};
