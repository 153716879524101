import { Column } from 'react-table';
import { Document, Task } from '../../../lib/api-client/@types/index';

export type TaskWithProgress = Task & {
  progress_index: number;
};

export const HOME_TASK_COLUMN: Column<TaskWithProgress>[] = [
  {
    Header: '契約書名',
    accessor: 'document_name',
  },
  {
    Header: '進捗状況',
    accessor: 'progress_index',
  },
  {
    Header: 'フォルダ名',
    accessor: 'folder_name',
  },
  {
    Header: '依頼者',
    accessor: 'client',
  },
  {
    Header: '担当者',
    accessor: 'manager',
  },
  {
    Header: '期限',
    accessor: 'limit_date',
  },
  {
    Header: 'タスク種別',
    accessor: 'task_genre',
  },
  {
    Header: 'ステータス',
    accessor: 'status',
  },
];

export const HOME_TASK_COLUMN_EN: Column<TaskWithProgress>[] = [
  {
    Header: 'Contract name',
    accessor: 'document_name',
  },
  {
    Header: 'Progress',
    accessor: 'progress_index',
  },
  {
    Header: 'Folder name',
    accessor: 'folder_name',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
  },
  {
    Header: 'Deadline',
    accessor: 'limit_date',
  },
  {
    Header: 'Task type',
    accessor: 'task_genre',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const DOCUMENTS_COLUMN: Column<Document>[] = [
  {
    Header: '契約書名',
    accessor: 'name',
  },
  {
    Header: 'ステータス',
    accessor: 'status',
  },
  {
    Header: '相手方',
    accessor: 'adverse',
  },
  {
    Header: '担当者',
    accessor: 'manager',
  },
  {
    Header: '契約開始日',
    accessor: 'start_date',
  },
  {
    Header: '契約終了日',
    accessor: 'end_date',
  },
  {
    Header: '',
    // @ts-ignore
    accessor: 'menu',
  },
];

export const DOCUMENTS_COLUMN_EN: Column<Document>[] = [
  {
    Header: 'Contract name',
    accessor: 'name',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'The other party',
    accessor: 'adverse',
  },
  {
    Header: 'Manager',
    accessor: 'manager',
  },
  {
    Header: 'Contract start date',
    accessor: 'start_date',
  },
  {
    Header: 'Contract end date',
    accessor: 'end_date',
  },
  {
    Header: '',
    // @ts-ignore
    accessor: 'menu',
  },
];
