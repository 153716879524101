import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Input } from '../../components/atoms/Input';
import logoImage from '../../assets/images/logo_legal0_blue.svg';
import Color from '../../utils/constants/styles/Color';
import { apiClient } from '../../utils/apiClient';
import { CreateJWTDto } from '../../lib/api-client/@types';
import { ERROR_MESSAGES } from '../../validations/const';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateJWTDto>({ mode: 'all' });
  // @ts-ignore
  const lastLocation = location?.state?.lastLocation;
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<CreateJWTDto> = (data) => {
    void (async () => {
      // @ts-ignore
      const { access } = await apiClient()
        .v1.jwtcookie.create.$post({
          body: { email: data.email, password: data.password },
        })
        .catch(() => {
          alert('ログインできませんでした。');
        });
      Cookies.set('access_token', access, { expires: 1 });
      if (lastLocation === '/signup') navigate('/');
      navigate(lastLocation || '/');
    })();
  };

  return (
    <StyledWrapper>
      <StyledContainer>
        <Logo src={logoImage} alt="Legal0" />
        <StyledForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="email"
              type="text"
              placeholder="Email"
              register={register('email', {
                required: ERROR_MESSAGES.REQUIRED,
              })}
              error={errors.email}
            />
            <Input
              label="password"
              type="password"
              placeholder="Password"
              register={register('password', {
                required: ERROR_MESSAGES.REQUIRED,
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
                },
              })}
              error={errors.password}
            />
            <StyledButton type="submit">ログイン</StyledButton>
          </form>
        </StyledForm>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Login;

const StyledWrapper = styled.div`
  background-color: ${Color.backgroundGrayFC};
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  margin-top: 20px;
  padding: 15px 20px;
  width: 100%;
`;

const StyledForm = styled.div`
  input {
    padding: 15px;
  }
`;

const Logo = styled.img`
  margin-bottom: 40px;
  width: 100%;
`;

const StyledContainer = styled.div`
  margin-top: 200px;
  width: 310px;
`;
