import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { RiFileCopy2Line } from 'react-icons/ri';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/atoms/Input';
import Select from '../../../../components/atoms/Select';
import { updateDocument } from '../../../../features/document/documentSlice';
import { useAppDispatch } from '../../../../store';
import Color from '../../../../utils/constants/styles/Color';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';
import { Document, UpdateDocumentDto } from '../../../../lib/api-client/@types';
import { isIntegerGreaterThanOrEqualToZero } from '../../../../validations/number';
import { ERROR_MESSAGES } from '../../../../validations/const';

type UpdateDocumentForm = Omit<UpdateDocumentDto, 'id' | 'auto_update'> & {
  auto_update: string;
};

type Props = {
  document: Document;
  setTabName: React.Dispatch<React.SetStateAction<string>>;
};

const SummaryTab = ({ document, setTabName }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateDocumentForm>({
    mode: 'all',
    defaultValues: {
      name: document.name,
      manager: document.manager,
      adverse: document.adverse,
      management_id: document.management_id,
      price: document.price,
      contract_date: document.contract_date,
      start_date: document.start_date,
      end_date: document.end_date,
      auto_update: document.auto_update ? 'あり' : 'なし',
      update_date: document.update_date,
    },
  });

  const dispatch = useAppDispatch();
  const { setHasError } = useNetworkErrorModalContext();

  const onSubmit = async (data: UpdateDocumentForm) => {
    await dispatch(
      updateDocument({
        document: {
          ...data,
          id: document.id,
          auto_update: data.auto_update === 'あり',
        },
      })
    ).then((result) => {
      if (updateDocument.rejected.match(result)) {
        setHasError(true);
        return;
      }
      setIsEditMode(false);
      if (
        result.payload.adverse &&
        result.payload.contract_date &&
        result.payload.manager &&
        result.payload.price
      ) {
        alert(`${t('作成タスクを完了して、承認タスクを作成してください')}`);
        setTabName('task');
      }
    });
  };

  return (
    <Wrapper>
      {!isEditMode ? (
        <SummaryDisplayMode setIsEditMode={setIsEditMode} document={document} />
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <StyledSidebarHeading>
              <RiFileCopy2Line className="icon" />
              {t('契約概要')}
            </StyledSidebarHeading>
            <Input
              label={t('契約書名')}
              type="text"
              register={register('name', {
                required: ERROR_MESSAGES.REQUIRED,
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
                },
              })}
              error={errors.name}
            />
            <Input
              label={t('担当者')}
              type="text"
              register={register('manager', {
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
                },
              })}
              error={errors.manager}
            />
            <Input
              label={t('契約相手方')}
              type="text"
              register={register('adverse', {
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
                },
              })}
              error={errors.adverse}
            />
            <Input
              label={t('文書管理番号')}
              type="number"
              register={register('management_id', {
                maxLength: {
                  value: 30,
                  message: ERROR_MESSAGES.MAX_LENGTH_NUMBER_30,
                },
                validate: {
                  message: (v) =>
                    isIntegerGreaterThanOrEqualToZero(v)
                      ? undefined
                      : ERROR_MESSAGES.INTEGER_GREATER_THAN_OR_EQUAL_TO_ZERO,
                },
              })}
              error={errors.management_id}
            />
            <Input
              label={t('金額')}
              type="number"
              register={register('price', {
                validate: {
                  message: (v) =>
                    isIntegerGreaterThanOrEqualToZero(v)
                      ? undefined
                      : ERROR_MESSAGES.NEGATIVE_AMOUNTS_CANNOT_BE_SPECIFIED,
                },
              })}
              error={errors.price}
            />
          </Container>
          <StyledSidebarHeading>
            <RiFileCopy2Line className="icon" />
            {t('契約期間')}
          </StyledSidebarHeading>
          <Input
            label={t('契約日')}
            type="date"
            register={register('contract_date')}
          />
          <Input
            label={t('開始日')}
            type="date"
            register={register('start_date')}
          />
          <Input
            label={t('終了日')}
            type="date"
            register={register('end_date')}
          />
          <Select
            register={register}
            label={t('自動更新')}
            name="auto_update"
            options={[`${t('なし')}`, `${t('あり')}`]}
            validation={{}}
            defaultValue={t('なし')}
          />
          <StyledSubmitButton type="submit">
            {t('編集を保存')}
          </StyledSubmitButton>
        </StyledForm>
      )}
    </Wrapper>
  );
};

export default SummaryTab;

// 表示用のコンテンツ
const SummaryDisplayMode = ({ setIsEditMode, document }) => {
  const { t } = useTranslation();
  const {
    name,
    end_date,
    price,
    contract_date,
    adverse,
    auto_update,
    start_date,
    manager,
    management_id,
  } = document;

  const CommaPrice = price
    ? `${parseInt(price, 10).toLocaleString()}${t('円')}`
    : null;

  const DisplayList = [
    {
      label: '契約書名',
      value: name,
    },
    {
      label: '担当者',
      value: manager,
    },
    {
      label: '契約相手方',
      value: adverse,
    },
    {
      label: '文書管理番号',
      value: management_id,
    },
    {
      label: '金額',
      value: CommaPrice,
    },
  ];

  const DisplayPeriodList = [
    {
      label: '契約日',
      value: () =>
        contract_date ? dayjs(contract_date).format('YYYY年M月D日') : '',
    },
    {
      label: '開始日',
      value: () => (start_date ? dayjs(start_date).format('YYYY年M月D日') : ''),
    },
    {
      label: '終了日',
      value: () => (end_date ? dayjs(end_date).format('YYYY年M月D日') : ''),
    },
    {
      label: '自動更新',
      value: () => (auto_update === 'True' ? 'あり' : 'なし'),
    },
  ];

  return (
    <>
      <Container>
        <StyledSidebarHeading>
          <RiFileCopy2Line className="icon" />
          {t('契約概要')}
        </StyledSidebarHeading>
        {DisplayList.map(({ label, value }) => (
          <DisplayItem key={label}>
            <DisplayLabel>{t(label)}</DisplayLabel>
            <DisplayValue>{value}</DisplayValue>
          </DisplayItem>
        ))}
        <EditButton
          onClick={() => {
            setIsEditMode(true);
          }}
        >
          {t('編集する')}
        </EditButton>
      </Container>
      <Container>
        <StyledSidebarHeading>
          <RiFileCopy2Line className="icon" />
          {t('契約期間')}
        </StyledSidebarHeading>
        {DisplayPeriodList.map(({ label, value }) => (
          <DisplayItem key={label}>
            <DisplayLabel>{t(label)}</DisplayLabel>
            <DisplayValue>{value()}</DisplayValue>
          </DisplayItem>
        ))}
      </Container>
    </>
  );
};

const Wrapper = styled.div``;

const StyledForm = styled.form``;

const DisplayItem = styled.div`
  margin-bottom: 20px;
`;

const EditButton = styled.div`
  color: ${Color.secondary};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 3px;
`;

const DisplayLabel = styled.div`
  color: ${Color.font7A};
  font-size: 14px;
  margin-bottom: 8px;
`;

const DisplayValue = styled.div`
  font-size: 15px;
  font-weight: 700;
`;

const Container = styled.div`
  margin-bottom: 50px;
  position: relative;
`;

const StyledSubmitButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  cursor: pointer;
  font-weight: bold;
  padding: 13px 20px;
  width: 100%;
`;

const StyledSidebarHeading = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Color.borderColorB9};
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 12px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;
