import { useEffect } from 'react';
import { BiError } from 'react-icons/bi';
import styled from 'styled-components';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import Color from '../../utils/constants/styles/Color';
import { useNetworkErrorModalContext } from '../../context/useNetworkErrorModalContext';

type Props = {
  hasError: boolean;
};

export const NetworkErrorModal = ({ hasError }: Props) => {
  const [Modal, open, close] = useModal('root', {
    closeOnOverlayClick: false,
  });
  const { setHasError } = useNetworkErrorModalContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (hasError) {
      open();
    } else {
      close();
    }
  }, [open, close, hasError]);

  return (
    <Modal>
      <StyledModalInner>
        <StyledTitle>
          <BiError className="icon" />
        </StyledTitle>
        <StyledDescBox>
          <StyledDesc>{t('ネットワークエラーが発生しました')}</StyledDesc>
          <StyledDesc>{t('再度お試しください')}</StyledDesc>
        </StyledDescBox>
        <StyledButtonBox>
          <StyledButton onClick={() => setHasError(false)}>
            {t('閉じる')}
          </StyledButton>
        </StyledButtonBox>
      </StyledModalInner>
    </Modal>
  );
};

const StyledModalInner = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  color: #333;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 20px;
`;

const StyledTitle = styled.div`
  font-weight: bold;

  .icon {
    height: 40px;
    width: 40px;
  }
`;

const StyledDescBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const StyledDesc = styled.p`
  font-weight: bold;
  padding: 4px;
`;

const StyledButtonBox = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled.button`
  background-color: ${Color.backgroundGrayA6};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  cursor: pointer;
  font-weight: bold;
  padding: 15px;
  width: 150px;

  &:hover {
    opacity: 0.7;
  }
`;
