import React, { useEffect, useState } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineFolder } from 'react-icons/ai';
import styled from 'styled-components';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FolderList from '../modules/FolderList';
import Color from '../../utils/constants/styles/Color';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchFolders, selectFolders } from '../../features/folder/folderSlice';
import { FormItemType } from '../../types';
import { Folder } from '../../lib/api-client/@types/index';
import { apiClient } from '../../utils/apiClient';

interface UploadModalType {
  Modal: React.FC<{ children: React.ReactNode }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
  defaultTabName: string;
  folders: Folder[];
  defaultSelectedFolder?: Folder | null;
  status: string;
}

const UploadModal: React.VFC<UploadModalType> = ({
  Modal,
  open,
  close,
  isOpen,
  defaultTabName,
  folders,
  defaultSelectedFolder = null,
  status,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedFolder, selectFolder] = useState<Folder | null>(null);
  const documentPageFolders = folders.filter((folder) => !folder.is_stored);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({ mode: 'all' });
  const uploadedFiles = useWatch<{ file: File; name: string }>({
    control,
    name: 'file',
  });
  const navigate = useNavigate();
  useEffect(() => {
    selectFolder(defaultSelectedFolder);
  }, [defaultSelectedFolder]);

  const onSubmit: SubmitHandler<any> = (data) => {
    void (async () => {
      const { id } = await apiClient().v1.upload_document.$post({
        body: {
          file_data: data.file[0],
          status,
          // @ts-ignore
          name: data.name,
          // @ts-ignore
          folder_id: selectedFolder?.id,
        },
      });
      navigate(`/detail/${id}`, { state: { defaultTabName: 'task' } });
    })();
  };

  return (
    <Modal>
      <StyledModalInner>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledModalTitle>
            <RiFileCopy2Line className="icon" />
            {t('契約書をアップロード')}
          </StyledModalTitle>
          <StyledModalUploadArea
            className={uploadedFiles?.[0] ? 'is-file' : ''}
          >
            <input
              type="file"
              accept=".pdf"
              {...register('file', { required: true })}
            />
            {uploadedFiles ? (
              <StyledUploadFileText>
                {uploadedFiles[0].name}
              </StyledUploadFileText>
            ) : (
              <>
                {t('ファイルをドラッグ&ドロップ')}
                <br />
                {t('またはファイルを選択')}
              </>
            )}
          </StyledModalUploadArea>
          <StyledModalTitle>
            <IoDocumentTextOutline className="icon" />
            {t('契約書名')}
          </StyledModalTitle>
          <StyledModalInput
            type="text"
            {...register('name', { required: true })}
          />
          {!defaultSelectedFolder && (
            <>
              <StyledModalTitle>
                <AiOutlineFolder className="icon" />
                {t('保存先フォルダの選択')}
              </StyledModalTitle>
              {documentPageFolders.length === 0 ? (
                <StyledLink>
                  {t('格納先のフォルダが存在しません。')}
                  <br />
                  <Link to="/documents/0" className="blue">
                    {t('契約書登録画面')}
                  </Link>
                  {t('でフォルダを作成してください')}
                </StyledLink>
              ) : (
                <StyledModalContent>
                  {documentPageFolders.map(
                    (folder) =>
                      !folder.parent_id && (
                        <FolderList
                          register={register}
                          key={folder.id}
                          folder={folder}
                          folders={documentPageFolders}
                          selectFolder={selectFolder}
                          selectedFolder={selectedFolder}
                        />
                      )
                  )}
                </StyledModalContent>
              )}
            </>
          )}

          <StyledButtonBox>
            <StyledButton disabled={!isValid || !selectedFolder} type="submit">
              {t('アップロード')}
            </StyledButton>
            <StyledButton className="cancel" onClick={() => close()}>
              {t('キャンセル')}
            </StyledButton>
          </StyledButtonBox>
        </StyledForm>
      </StyledModalInner>
    </Modal>
  );
};

export default UploadModal;

const StyledModalUploadArea = styled.div`
  align-items: center;
  background-color: ${Color.backgroundGrayE7};
  border: 1px splid ${Color.borderColorCB};
  display: flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  text-align: center;

  &.is-file {
    background-color: ${Color.backgroundBlueSecondary};
  }

  input {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;
const StyledModalContent = styled.div`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  max-height: 320px;
  overflow-y: scroll;
  padding: 10px 25px;
  width: 100%;
  z-index: 1;
`;

const StyledModalInput = styled.input`
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorCB};
  border-radius: 4px;
  color: ${Color.font5D};
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
`;

const StyledModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledForm = styled.form``;
const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &:disabled {
    background-color: ${Color.backgroundGrayED};
    color: ${Color.backgroundGrayCE};
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledModalInner = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 5px 3px 7px 0 rgb(4 0 0 / 10%);
  min-width: 500px;
  padding: 30px;
`;

const StyledUploadFileText = styled.p`
  color: ${Color.secondary};
`;

const StyledLink = styled.div`
  line-height: 1.7;
  margin-bottom: 20px;
  padding: 10px;

  a.blue {
    color: ${Color.secondary};
  }
`;
