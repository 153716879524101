import React from 'react';
import styled from 'styled-components';
import Color from '../../../../utils/constants/styles/Color';

const MemberTable = ({ companyMemberList }) => (
  <Container>
    <Head>
      <HeadLabel>名前</HeadLabel>
      <HeadLabel>メールアドレス</HeadLabel>
      {/* β版は対象外 */}
      {/* <HeadLabel>部署</HeadLabel> */}
    </Head>
    <TableBody>
      {companyMemberList?.map(({ id, username, email, department }) => (
        <Item key={id}>
          <ItemLabel>{username}</ItemLabel>
          <ItemLabel>{email}</ItemLabel>
          {/* β版は対象外 */}
          {/* <ItemLabel>{department}</ItemLabel> */}
        </Item>
      ))}
    </TableBody>
  </Container>
);

export default MemberTable;

const Container = styled.div`
  padding: 30px;
  position: relative;
`;

const Head = styled.div`
  display: flex;
  padding: 15px;
`;

const TableBody = styled.div``;

const HeadLabel = styled.div`
  font-size: 12px;
  width: 250px;
`;

const Item = styled.div`
  border-top: 1px solid ${Color.borderColorCB};
  display: flex;
  padding: 15px;
`;

const ItemLabel = styled.div`
  font-size: 15px;
  width: 250px;
`;
