import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../modules/Header';
import Sidebar from '../modules/Sidebar';

const Layout: React.FC = () => (
  <>
    <Header />
    <StyledContainer>
      <Sidebar />
      <StyledContent>
        <Outlet />
      </StyledContent>
    </StyledContainer>
  </>
);

export default Layout;

const StyledContainer = styled.div`
  display: flex;
  margin-top: 97px;
`;

const StyledContent = styled.div`
  margin-left: 200px;
  width: 100%;
`;
