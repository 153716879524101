import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoSettingsOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Color from '../../utils/constants/styles/Color';
import { useAppDispatch } from '../../store';
import {
  createFolder,
  deleteFolder,
  editFolderName,
} from '../../features/folder/folderSlice';
import { Folder, Document } from '../../lib/api-client/@types/index';
import { useNetworkErrorModalContext } from '../../context/useNetworkErrorModalContext';
import { ErrorText } from '../atoms/ErrorText';
import { useToastContext } from '../../context/useToastContext';

interface FolderMenuProps {
  displayFolder: Folder | undefined;
  folders: Folder[];
  documents: Document[];
}

const FolderMenu: React.VFC<FolderMenuProps> = ({
  displayFolder,
  folders,
  documents,
}) => {
  const { t } = useTranslation();
  const { setToastText } = useToastContext();
  const [isShowMenu, toggleMenu] = useState(false);
  const [type, setType] = useState('');
  const [isError, setError] = useState(false);
  const [newName, rename] = useState('');
  const [childFolderName, setChildFolderName] = useState('');

  const dispatch = useAppDispatch();
  const { setHasError } = useNetworkErrorModalContext();
  const childFolders = folders.filter(
    (folder) => folder.parent_id === displayFolder?.id
  );

  const handleDelete = async () => {
    if (documents.length || childFolders.length) {
      setError(true);
      return;
    }
    if (displayFolder === undefined) return;

    void (await dispatch(
      deleteFolder({
        id: displayFolder.id,
      })
    ).then((result) => {
      if (deleteFolder.rejected.match(result)) {
        setHasError(true);
        return;
      }
      setToastText(`${displayFolder.name}を削除しました`);
      handleCancel();
    }));
  };

  const handleCreateChildFolder = async () => {
    if (displayFolder === undefined) return;
    await dispatch(
      createFolder({
        name: childFolderName,
        parent_id: displayFolder.id,
        is_stored: displayFolder.is_stored,
      })
    ).then((result) => {
      if (createFolder.rejected.match(result)) {
        setHasError(true);
        return;
      }
      setToastText(`${childFolderName}を作成しました`);
      handleCancel();
    });
  };

  const handleRename = async () => {
    if (displayFolder === undefined) return;
    await dispatch(
      editFolderName({
        name: newName,
        id: displayFolder.id,
        parent_id: displayFolder.parent_id,
        is_stored: displayFolder.is_stored,
      })
    ).then((result) => {
      if (editFolderName.rejected.match(result)) {
        setHasError(true);
        return;
      }
      setToastText(`フォルダ名を${newName}に変更しました`);
      handleCancel();
    });
  };

  const handleCancel = () => {
    setType('');
    toggleMenu(false);
  };

  const obj = {
    delete: {
      title: `${displayFolder?.name}を削除します`,
      buttonText: '削除',
      onClick: handleDelete,
    },
    rename: {
      title: 'フォルダ名変更',
      buttonText: '変更',
      handler: (event) => rename(event.target.value),
      value: newName,
      onClick: handleRename,
    },
    create: {
      title: '子フォルダ名作成',
      buttonText: '作成',
      handler: (event) => setChildFolderName(event.target.value),
      value: childFolderName,
      onClick: handleCreateChildFolder,
    },
  };

  useEffect(() => {
    if (displayFolder === undefined) return;
    rename(displayFolder?.name);
  }, [displayFolder]);

  return (
    <>
      {displayFolder !== undefined && (
        <StyledFolderMenu onClick={() => toggleMenu(true)}>
          <IoSettingsOutline className="icon" />
          {t('フォルダ管理')}
        </StyledFolderMenu>
      )}
      {displayFolder !== undefined && isShowMenu && (
        <StyledMenuWrapper>
          <StyledLayer onClick={() => toggleMenu(false)} />
          <StyledMenu>
            <StyledMenuItem onClick={() => setType('rename')}>
              {t('フォルダ名変更')}
            </StyledMenuItem>
            {!displayFolder.parent_id && (
              <StyledMenuItem onClick={() => setType('create')}>
                {t('子フォルダ作成')}
              </StyledMenuItem>
            )}
            <StyledMenuItem
              className="is-danger"
              onClick={() => setType('delete')}
            >
              {t('フォルダ削除')}
            </StyledMenuItem>

            {type && (
              <StyledSubMenu className={type}>
                <StyledMenuTitle>{obj[type].title}</StyledMenuTitle>
                {type !== 'delete' && (
                  <StyledInput
                    type="text"
                    name={type}
                    onChange={obj[type].handler}
                    value={obj[type].value}
                  />
                )}
                <StyledButtonBox>
                  <StyledButton type="button" onClick={obj[type].onClick}>
                    {obj[type].buttonText}
                  </StyledButton>
                  <StyledButton className="cancel" onClick={handleCancel}>
                    {t('キャンセル')}
                  </StyledButton>
                </StyledButtonBox>
                {type === 'delete' && isError && (
                  <ErrorText>
                    ※フォルダを削除する場合、フォルダ内の契約書及び子フォルダを
                    削除、又は移動を行い、削除するフォルダ内を空に
                    してから行ってください。
                  </ErrorText>
                )}
              </StyledSubMenu>
            )}
          </StyledMenu>
        </StyledMenuWrapper>
      )}
    </>
  );
};

export default FolderMenu;

const StyledFolderMenu = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;

  &:hover {
    background-color: ${Color.backgroundGrayED};
    cursor: pointer;
  }
`;
const StyledMenuWrapper = styled.div`
  &.is-hidden {
    display: none;
  }
`;

const StyledLayer = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const StyledMenu = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px #eee;
  position: absolute;
  right: 0;
  top: 33px;
  width: 200px;
  z-index: 1;
`;

const StyledMenuItem = styled.li`
  border-bottom: 1px solid ${Color.borderColorED};
  cursor: pointer;
  font-size: 14px;
  padding: 14px 20px;

  &.is-danger {
    color: ${Color.fontRed};
  }

  &:hover {
    background-color: ${Color.backgroundGrayED};
  }
`;

const StyledSubMenu = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px #eee;
  left: -320px;
  padding: 20px 30px;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 1;
`;

const StyledInput = styled.input`
  border: 1px solid ${Color.borderColorCB};
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
`;

const StyledMenuTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 5px 10px;
  width: 100px;

  .delete & {
    background-color: ${Color.important};
    color: #fff;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
