import React, { useState } from 'react';
import styled from 'styled-components';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import DocumentFolderSelect from '../../../../components/modules/DocumentFolderSelect';
import Color from '../../../../utils/constants/styles/Color';
import { Document, Folder } from '../../../../lib/api-client/@types/index';

const RegisterModalStepSelect = ({
  nonStoredFolders,
  selectItem,
  targetItem,
  moveStep,
  closeModal,
}) => {
  const [isDisable, setIsDisable] = useState(true);
  const { t } = useTranslation();
  return (
    <div>
      <StyledTitle>
        <IoDocumentTextOutline className="icon" />
        {t('契約書の保存先を選択')}
      </StyledTitle>
      <DocumentFolderSelect
        targetItem={targetItem}
        selectItem={selectItem}
        folders={nonStoredFolders}
        setIsDisable={setIsDisable}
      />
      <StyledButtonBox>
        <StyledButton
          disabled={isDisable}
          type="button"
          onClick={() => moveStep('register')}
        >
          {t('選択')}
        </StyledButton>
        <StyledButton className="cancel" onClick={closeModal}>
          {t('キャンセル')}
        </StyledButton>
      </StyledButtonBox>
    </div>
  );
};

export default RegisterModalStepSelect;

const StyledTitle = styled.h3`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  .icon {
    margin-right: 10px;
  }
`;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &:disabled {
    background-color: ${Color.backgroundGrayF7};
    color: ${Color.font7A};
    cursor: default;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
