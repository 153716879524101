import React from 'react';
import { AiOutlineFolder } from 'react-icons/ai';
import styled from 'styled-components';
import Color from '../../utils/constants/styles/Color';
import { Folder } from '../../lib/api-client/@types';

interface FolderListProps {
  folder: Folder;
  folders: Folder[];
  selectFolder: React.Dispatch<React.SetStateAction<Folder | null>>;
  selectedFolder: Folder | null;
  register: (
    name: 'folder_name' | 'folderId',
    RegisterOptions?
  ) => { onChange; onBlur; name; ref };
}

const FolderList: React.VFC<FolderListProps> = ({
  folder,
  folders,
  selectFolder,
  selectedFolder,
  register,
}) => {
  const childFolders = folders.filter(
    (folderItem) => folderItem.parent_id === folder.id
  );
  return (
    <StyledFolderWrapper>
      <label htmlFor={String(folder.id)}>
        <StyledRadio
          id={String(folder.id)}
          type="radio"
          {...register('folderId', {
            required: true,
          })}
          value={folder.id}
        />
        <StyledParentFolder
          className={folder === selectedFolder ? 'is-active' : ''}
          onClick={() => selectFolder(folder)}
        >
          <AiOutlineFolder /> {folder.name}
        </StyledParentFolder>
      </label>
      <ul>
        {childFolders.map((childFolder) => (
          <label key={childFolder.id} htmlFor={String(childFolder.id)}>
            <StyledRadio
              id={String(childFolder.id)}
              type="radio"
              value={childFolder.id}
              {...register('folderId', {
                required: true,
              })}
            />
            <StyledChildFolder
              onClick={() => selectFolder(childFolder)}
              className={childFolder === selectedFolder ? 'is-active' : ''}
            >
              <AiOutlineFolder />
              {childFolder.name}
            </StyledChildFolder>
          </label>
        ))}
      </ul>
    </StyledFolderWrapper>
  );
};

export default FolderList;

const StyledFolderWrapper = styled.div`
  .is-active {
    background-color: ${Color.secondary};
    color: ${Color.fontColorWhite};
  }
`;

const StyledParentFolder = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
`;

const StyledChildFolder = styled.li`
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  padding: 5px;
`;

const StyledRadio = styled.input`
  display: none;
`;
