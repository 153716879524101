import React from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import TaskCard from './TaskCard';
import Color from '../../../../utils/constants/styles/Color';
import AddTaskModal from './AddTaskModal';

const TaskTab = ({ documentTasks, documentId, documentStatus }) => {
  const { t } = useTranslation();
  const [Modal, open, close, isOpen] = useModal('root', {});
  return (
    <div>
      <StyledSidebarHeading>
        <RiFileCopy2Line className="icon" />
        {t('タスク管理')}
      </StyledSidebarHeading>

      <StyledAddTaskButton onClick={open}>
        <AiOutlinePlusCircle className="icon" />
        {t('タスクを追加')}
      </StyledAddTaskButton>

      <AddTaskModal
        Modal={Modal}
        close={close}
        documentId={documentId}
        documentStatus={documentStatus}
      />

      {documentTasks.map((task) => (
        <TaskCard task={task} key={task.id} />
      ))}
    </div>
  );
};

export default TaskTab;

const StyledSidebarHeading = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Color.borderColorB9};
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  padding-bottom: 12px;

  .icon {
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

const StyledAddTaskButton = styled.button`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${Color.borderColorA9};
  border-radius: 4px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 20px;
  padding: 12px;
  width: 100%;

  .icon {
    margin-right: 10px;
  }
`;
