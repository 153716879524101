import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import dayjs from 'dayjs';
import { BiUserCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Color from '../../../../utils/constants/styles/Color';
import { addDocumentMemo } from '../../../../features/document/documentSlice';
import { useAppDispatch } from '../../../../store';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';

const RemarksTab = ({ document }) => {
  const { register, handleSubmit } = useForm<any>({ mode: 'all' });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setHasError } = useNetworkErrorModalContext();

  const onSubmit: SubmitHandler<any> = async (data) => {
    // ユーザー情報も取得する
    const result = await dispatch<any>(
      addDocumentMemo({
        text: data.message,
        documentId: document.id,
      })
    );
    if (addDocumentMemo.rejected.match(result)) {
      setHasError(true);
    }
  };

  return (
    <Container>
      <Title>
        <AiOutlineExclamationCircle className="icon" />
        <TitleLabel>{t('備考欄')}</TitleLabel>
      </Title>

      <CardList>
        {document.document_memo.map((memo) => (
          <Card key={memo.id}>
            <CardHead>
              <CardName>
                <BiUserCircle className="icon icon--user" />
                {memo.username}
              </CardName>
              <CardDate>
                {dayjs(memo.create_date).format('YYYY年M月D日')}
              </CardDate>
            </CardHead>
            <CardText>{memo.text}</CardText>
          </Card>
        ))}
      </CardList>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <CommentInputWrapper onSubmit={handleSubmit(onSubmit)}>
        <CommentInput
          placeholder={t('コメントを記入する')}
          {...register('message', { required: true })}
        />
        <CommentButton type="submit">{t('送信')}</CommentButton>
      </CommentInputWrapper>
    </Container>
  );
};

export default RemarksTab;

const Container = styled.div``;

const CardText = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
`;

const CardList = styled.div``;

const CardHead = styled.div`
  align-items: center;
  color: ${Color.font5D};
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const CardName = styled.div`
  align-items: center;
  display: flex;
`;

const CardDate = styled.div``;

const Card = styled.div`
  background-color: ${Color.backgroundWhite};
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 15%) 1px 1px 4px;
  margin-bottom: 15px;
  padding: 15px;
`;

const TitleLabel = styled.div`
  margin-left: 5px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  margin-bottom: 15px;
`;

const CommentInput = styled.textarea`
  appearance: none;
  background-color: ${Color.backgroundGrayFC};
  border: 1px solid ${Color.borderColorE7};
  border-radius: 4px;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 10px;
  width: 100%;
`;

const CommentInputWrapper = styled.form`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

const CommentButton = styled.button`
  appearance: none;
  background-color: ${Color.secondary};
  border: none;
  color: ${Color.fontColorWhite};
  cursor: pointer;
  margin-left: 5px;
  padding: 6px;
  width: 50px;

  &:hover {
    opacity: 0.8;
  }
`;
