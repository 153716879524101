import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { apiClient } from '../../utils/apiClient';
import { Task } from '../../lib/api-client/@types/index';

export interface TaskState {
  tasks: Task[];
}

const initialState: TaskState = {
  tasks: [],
};

export const fetchTasks = createAsyncThunk('task/fetchTasks', () =>
  apiClient().v1.tasks.$get()
);

export const addTask = createAsyncThunk<
  Task,
  { task: Task },
  {
    rejectValue: { errorMessage: string };
  }
>('task/addTask', async ({ task }, { rejectWithValue }) => {
  try {
    return await apiClient().v1.tasks.$post({ body: task });
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const changeStatus = createAsyncThunk<
  Task,
  { task: Task },
  {
    rejectValue: { errorMessage: string };
  }
>('task/changeStatus', async ({ task }, { rejectWithValue }) => {
  try {
    return apiClient().v1.tasks._taskId(String(task.id)).$put({ body: task });
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.fulfilled, (state, action) => ({
      ...state,
      tasks: action.payload,
    }));
    builder.addCase(addTask.fulfilled, (state, action) => ({
      ...state,
      tasks: [...state.tasks, action.payload],
    }));
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      const newTasks = state.tasks.map((task) =>
        task.id === action.payload.id ? action.payload : task
      );
      return {
        ...state,
        tasks: newTasks,
      };
    });
  },
});

export const selectTasks = (state: RootState) => state.task.tasks;

export default taskSlice.reducer;
