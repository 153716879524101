import React from 'react';
import {
  allStatusOptions,
  allTaskGenreOptions,
} from '../../../utils/constants/data/options';

export const getAllOptions: (tasks: string[], string) => string[] = (
  tasks,
  property
) => {
  if (property === 'status')
    return allStatusOptions.filter((item) => typeof item === 'string');
  if (property === 'task_genre')
    return allTaskGenreOptions.filter((item) => typeof item === 'string');
  return Array.from(new Set(tasks.map((task) => task[property])));
};

export const testProperty = (taskProperty, selectedText) => {
  if (selectedText === '完了以外') return taskProperty !== '完了'; // statusのみ
  return selectedText ? taskProperty === selectedText : true;
};

export const handleOnChange =
  (func: (value: string) => void) =>
  (event: React.ChangeEvent<HTMLSelectElement>) =>
    func(event.target.value);
