import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/atoms/Input';
import Select from '../../../../components/atoms/Select';
import Color from '../../../../utils/constants/styles/Color';
import { apiClient } from '../../../../utils/apiClient';
import { updateDocument } from '../../../../features/document/documentSlice';
import { allDocumentStatusOptions } from '../../../../utils/constants/data/options';
import { useAppDispatch } from '../../../../store';
import { createFolder } from '../../../../features/folder/folderSlice';
import { useNetworkErrorModalContext } from '../../../../context/useNetworkErrorModalContext';
import { ERROR_MESSAGES } from '../../../../validations/const';
import { UpdateDocumentDto } from '../../../../lib/api-client/@types';
import { isIntegerGreaterThanOrEqualToZero } from '../../../../validations/number';

type UpdateDocumentForm = Omit<UpdateDocumentDto, 'id' | 'auto_update'> & {
  status: string;
  auto_update: string;
};

const RegisterModalStepInput = ({
  selectedDocument,
  targetItem,
  moveStep,
  closeModal,
  setNewDocumentId,
  nonStoredFolders,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateDocumentForm>({
    mode: 'all',
    defaultValues: {
      name: selectedDocument.name,
      manager: selectedDocument.manager,
      start_date: selectedDocument.start_date,
      end_date: selectedDocument.end_date,
      price: selectedDocument.price,
      contract_date: selectedDocument.contract_date,
      adverse: selectedDocument.adverse,
      status: '格納完了',
      management_id: selectedDocument.management_id,
    },
  });
  const dispatch = useAppDispatch();
  const { setHasError } = useNetworkErrorModalContext();

  const onSubmit: SubmitHandler<UpdateDocumentForm> = (data) => {
    const isFolder = 'parent_id' in targetItem;
    if (isFolder) {
      void (async () => {
        const { object_token } = await apiClient()
          .v1.document._documentId(selectedDocument.id)
          .object.$post();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/document/object/${object_token}`
        );
        const blob = await response.blob();
        const file = new File([blob], selectedDocument.file_data, {
          type: 'application/pdf',
        });
        // 新規作成(フォルダの場合) Create new (for folders)
        // アップロード Upload
        const { id } = await apiClient().v1.upload_document.$post({
          body: {
            folder_id: isFolder ? targetItem.id : targetItem.folder_id, // TODO folder_id
            // @ts-ignore
            file_data: file,
            name: selectedDocument.name,
          },
        });
        // data内容で更新 Update data with contents
        const res = await dispatch(
          updateDocument({
            document: {
              ...data,
              status: '格納完了',
              auto_update: data.auto_update === 'あり' ? 'True' : 'False',
              id,
            },
          })
        );
        setNewDocumentId(id);
        const res2 = await dispatch(
          updateDocument({
            document: {
              status: '台帳登録済み',
              id: selectedDocument.id,
            },
          })
        );
        if (
          updateDocument.rejected.match(res) ||
          updateDocument.rejected.match(res2)
        ) {
          setHasError(true);
        }
      })();
    } else {
      // ドキュメントに上書きの場合
      // TODO: エラーハンドルできていない
      const archiveFolder = nonStoredFolders.find(
        ({ name: folderName }) => folderName === 'ARCHIVE'
      );

      // アーカイブフォルダに新規作成
      void (async () => {
        const { object_token } = await apiClient()
          .v1.document._documentId(targetItem.id)
          .object.$post();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/document/object/${object_token}`
        );
        const blob = await response.blob();
        const file = new File([blob], targetItem.file_data, {
          type: 'application/pdf',
        });
        if (!archiveFolder) {
          const {
            // @ts-ignore // TODO: エラーハンドルできていない
            payload: { id: FolderId },
          } = await dispatch(
            createFolder({
              name: 'ARCHIVE',
              is_stored: false,
            })
          );
          // アーカイブに前のファイルで新規作成
          const { id: documentId } = await apiClient().v1.upload_document.$post(
            {
              body: {
                // @ts-ignore
                file_data: file,
                name: targetItem.name,
                folder_id: FolderId,
              },
            }
          );
          // 前の内容をアーカイブにも適用
          const { related_documents, file_data, folder_id, name, ...rest } =
            targetItem;
          await dispatch<any>(
            updateDocument({
              document: {
                ...rest,
                related_documents: related_documents.map(
                  ({ document_id }) => document_id
                ),
                id: documentId,
              },
            })
          );
        } else {
          // アーカイブに前のファイルで新規作成
          const { id: documentId } = await apiClient().v1.upload_document.$post(
            {
              body: {
                // @ts-ignore
                file_data: file,
                name: targetItem.name,
                folder_id: archiveFolder.id,
              },
            }
          );
          // 前の内容をアーカイブにも適用
          const { related_documents, file_data, folder_id, name, ...rest } =
            targetItem;
          await dispatch(
            updateDocument({
              document: {
                ...rest,
                related_documents: related_documents.map(
                  ({ document_id }) => document_id
                ),
                id: documentId,
              },
            })
          );
        }

        // 既存のファイルを上書き
        const form_data = new FormData();
        // @ts-ignore
        form_data.append('file_data', file);
        const token = Cookies.get('access_token');
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/v1/documents/${targetItem.id}`,
          form_data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `JWT ${token}`,
            },
          }
        );
        // data内容で更新
        await dispatch<any>(
          updateDocument({
            document: {
              ...data,
              status: '格納完了',
              auto_update: data.auto_update === 'あり' ? 'True' : 'False',
              id: targetItem.id,
            },
          })
        );
        setNewDocumentId(targetItem.id);
        // selectedDocumentのステータスを変更
        await dispatch<any>(
          updateDocument({
            document: {
              status: '台帳登録済み',
              id: selectedDocument.id,
            },
          })
        );
      })();
    }
    moveStep('complete');
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label={t('契約書名')}
        type="text"
        register={register('name', {
          required: ERROR_MESSAGES.REQUIRED,
          maxLength: {
            value: 30,
            message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
          },
        })}
        error={errors.name}
      />
      <Input
        label={t('担当者')}
        type="text"
        register={register('manager', {
          required: ERROR_MESSAGES.REQUIRED,
          maxLength: {
            value: 30,
            message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
          },
        })}
        error={errors.manager}
      />
      <Input
        label={t('契約相手方')}
        type="text"
        register={register('adverse', {
          required: ERROR_MESSAGES.REQUIRED,
          maxLength: {
            value: 30,
            message: ERROR_MESSAGES.MAX_LENGTH_STRING_30,
          },
        })}
        error={errors.adverse}
      />
      <Input
        label={t('文章管理番号')}
        type="number"
        register={register('management_id', {
          required: ERROR_MESSAGES.REQUIRED,
          maxLength: {
            value: 30,
            message: ERROR_MESSAGES.MAX_LENGTH_NUMBER_30,
          },
          validate: {
            message: (v) =>
              isIntegerGreaterThanOrEqualToZero(v)
                ? undefined
                : ERROR_MESSAGES.INTEGER_GREATER_THAN_OR_EQUAL_TO_ZERO,
          },
        })}
        error={errors.management_id}
      />
      <Input
        label={t('金額')}
        type="number"
        register={register('price', {
          required: ERROR_MESSAGES.REQUIRED,
          maxLength: {
            value: 30,
            message: ERROR_MESSAGES.MAX_LENGTH_NUMBER_30,
          },
          validate: {
            message: (v) =>
              isIntegerGreaterThanOrEqualToZero(v)
                ? undefined
                : ERROR_MESSAGES.NEGATIVE_AMOUNTS_CANNOT_BE_SPECIFIED,
          },
        })}
        error={errors.price}
      />
      <Input
        label={t('締結日')}
        type="date"
        register={register('contract_date', {
          required: ERROR_MESSAGES.REQUIRED,
        })}
        error={errors.contract_date}
      />
      <Input
        label={t('契約開始日')}
        type="date"
        register={register('start_date', {
          required: ERROR_MESSAGES.REQUIRED,
        })}
        error={errors.start_date}
      />
      <Input
        label={t('契約終了日')}
        type="date"
        register={register('end_date', {
          required: ERROR_MESSAGES.REQUIRED,
        })}
        error={errors.end_date}
      />
      <Select
        register={register}
        label={t('自動更新')}
        name="auto_update"
        options={['あり', 'なし']}
        validation={{ required: true }}
        defaultValue=""
      />
      <StyledButtonBox>
        <StyledButton disabled={!isValid} type="submit">
          {t('登録')}
        </StyledButton>
        <StyledButton className="cancel" onClick={closeModal}>
          {t('キャンセル')}
        </StyledButton>
      </StyledButtonBox>
    </form>
  );
};

export default RegisterModalStepInput;

const StyledButton = styled.button`
  background-color: ${Color.secondary};
  border: none;
  border-radius: 4px;
  color: ${Color.fontColorWhite};
  font-weight: bold;
  padding: 15px 20px;
  width: 150px;

  &:disabled {
    background-color: ${Color.backgroundGrayF7};
    color: ${Color.font7A};
    cursor: default;
  }

  &.cancel {
    background-color: ${Color.backgroundGrayA6};
    color: ${Color.fontColorWhite};
    margin-left: 15px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
