import React from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons';

interface TitleProps {
  Icon: any; // Elementと互換がないため
  title: string;
  subTitle: string;
}

const Title: React.VFC<TitleProps> = ({ Icon, title, subTitle }) => (
  <StyledWrapper>
    {Icon}
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledSubTitle>{subTitle}</StyledSubTitle>
    </div>
  </StyledWrapper>
);

export default Title;

const StyledWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  svg {
    height: 20px;
    margin-right: 20px;
    margin-top: 3px;
    width: 20px;
  }
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledSubTitle = styled.div`
  font-size: 12px;
`;
