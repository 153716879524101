import React from 'react';
import TableSelect from './TableSelect';
import { getAllOptions } from '../modules';

const handleOnChange =
  (func: (value: string) => void) =>
  (event: React.ChangeEvent<HTMLSelectElement>) =>
    func(event.target.value);

const TableSelectList = ({ selectList, tasks }) => (
  <>
    {selectList.map(({ id, name, setHandler, value }) => (
      <TableSelect
        options={getAllOptions(tasks, id)}
        key={id}
        name={name}
        id={id}
        handleOnChange={handleOnChange(setHandler)}
        value={value}
      />
    ))}
  </>
);

export default TableSelectList;
