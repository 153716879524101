import { useState } from 'react';
import { Document, SearchDocumentDto } from '../../../lib/api-client/@types';
import { fetchDocuments } from '../../../features/document/documentSlice';
import { useAppDispatch } from '../../../store';

export const useSearchDocuments = () => {
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<Document[]>([]);
  const searchDocuments = async (query: SearchDocumentDto) => {
    const autoUpdateObj = {
      あり: 'True',
      なし: 'False',
    };
    await dispatch(
      fetchDocuments({
        // @ts-ignore
        query: {
          ...query,
          auto_update:
            // @ts-ignore
            query.auto_update === '' ? '' : autoUpdateObj[query.auto_update],
        },
      })
    );
  };

  return { documents, searchDocuments };
};
