import React from 'react';
import styled from 'styled-components';
import { RiFileCopy2Line, RiHome2Line } from 'react-icons/ri';
import { FaBalanceScale } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { GoBook } from 'react-icons/go';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';
import Color from '../../utils/constants/styles/Color';
import SidebarItem from '../atoms/SidebarItem';

interface TabList {
  Icon: IconType;
  enName: string;
  name: string;
  path: string;
  isDisabled?: boolean;
}

const tabList: TabList[] = [
  {
    Icon: RiHome2Line,
    enName: 'HOME',
    name: 'ホーム',
    path: '/',
  },
  {
    Icon: RiFileCopy2Line,
    enName: 'DOCUMENTS',
    name: '契約書管理',
    path: '/documents/0',
  },
  {
    Icon: GoBook,
    enName: 'LEDGER REGISTRATION',
    name: '台帳登録',
    path: '/register/0',
  },
  {
    Icon: FaBalanceScale,
    enName: 'ADVICE',
    name: '相談する',
    path: '/advice',
    isDisabled: true,
  },
];

const Sidebar = () => {
  const { t } = useTranslation();
  const locationPath = useLocation().pathname.split(/\//)[1];
  const getIsActive = (path: string) => {
    if (!locationPath) return path === '/';
    return path.includes(locationPath);
  };
  const getStatus = (path: string, isDisabled: boolean) => {
    if (isDisabled) return 'disabled';
    if (getIsActive(path)) return 'active';
    return 'default';
  };

  return (
    <StyledSidebar>
      {tabList.map(({ Icon, enName, name, path, isDisabled }) => (
        <SidebarItem
          Icon={Icon}
          enName={enName}
          name={t(name)}
          status={getStatus(path, isDisabled ?? false)}
          path={path}
          key={name}
        />
      ))}
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  background-color: ${Color.backgroundGrayF3};
  height: calc(100vh - 97px);
  left: 0;
  position: fixed;
  width: 200px;
`;
