import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import reset from 'styled-reset';
import dayjs from 'dayjs';
import { createGlobalStyle } from 'styled-components';
import { index } from './store';
import * as serviceWorker from './serviceWorker';
import Router from './routes';
import Color from './utils/constants/styles/Color';
import { NetworkErrorModalProvider } from './context/useNetworkErrorModalContext';

import './i18n/config';

dayjs.locale('ja');

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body{
    color: ${Color.fontColor};
  }
  
  /* other styles */
  * {
    box-sizing: border-box;
  }
  
  li{
    list-style: none;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  a {
    cursor: pointer;

    &:link,
    &:visited {
      color: ${Color.fontColor};
      text-decoration: none;
    }
  }

  input:focus, select:focus{
    outline: 1px solid #ccc;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<>Loading...</>}>
      <NetworkErrorModalProvider>
        <Provider store={index}>
          <GlobalStyle />
          <Router />
        </Provider>
      </NetworkErrorModalProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
