import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentFolderSelectItem from './DocumentFolderSelectItem';
import Color from '../../utils/constants/styles/Color';
import { Document, Folder } from '../../lib/api-client/@types/index';
import DocumentSelectItem from './DocumentSelectItem';

interface DocumentFolderSelectProps {
  folders: Folder[];
  targetItem: Folder | Document | null;
  selectItem: any;
  setIsDisable: any;
  isFolderSelect?: boolean;
}

const DocumentFolderSelect: React.VFC<DocumentFolderSelectProps> = ({
  folders,
  targetItem,
  selectItem,
  setIsDisable,
  isFolderSelect = true,
}) => {
  const { t } = useTranslation();
  const parentFolders = folders.filter(({ parent_id }) => !parent_id);

  if (!parentFolders.length) {
    return (
      <StyledLink>
        {t('格納先のフォルダが存在しません。')}
        <br />
        <Link to="/documents/0" className="blue">
          {t('契約書登録画面')}
        </Link>
        {t('でフォルダを作成してください')}
      </StyledLink>
    );
  }
  return (
    <StyledDocumentSelect>
      {parentFolders.map((folder) =>
        isFolderSelect ? (
          <DocumentFolderSelectItem
            key={folder.id}
            folder={folder}
            folders={folders}
            selectItem={selectItem}
            targetItem={targetItem}
            setIsDisable={setIsDisable}
          />
        ) : (
          <DocumentSelectItem
            key={folder.id}
            folder={folder}
            folders={folders}
            selectItem={selectItem}
            targetItem={targetItem}
            setIsDisable={setIsDisable}
          />
        )
      )}
    </StyledDocumentSelect>
  );
};

export default DocumentFolderSelect;

const StyledDocumentSelect = styled.div`
  background-color: ${Color.backgroundGrayF7};
  max-height: 400px;
  overflow-y: scroll;
  padding: 15px 20px;
`;

const StyledLink = styled.div`
  line-height: 1.7;
  padding: 10px;

  a.blue {
    color: ${Color.secondary};
  }
`;
