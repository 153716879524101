import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Color from '../../../utils/constants/styles/Color';

interface TableSelectProps {
  name: string;
  id: string;
  options: string[];
  handleOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const TableSelect: React.VFC<TableSelectProps> = ({
  options,
  id,
  name,
  handleOnChange,
  value,
}) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledName>{t(name)}</StyledName>
      <StyledSelectWrapper>
        <select
          name={name}
          id={id}
          onChange={handleOnChange}
          className={value ? '' : 'is-gray'}
          value={t(value)}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option label="" value="" />
          {options.map((option) => (
            <option key={option}>{t(option)}</option>
          ))}
        </select>
      </StyledSelectWrapper>
    </StyledWrapper>
  );
};

export default TableSelect;

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-right: 60px;
  }
`;
const StyledName = styled.span`
  font-size: 10px;
  margin-right: 10px;
`;

const StyledSelectWrapper = styled.div`
  border-radius: 4px;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: relative;

  select.is-gray {
    background-color: ${Color.backgroundGrayED};
  }

  &::after {
    border-color: #343434 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 11px;
  }

  select {
    /* 初期化 */
    appearance: none;
    background: none;
    border: 1px solid ${Color.borderColorE7};
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    height: 100%;
    min-width: 130px;
    padding: 5px 28px 5px 5px;
  }
`;
