import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods0 } from './v1/company';
import type { Methods as Methods1 } from './v1/document/_documentId@string/object';
import type { Methods as Methods2 } from './v1/document/object/_objectToken@string';
import type { Methods as Methods3 } from './v1/documents';
import type { Methods as Methods4 } from './v1/documents/_documentId@string';
import type { Methods as Methods5 } from './v1/documents/_documentId@string/tasks';
import type { Methods as Methods6 } from './v1/documents/_document_id@string/document-memo';
import type { Methods as Methods7 } from './v1/folders';
import type { Methods as Methods8 } from './v1/folders/_folderId@string';
import type { Methods as Methods9 } from './v1/folders/_folderId@string/documents';
import type { Methods as Methods10 } from './v1/jwtcookie/create';
import type { Methods as Methods11 } from './v1/notification';
import type { Methods as Methods12 } from './v1/register';
import type { Methods as Methods13 } from './v1/tasks';
import type { Methods as Methods14 } from './v1/tasks/_taskId@string';
import type { Methods as Methods15 } from './v1/tasks/_taskId@string/comments';
import type { Methods as Methods16 } from './v1/upload-document';
import type { Methods as Methods17 } from './v1/user/myself';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (
    baseURL === undefined ? 'http://localhost:3000' : baseURL
  ).replace(/\/$/, '');
  const PATH0 = '/v1/company';
  const PATH1 = '/v1/document';
  const PATH2 = '/object';
  const PATH3 = '/v1/document/object';
  const PATH4 = '/v1/documents';
  const PATH5 = '/tasks';
  const PATH6 = '/document-memo';
  const PATH7 = '/v1/folders';
  const PATH8 = '/documents';
  const PATH9 = '/v1/jwtcookie/create';
  const PATH10 = '/v1/notification';
  const PATH11 = '/v1/register';
  const PATH12 = '/v1/tasks';
  const PATH13 = '/comments';
  const PATH14 = '/v1/upload-document';
  const PATH15 = '/v1/user/myself';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';
  const PATCH = 'PATCH';

  return {
    v1: {
      company: {
        /**
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods0['get']['resBody'],
            BasicHeaders,
            Methods0['get']['status']
          >(prefix, PATH0, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods0['get']['resBody'],
            BasicHeaders,
            Methods0['get']['status']
          >(prefix, PATH0, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH0}`,
      },
      document: {
        _documentId: (val2: string) => {
          const prefix2 = `${PATH1}/${val2}`;

          return {
            object: {
              /**
               * @returns OK
               */
              post: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods1['post']['resBody'],
                  BasicHeaders,
                  Methods1['post']['status']
                >(prefix, `${prefix2}${PATH2}`, POST, option).json(),
              /**
               * @returns OK
               */
              $post: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods1['post']['resBody'],
                  BasicHeaders,
                  Methods1['post']['status']
                >(prefix, `${prefix2}${PATH2}`, POST, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH2}`,
            },
          };
        },
        object: {
          _objectToken: (val3: string) => {
            const prefix3 = `${PATH3}/${val3}`;

            return {
              /**
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods2['get']['resBody'],
                  BasicHeaders,
                  Methods2['get']['status']
                >(prefix, prefix3, GET, option).json(),
              /**
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods2['get']['resBody'],
                  BasicHeaders,
                  Methods2['get']['status']
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
      },
      documents: {
        _documentId: (val2: string) => {
          const prefix2 = `${PATH4}/${val2}`;

          return {
            tasks: {
              /**
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods5['get']['resBody'],
                  BasicHeaders,
                  Methods5['get']['status']
                >(prefix, `${prefix2}${PATH5}`, GET, option).json(),
              /**
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods5['get']['resBody'],
                  BasicHeaders,
                  Methods5['get']['status']
                >(prefix, `${prefix2}${PATH5}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH5}`,
            },
            /**
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods4['get']['resBody'],
                BasicHeaders,
                Methods4['get']['status']
              >(prefix, prefix2, GET, option).json(),
            /**
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods4['get']['resBody'],
                BasicHeaders,
                Methods4['get']['status']
              >(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns OK
             */
            put: (option: {
              body: Methods4['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods4['put']['resBody'],
                BasicHeaders,
                Methods4['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * @returns OK
             */
            $put: (option: {
              body: Methods4['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods4['put']['resBody'],
                BasicHeaders,
                Methods4['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns OK
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods4['delete']['resBody'],
                BasicHeaders,
                Methods4['delete']['status']
              >(prefix, prefix2, DELETE, option).json(),
            /**
             * @returns OK
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods4['delete']['resBody'],
                BasicHeaders,
                Methods4['delete']['status']
              >(prefix, prefix2, DELETE, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns OK
             */
            patch: (option: {
              body: Methods4['patch']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods4['patch']['resBody'],
                BasicHeaders,
                Methods4['patch']['status']
              >(prefix, prefix2, PATCH, option).json(),
            /**
             * @returns OK
             */
            $patch: (option: {
              body: Methods4['patch']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods4['patch']['resBody'],
                BasicHeaders,
                Methods4['patch']['status']
              >(prefix, prefix2, PATCH, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        _document_id: (val2: string) => {
          const prefix2 = `${PATH4}/${val2}`;

          return {
            document_memo: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch(prefix, `${prefix2}${PATH6}`, GET, option).send(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch(prefix, `${prefix2}${PATH6}`, GET, option)
                  .send()
                  .then((r) => r.body),
              /**
               * @returns OK
               */
              post: (option: {
                body: Methods6['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods6['post']['resBody'],
                  BasicHeaders,
                  Methods6['post']['status']
                >(prefix, `${prefix2}${PATH6}`, POST, option).json(),
              /**
               * @returns OK
               */
              $post: (option: {
                body: Methods6['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods6['post']['resBody'],
                  BasicHeaders,
                  Methods6['post']['status']
                >(prefix, `${prefix2}${PATH6}`, POST, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH6}`,
            },
          };
        },
        /**
         * @returns OK
         */
        get: (option: {
          query: Methods3['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods3['get']['resBody'],
            BasicHeaders,
            Methods3['get']['status']
          >(prefix, PATH4, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option: {
          query: Methods3['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods3['get']['resBody'],
            BasicHeaders,
            Methods3['get']['status']
          >(prefix, PATH4, GET, option)
            .json()
            .then((r) => r.body),
        put: (option: {
          body: Methods3['put']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<void, BasicHeaders, Methods3['put']['status']>(
            prefix,
            PATH4,
            PUT,
            option
          ).send(),
        $put: (option: {
          body: Methods3['put']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<void, BasicHeaders, Methods3['put']['status']>(
            prefix,
            PATH4,
            PUT,
            option
          )
            .send()
            .then((r) => r.body),
        $path: (
          option?:
            | { method?: 'get' | undefined; query: Methods3['get']['query'] }
            | undefined
        ) =>
          `${prefix}${PATH4}${
            option && option.query ? `?${dataToURLString(option.query)}` : ''
          }`,
      },
      folders: {
        _folderId: (val2: string) => {
          const prefix2 = `${PATH7}/${val2}`;

          return {
            documents: {
              /**
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods9['get']['resBody'],
                  BasicHeaders,
                  Methods9['get']['status']
                >(prefix, `${prefix2}${PATH8}`, GET, option).json(),
              /**
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods9['get']['resBody'],
                  BasicHeaders,
                  Methods9['get']['status']
                >(prefix, `${prefix2}${PATH8}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH8}`,
            },
            /**
             * @returns OK
             */
            put: (option: {
              body: Methods8['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods8['put']['resBody'],
                BasicHeaders,
                Methods8['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * @returns OK
             */
            $put: (option: {
              body: Methods8['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods8['put']['resBody'],
                BasicHeaders,
                Methods8['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * @returns OK
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods8['delete']['resBody'],
                BasicHeaders,
                Methods8['delete']['status']
              >(prefix, prefix2, DELETE, option).json(),
            /**
             * @returns OK
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods8['delete']['resBody'],
                BasicHeaders,
                Methods8['delete']['status']
              >(prefix, prefix2, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods7['get']['resBody'],
            BasicHeaders,
            Methods7['get']['status']
          >(prefix, PATH7, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods7['get']['resBody'],
            BasicHeaders,
            Methods7['get']['status']
          >(prefix, PATH7, GET, option)
            .json()
            .then((r) => r.body),
        /**
         * @returns OK
         */
        post: (option: {
          body: Methods7['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods7['post']['resBody'],
            BasicHeaders,
            Methods7['post']['status']
          >(prefix, PATH7, POST, option).json(),
        /**
         * @returns OK
         */
        $post: (option: {
          body: Methods7['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods7['post']['resBody'],
            BasicHeaders,
            Methods7['post']['status']
          >(prefix, PATH7, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH7}`,
      },
      jwtcookie: {
        create: {
          /**
           * @returns OK
           */
          post: (option: {
            body: Methods10['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods10['post']['resBody'],
              BasicHeaders,
              Methods10['post']['status']
            >(prefix, PATH9, POST, option).json(),
          /**
           * @returns OK
           */
          $post: (option: {
            body: Methods10['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods10['post']['resBody'],
              BasicHeaders,
              Methods10['post']['status']
            >(prefix, PATH9, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH9}`,
        },
      },
      notification: {
        /**
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods11['get']['resBody'],
            BasicHeaders,
            Methods11['get']['status']
          >(prefix, PATH10, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods11['get']['resBody'],
            BasicHeaders,
            Methods11['get']['status']
          >(prefix, PATH10, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH10}`,
      },
      register: {
        /**
         * @returns OK
         */
        post: (option: {
          body: Methods12['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods12['post']['resBody'],
            BasicHeaders,
            Methods12['post']['status']
          >(prefix, PATH11, POST, option).json(),
        /**
         * @returns OK
         */
        $post: (option: {
          body: Methods12['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods12['post']['resBody'],
            BasicHeaders,
            Methods12['post']['status']
          >(prefix, PATH11, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH11}`,
      },
      tasks: {
        _taskId: (val2: string) => {
          const prefix2 = `${PATH12}/${val2}`;

          return {
            comments: {
              /**
               * @returns OK
               */
              post: (option: {
                body: Methods15['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods15['post']['resBody'],
                  BasicHeaders,
                  Methods15['post']['status']
                >(prefix, `${prefix2}${PATH13}`, POST, option).json(),
              /**
               * @returns OK
               */
              $post: (option: {
                body: Methods15['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods15['post']['resBody'],
                  BasicHeaders,
                  Methods15['post']['status']
                >(prefix, `${prefix2}${PATH13}`, POST, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH13}`,
            },
            /**
             * @returns OK
             */
            put: (option: {
              body: Methods14['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods14['put']['resBody'],
                BasicHeaders,
                Methods14['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * @returns OK
             */
            $put: (option: {
              body: Methods14['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods14['put']['resBody'],
                BasicHeaders,
                Methods14['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * @returns OK
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods13['get']['resBody'],
            BasicHeaders,
            Methods13['get']['status']
          >(prefix, PATH12, GET, option).json(),
        /**
         * @returns OK
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods13['get']['resBody'],
            BasicHeaders,
            Methods13['get']['status']
          >(prefix, PATH12, GET, option)
            .json()
            .then((r) => r.body),
        /**
         * @returns OK
         */
        post: (option: {
          body: Methods13['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods13['post']['resBody'],
            BasicHeaders,
            Methods13['post']['status']
          >(prefix, PATH12, POST, option).json(),
        /**
         * @returns OK
         */
        $post: (option: {
          body: Methods13['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods13['post']['resBody'],
            BasicHeaders,
            Methods13['post']['status']
          >(prefix, PATH12, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH12}`,
      },
      upload_document: {
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch(prefix, PATH14, GET, option).send(),
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch(prefix, PATH14, GET, option)
            .send()
            .then((r) => r.body),
        /**
         * @returns OK
         */
        post: (option: {
          body: Methods16['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods16['post']['resBody'],
            BasicHeaders,
            Methods16['post']['status']
          >(prefix, PATH14, POST, option, 'FormData').json(),
        /**
         * @returns OK
         */
        $post: (option: {
          body: Methods16['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods16['post']['resBody'],
            BasicHeaders,
            Methods16['post']['status']
          >(prefix, PATH14, POST, option, 'FormData')
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH14}`,
      },
      user: {
        myself: {
          /**
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods17['get']['resBody'],
              BasicHeaders,
              Methods17['get']['status']
            >(prefix, PATH15, GET, option).json(),
          /**
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods17['get']['resBody'],
              BasicHeaders,
              Methods17['get']['status']
            >(prefix, PATH15, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH15}`,
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
