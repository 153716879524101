export const buttonColor = {
  PRIMARY_BUTTON_COLOR: '#FFFFFF',
  SECONDARY_BUTTON_COLOR: '#FFFFFF',
  PRIMARY_BUTTON_BACKGROUND_COLOR: '#00AFCC',
  SECONDARY_BUTTON_BACKGROUND_COLOR: '#56A764',
};

export default {
  primary: '#00AFCC',
  secondary: '#119BB5',
  important: '#E04091',
  backgroundGrayF6: '#f6f6f6',
  backgroundGrayF3: '#f3f3f3',
  backgroundGrayFB: '#FBFBFB',
  backgroundGrayED: '#EDEDED',
  backgroundGrayF7: '#F7F7F7',
  backgroundGrayE7: '#E7E7E7',
  backgroundGrayB9: '#B9B9B9',
  backgroundGray9D: '#9D9D9D',
  backgroundGrayA6: '#A6A6A6',
  backgroundGrayFC: '#FCFCFC',
  backgroundGrayCE: '#CECECE',
  backgroundBluePrimary: '#EDFCFF',
  backgroundBlueSecondary: '#DBEFF4',
  backgroundBlack: '#333',
  backgroundWhite: '#fff',
  borderColorE8: '#E8E8E8',
  borderColorB9: '#B9B9B9',
  borderColorE7: '#E7E7E7',
  borderColor4F: '#4F4F4F',
  borderColorED: '#EDEDED',
  borderColorCB: '#CBCBCB',
  borderColorA9: '#A9A9A9',
  borderColorTable: '#DEE2E6',
  fontColor: '#343434',
  font86: '#868686',
  font7A: '#7A7A7A',
  fontA9: '#A9A9A9',
  font5D: '#5D5D5D',
  fontRed: '#E04091',
  fontColorWhite: '#fff',
};
