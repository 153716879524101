import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { apiClient } from '../../utils/apiClient';
import {
  Task,
  Document,
  Memo,
  AddTaskDto,
  SearchDocumentDto,
} from '../../lib/api-client/@types/index';

export interface documentState {
  documents: Document[];
  documentTasks: Task[];
  document: Document | null;
}

const initialState: documentState = {
  documents: [],
  // detailページでのタスク状態
  documentTasks: [],
  document: null,
};

export const fetchDocuments = createAsyncThunk<
  Document[],
  { query?: SearchDocumentDto }
>('documents/fetch', ({ query = {} }) =>
  // @ts-ignore
  apiClient().v1.documents.$get({ query })
);

export const fetchDocumentsInFolder = createAsyncThunk<
  Document[],
  { folderId: string }
>('documents/fetchInFolder', ({ folderId }) =>
  apiClient().v1.folders._folderId(folderId).documents.$get()
);

export const fetchDocument = createAsyncThunk(
  'documents/fetchDocument',
  (documentId: string) =>
    apiClient().v1.documents._documentId(documentId).$get({ config: {} })
);

export const fetchDocumentTasks = createAsyncThunk(
  'documents/fetchTasks',
  (documentId: string) =>
    apiClient().v1.documents._documentId(documentId).tasks.$get()
);

export const addDocumentTask = createAsyncThunk<
  Task,
  AddTaskDto,
  {
    rejectValue: { errorMessage: string };
  }
>(
  'documents/addTask',
  async (
    {
      document_id,
      task_genre,
      client,
      manager = '',
      limit_date,
      message,
      status = '未着手',
    },
    { rejectWithValue }
  ) => {
    try {
      return await apiClient().v1.tasks.$post({
        // TODO genre
        body: {
          document_id,
          task_genre,
          client,
          manager,
          limit_date,
          message,
          status,
        },
      });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'documents/delete',
  async (documentId: number) => {
    try {
      return await apiClient()
        .v1.documents._documentId(String(documentId))
        .$delete();
    } catch (e) {
      return null;
    }
  }
);

export const updateDocument = createAsyncThunk<
  Document,
  { document: any },
  {
    rejectValue: { errorMessage: string };
  }
>('documents/update', async ({ document }, { rejectWithValue }) => {
  try {
    return await apiClient()
      .v1.documents._documentId(String(document.id))
      .$patch({
        body: document,
      });
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const updateRelatedDocuments = createAsyncThunk<
  Document,
  { related_documents: number[]; document_id },
  {
    rejectValue: { errorMessage: string };
  }
>(
  'documents/updateRelatedDocuments',
  async ({ related_documents, document_id }, { rejectWithValue }) => {
    try {
      return await apiClient()
        .v1.documents._documentId(String(document_id))
        // @ts-ignore
        .$patch({ body: { related_documents } });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const changeStatus = createAsyncThunk<
  Task,
  { id: number; status: string },
  {
    rejectValue: { errorMessage: string };
  }
>('documents/changeTaskStatus', async ({ id, status }, { rejectWithValue }) => {
  try {
    return await apiClient()
      .v1.tasks._taskId(String(id))
      .$put({ body: { status } }); // TODO stoplight修正するか
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const registerRelatedDocuments = createAsyncThunk<
  Document,
  { ids: string[]; document: Document },
  {
    rejectValue: { errorMessage: string };
  }
>(
  'documents/registerRelated',
  async ({ ids, document }, { rejectWithValue }) => {
    const newDocument = { ...document, relatedDocumentIds: ids };
    try {
      return await apiClient()
        .v1.documents._documentId('1')
        .$put({ body: newDocument });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const addDocumentMemo = createAsyncThunk<
  Memo,
  { documentId: number; text: string },
  {
    rejectValue: { errorMessage: string };
  }
>(
  'documents/addDocumentMemo',
  async ({ documentId, text }, { rejectWithValue }) => {
    try {
      return await apiClient()
        .v1.documents._document_id(String(documentId))
        .document_memo.$post({ body: { text } });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const moveDocument = createAsyncThunk<
  Document,
  { documentId: number; folder_name: string; folder_id: number },
  {
    rejectValue: { errorMessage: string };
  }
>(
  'documents/moveDocument',
  async ({ documentId, folder_name, folder_id }, { rejectWithValue }) => {
    try {
      return await apiClient()
        .v1.documents._documentId(String(documentId))
        // @ts-ignore
        .$patch({ body: { folder_name, folder_id } });
    } catch (error) {
      return rejectWithValue({
        errorMessage: String(error),
      });
    }
  }
);

export const addTaskComment = createAsyncThunk<
  Task,
  { id: string; message: string },
  {
    rejectValue: { errorMessage: string };
  }
>('documents/addTaskComment', async ({ id, message }, { rejectWithValue }) => {
  try {
    return await apiClient()
      .v1.tasks._taskId(id)
      .comments.$post({ body: { message } });
  } catch (error) {
    return rejectWithValue({
      errorMessage: String(error),
    });
  }
});

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    initializeDocuments(state) {
      state.documents = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocuments.fulfilled, (state, action) => ({
      ...state,
      documents: action.payload,
    }));
    builder.addCase(fetchDocumentsInFolder.fulfilled, (state, action) => ({
      ...state,
      documents: action.payload,
    }));
    builder.addCase(fetchDocumentTasks.fulfilled, (state, action) => ({
      ...state,
      documentTasks: action.payload,
    }));
    builder.addCase(fetchDocument.fulfilled, (state, action) => ({
      ...state,
      document: action.payload,
    }));
    builder.addCase(addDocumentTask.fulfilled, (state, action) => ({
      ...state,
      documentTasks: [...state.documentTasks, action.payload],
    }));
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      const newDocuments = state.documents.map((document) => {
        if (document.id === action.payload.id) return action.payload;
        return document;
      });
      return {
        ...state,
        documents: newDocuments,
        document: action.payload,
      };
    });
    builder.addCase(updateRelatedDocuments.fulfilled, (state, action) => ({
      ...state,
      document: action.payload,
    }));
    builder.addCase(addDocumentMemo.fulfilled, (state, action) => {
      const oldDocumentMemo = state.document?.document_memo || [];
      const newDocument = {
        ...state.document,
        document_memo: [...oldDocumentMemo, action.payload],
      } as Document;
      return {
        ...state,
        document: newDocument,
      };
    });
    builder.addCase(registerRelatedDocuments.fulfilled, (state, action) => ({
      ...state,
      document: { ...action.payload },
    }));
    builder.addCase(moveDocument.fulfilled, (state, action) => {
      const newDocuments = state.documents.filter(
        (document) => document.id !== action.payload.id
      );
      return {
        ...state,
        documents: newDocuments,
      };
    });
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      const newDocuments = state.documents.filter(
        ({ id }) => id !== action.payload?.id
      );
      return {
        ...state,
        documents: newDocuments,
      };
    });
    builder.addCase(addTaskComment.fulfilled, (state, action) => {
      const newTask = {
        // @ts-ignore
        ...action.payload.task,
        // @ts-ignore
        id: action.payload.task.task__id,
        // @ts-ignore
        comments: action.payload.comments.map((comment) => ({
          // @ts-ignore
          id: comment.comment_id,
          // @ts-ignore
          task_id: action.payload.task.id || action.payload.task.task__id,
          // @ts-ignore
          email: comment.comment_email,
          // @ts-ignore
          create_date: comment.comment_createTime,
          // @ts-ignore
          message: comment.comment_message,
        })),
      };
      const newTasks = state.documentTasks.map((task) =>
        // console.log(task.id);
        // console.log(action.payload.task.task__id);
        // @ts-ignore
        task.id === action.payload.task.task__id ? newTask : task
      );
      return {
        ...state,
        documentTasks: newTasks,
      };
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      const newTasks = state.documentTasks.map((task) =>
        task.id === action.payload.id ? action.payload : task
      );
      return {
        ...state,
        documentTasks: newTasks,
      };
    });
  },
});

export const { initializeDocuments } = documentSlice.actions;

export const selectDocuments = (state: RootState) => state.document.documents;
export const selectDocumentTasks = (state: RootState) =>
  state.document.documentTasks;
export const selectDocument = (state: RootState) => state.document.document;

export default documentSlice.reducer;
